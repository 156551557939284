import React, {useEffect} from 'react';
import Switch from "@material-ui/core/Switch";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {saveSettings} from '../../utils/functions';


const styles = {
    root: {
        color: 'rgb(182,182,182)',
    },
};

function IllustrationLogicSetting(props) {
    const [settings, setSettings] = React.useState({
        night_mode: true,
        auto_save: true,
        midway_check: true
    });
    const { toggleTheme } = props;
    const { classes } = props;
    const handleChange = (name) => (event) => {
        let newSettings = { ...settings, [name]: event.target.checked };
        // save this settings into the localStorage
        if (name === 'night_mode') {
            let sudoku = JSON.parse(localStorage.getItem('sudoku'));
            sudoku.settings.night_mode = newSettings.night_mode;
            localStorage.setItem('sudoku', JSON.stringify(sudoku));
            toggleTheme();
        }
        if (localStorage.getItem('numplay')) {
            let numplay = JSON.parse(localStorage.getItem('numplay'));
            numplay.settings = newSettings;
            localStorage.setItem('numplay', JSON.stringify(numplay));
        }
        setSettings(newSettings);
    };
    // night mode will be control only in one app either sudoku or numplay
    // we will control night mode in the sudoku app
    const loadSettings = () => {
        if (localStorage.getItem('numplay')) {
            let numPlaySettings = JSON.parse(localStorage.getItem('numplay')).settings;
            // get the night mode from the sudoku app and set the night mode
            numPlaySettings.night_mode = JSON.parse(localStorage.getItem('sudoku')).settings.night_mode;
            setSettings(numPlaySettings);
        }
    };
    useEffect(() => {
        saveSettings();
        loadSettings();
    }, []);
    const redirectTo = (event, path) => {
        event.preventDefault(); // this will prevent it's default behaviour. which is redirecting to another page
        props.history.push(path)
    };

    return (
        <div className="firstbody">
            <div className="setHead">
                イラストロジックの設定
            </div>
            <h6 className="letterh7">画面の設定</h6>
            <div className="slist">
                <ul>
                    <li>夜間モード
                        <div className='switch_div'>
                            <Switch color={"primary"} className={classes.root}
                                    checked={settings.night_mode}
                                    onChange={handleChange('night_mode')}
                                    value="night_mode"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </div>
                    </li>
                </ul>
            </div>
            <div>
                <h5 className="letterh5">入力の設定</h5>
                <div className="slist">
                    <ul>
                        <li>自動保存
                            <div className='switch_div'>
                                <Switch color={"primary"}
                                        checked={settings.auto_save}
                                        onChange={handleChange('auto_save')}
                                        value="auto_save"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }} />
                            </div>
                        </li>
                    </ul>

                </div>
                <div className="slist">
                    <ul>
                        <li>途中チェック機能
                            <div className='switch_div'>
                                <Switch color={"primary"}
                                        checked={settings.midway_check}
                                        onChange={handleChange('midway_check')}
                                        value="midway_check"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }} />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="sbuttonDiv">
                <button className="sbutton" onClick={(e) => props.history.goBack()}>イラストロジックに戻る</button>
            </div>
        </div>
    );
}

IllustrationLogicSetting.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IllustrationLogicSetting);
