import React, {useEffect, useState} from 'react';
import './unusual_problems_view.css';
import { BASE_URL } from '../../config';

function UnusualProblemsView(props) {

    const problemStages = {'introductory':[], 'beginner':[], 'intermediate':[], 'advanced':[], 'expert':[]}
    const [problemStageData, setProblemStageData] = useState(problemStages);

    const redirectTo = (event, path) => {
        event.preventDefault(); // this will prevent it's default behaviour. which is redirecting to another page
        props.history.push(path)
    };
    const parseProblem = (problemData)=>{
        let problems = {...problemStageData};
        Object.keys(problems).forEach((key) => {
            problems[key] = problemData.filter(x => x.difficulty_level === key);
        });

        setProblemStageData(problems);
        console.log(problems);

    };

    const startGame = (id, difficulty_level) =>{
        props.history.push('/sudoku', {id, difficulty_level});
    };

    useEffect(() => {

        fetch(BASE_URL + '/api/boards')
            .then(res => res.json())
            .then(json => {
                parseProblem(json.data);
            })
            .catch(err => {

            })

    }, []);

    return (
        <div>
            <div className="header">変わった問題</div>
            {
                Object.keys(problemStageData).map((keys, key)=>{
                    return (
                        <div key={key}>
                            <div className="section">{keys}</div>
                            <table>
                                <tbody>
                                {
                                    problemStageData[keys].map((k, index) => {
                                        return(
                                            <tr key={index}>
                                                <td><input type="checkbox"/></td>
                                                <td>{k['id']}</td>
                                                <td>
                                                    <button onClick={() => startGame(k['id'], k['difficulty_level'])}>START</button>
                                                </td>
                                            </tr>
                                        );

                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    );
                })
            }
            <div onClick={(e)=>{redirectTo(e, '/')}} className="footer">ナンプレTOPへ戻る</div>
        </div>
    );
}

export default UnusualProblemsView;
