import React, {useEffect, useState} from 'react';
import './howtoplaydetails.css'
import {BASE_URL} from '../../config';
import {FaList} from "react-icons/fa";


function HowToPlayDetails(props) {
    const [tipTitle, setTipTitle] = useState('');
    const [topTitle, setTopTitle] = useState('');
    const [contents, setContents] = useState([]);


    useEffect(() => {
        if (props.location.state) {
            let request_url = props.location.state.requestUrl;
            setTipTitle(props.location.state.title);
            setTopTitle(props.location.state.topTitle);
            fetch(BASE_URL + request_url)
                .then(res => res.json())
                .then(json => {
                    setContents(json.data);
                })
                .catch(error => {
                    console.log(error);
                });
        }

    }, []);

    function redirectTo(event, url) {
        event.preventDefault();
        props.history.push(url);
    }

    const refs = contents.reduce((accumulator, current) => {
        accumulator[current.id] = React.createRef();
        return accumulator;
    }, {});

    const topRef = React.createRef();

    const handleClick = (id) => {
        refs[id].current.scrollIntoView({
            behavior: "smooth",
            block: 'start',
        })
    };

    const goToTop = () => {
        topRef.current.scrollIntoView({
            behavior: "smooth"
        })
    };

    return (
        <div id='parent_wrapper'>

            { /*page title*/}
            <div id='howToPlayDetailsHeader' ref={topRef}>{topTitle}</div>

            <div className="articleBody">
                <div className="letterh4" style={{textAlign: 'center', marginBottom: 20}}>{tipTitle}</div>
                <div className="titlebox">
                    <div className="a_title"><FaList/><h4>目次</h4></div>
                    <div className="a_box">
                        <div className="num">
                            {
                                contents.map((row, key) => {
                                    return (
                                        <div className='title_div' onClick={() => handleClick(row['id'])} key={key}>
                                            <button>{key + 1}</button>
                                            <span>{row['header']}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <br/>
                <div className='content'>

                    {
                        contents.map((row, key) => {
                            return (
                                <div key={key}>
                                    <div className="letterh4 para_title" ref={refs[row['id']]}>{row['header']}</div>
                                    <div className="para">
                                        {row['content']}
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>

            { /*content section*/}

            <div id='secondLastFooter' onClick={() => goToTop()}>他の記事も読む</div>
            <div id='lastFooter' onClick={(event) => redirectTo(event, '/')}>
                ナンプレTOPへ戻る
            </div>

        </div>
    );
}

export default HowToPlayDetails;
