export function createChunk(myArray, chunk_size) {
  let arrayLength = myArray.length;
  let tempArray = [];

  for (let index = 0; index < arrayLength; index += chunk_size) {
    let myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
}

export const getCurrentTime = (date1, date2) => {
  // use a constant date (e.g. 2000-01-01) and the desired time to initialize two dates

  // let date1 = new Date(2000, 0, 1,  9, 0); // 9:00 AM
  // let date2 = new Date(2000, 0, 1, 17, 0); // 5:00 PM

  // the following is to handle cases where the times are on the opposite side of
  // midnight e.g. when you want to get the difference between 9:00 PM and 5:00 AM

  if (date2 < date1) {
    date2.setDate(date2.getDate() + 1);
  }

  // 28800000 milliseconds (8 hours)
  let milliseconds = date2 - date1;
  let hh = Math.floor(milliseconds / 1000 / 60 / 60);
  milliseconds -= hh * 1000 * 60 * 60;
  let mm = Math.floor(milliseconds / 1000 / 60);
  milliseconds -= mm * 1000 * 60;
  let ss = Math.floor(milliseconds / 1000);
  return { hh, mm, ss }
  // diff = 28800000 => hh = 8, mm = 0, ss = 0, milliseconds = 0
};

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const saveSettings = () => {
  // check the localStorage.
  if (!localStorage.getItem('sudoku')) {
    let sudoku = {
      settings: {
        night_mode: false,
        auto_save: true,
        midway_check: true,
        highlight_selected: true,
        vertical_horizontal_select: true,
        gray_nine_times: true,
        auto_delete: true
      },
      level: '1',
      difficulty_level: 'introductory',
      completed_levels: [],
      board: []
    };
    localStorage.setItem('sudoku', JSON.stringify(sudoku));
  }

  if (!localStorage.getItem('sudoku_four')) {
    let sudoku = {
      settings: {
        auto_save: true,
        midway_check: true,
        highlight_selected: true,
        vertical_horizontal_select: true,
        gray_nine_times: true,
        auto_delete: true
      },
      level: '1',
      difficulty_level: 'introductory',
      completed_levels: [],
      board: []
    };
    localStorage.setItem('sudoku_four', JSON.stringify(sudoku));
  }

  if (!localStorage.getItem('sudoku_diagonal')) {
    let sudoku = {
      settings: {
        auto_save: true,
        midway_check: true,
        highlight_selected: true,
        vertical_horizontal_select: true,
        gray_nine_times: true,
        auto_delete: true
      },
      level: '1',
      difficulty_level: 'introductory',
      completed_levels: [],
      board: []
    };
    localStorage.setItem('sudoku_diagonal', JSON.stringify(sudoku));
  }

  if (!localStorage.getItem('sudoku_jigsaw')) {
    let sudoku = {
      settings: {
        auto_save: true,
        midway_check: true,
        highlight_selected: true,
        vertical_horizontal_select: true,
        gray_nine_times: true,
        auto_delete: true
      },
      level: '1',
      difficulty_level: 'introductory',
      completed_levels: [],
      board: []
    };
    localStorage.setItem('sudoku_jigsaw', JSON.stringify(sudoku));
  }

  if (!localStorage.getItem('sudoku_sum')) {
    let sudoku = {
      settings: {
        auto_save: true,
        midway_check: true,
        highlight_selected: true,
        vertical_horizontal_select: true,
        gray_nine_times: true,
        auto_delete: true
      },
      level: '1',
      difficulty_level: 'introductory',
      completed_levels: [],
      board: []
    };
    localStorage.setItem('sudoku_sum', JSON.stringify(sudoku));
  }

  if (!localStorage.getItem('numplay')) {
    let numplay = {
      settings: {
        auto_save: true,
        midway_check: true,
      },
      grid_row: 10,
      grid_col: 10,
      completed_levels: [],
      board: [],
      rows: [],
      columns: [],
      level: '1',
      difficulty_level: 'introductory',
    };
    localStorage.setItem('numplay', JSON.stringify(numplay));
  }
};

export const compareValues = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
};