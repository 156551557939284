import React,{useState, useEffect} from 'react'
import './illustrologyArticleDetails.css'
import { BASE_URL } from '../../config';

function IllustrologyArticleDetails(props) {

    const contentId = props.location.state.id;
    const articleTitle = props.location.state.title;
    const linkToArticleContent = BASE_URL + '/api/illustroarticle/contents/'+contentId;
    const [contents, setContents] = useState([]);


    useEffect(()=>{
        
        fetch(linkToArticleContent)
        .then(res=>res.json())
        .then(json=>{
            setContents(json);
        })
        .catch(error => {
            console.log(error);
        })

    }, []);

    console.log(contents);

    function redirectTo(event, url){
        event.preventDefault();
        props.history.push(url);
    }

    const refs = contents.reduce((accumulator, current) => {
        accumulator[current.id] = React.createRef();
        return accumulator;
    },{});

    const topRef = React.createRef();

    const handleClick = (id) => {
        refs[id].current.scrollIntoView({
            behavior: "smooth",
            block: 'start',
        })
    };

    const goToTop = () => {
        topRef.current.scrollIntoView({
            behavior: "smooth"
        })
    };

    function setImage($url) {
        if($url !== '' && $url != null)
            return (<img className='images' src={$url} alt='Sudoku_board' />);

        return null;
    }

    return (
        <div id='parent_wrapper'>

            { /*page title*/ }
            <div id='howToPlayDetailsHeader' ref={topRef}>ナンプレの遊び方・コツ</div>

            { /*article title*/ }
            <h4 id='tipTitle'>{articleTitle}</h4>

            { /*table of contents*/ }
            <div className='tableOfContents'>
                <span id='title'>目次</span>
                <ol>
                    {
                        contents.map((row) => {
                            return (
                                <li onClick={() => handleClick(row['id'])}>{row['header']}</li>
                            )
                        })
                    }
                </ol>
            </div>

            { /*content section*/ }
            <div className='content'>
                {
                    contents.map((row) => {
                        return(
                            <div>
                                <h3 ref={refs[row['id']]}>{row['header']}</h3>
                                <p>
                                    {setImage(row['image_url'])}
                                    {row['content']}
                                </p>
                            </div>
                        );
                    })
                }
            </div>

            <div id='secondLastFooter' onClick={()=>goToTop()}>他の記事も読む</div>
            <div id='lastFooter' onClick={(event)=>redirectTo(event, '/')}>
                ナンプレTOPへ戻る
            </div>

        </div>
    )
}

export default IllustrologyArticleDetails;
