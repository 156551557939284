

function sudokuTemplate(html) {
    return `
<html>
  <head>
  <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
      <style type='text/css'>
        table {
            border-collapse: collapse;
        }
        .board_table {
            width: 350px;
            height: 450px;
            border-collapse: collapse;
            margin-left: auto;
            margin-right: auto;
            font-family: Calibri, sans-serif;
        }

        .board_table>tbody>tr>td {
            text-align: center;
            vertical-align: center;
            width: 30px;
            height: 30px;
            white-space: nowrap;
            border: 2px solid black;
        }

        .board_table>tbody>tr:nth-child(3n) {
            border-bottom: 2px solid black;
        }

        .board_table>tbody>tr>td:nth-child(3n) {
            border-right: 2px solid black;
        }

        th {
            background-color: green;
            Color: white;
        }

        .floatLeft {
            margin-top: 20px;
            margin-bottom: 20px;

        }

        .floatRight {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .container {
            display: block;
            width: 70%;
            margin: 0 auto;
        }
        </style>
    </head>
  <body>${html}</body>
</html>`
}

export default sudokuTemplate;
