import React from "react";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import {Backdrop, createStyles, makeStyles} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
    FacebookIcon,
    FacebookShareButton,
    LineIcon,
    LineShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import NumpureSolutionTable from "../NumpureSolutionTable";

const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justify:"center",
            justifyContent:"center",
            position:'absolute',            
        },
        paper: {
            backgroundColor: 'rgba(65, 98, 162, 0.6)',
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(0, 4, 3),
            width: "90%",
            [theme.breakpoints.up(1281)]: {width: "30%"},
            [theme.breakpoints.between(769, 1281)]: {width: "45%"},
            [theme.breakpoints.between(481, 769)]: {width: "90%"},
            [theme.breakpoints.between(0, 480)]: {width: "90%"},
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
             height: 18,
             left:22,
        }
    }),
);

export default function NumpureSuccessModal(props) {
    const classes = useStyles();

    const {successDialog, setSuccessDialog, endTime, addRating, shareMessage, imageUrl, answer, solution} = props;
    const [rating, setRating] = React.useState('');
    const [difficultyRating, setDifficultyRating] = React.useState('');
    const [openRating, setOpenRating] = React.useState(false);
    const [openDifficultyRating, setOpenDifficultyRating] = React.useState(false);

    const handleChangeRating = event => {
        setRating(event.target.value);
    };

    const handleCloseRating = () => {
        setOpenRating(false);
    };

    const handleOpenRating = () => {
        setOpenRating(true);
    };

    const handleChangeDifficultyRating = event => {
        setDifficultyRating(event.target.value);
    };

    const handleCloseDifficultyRating = () => {
        setOpenDifficultyRating(false);
    };

    const handleOpenDifficultyRating = () => {
        setOpenDifficultyRating(true);
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={successDialog}
                onClose={() => setSuccessDialog(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={successDialog}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title" style={{color: 'white', textAlign: 'center'}}>正解です！</h2>
                        <h5 style={{color: 'white', textAlign: 'center',marginTop:-14}}>タイム：{endTime ? endTime : '0'}</h5>
                        {/* Here we need to render the solution Table */}
                        <NumpureSolutionTable solution={solution} answer={answer}/>
                        <div style={{color: 'white', textAlign: 'center',marginTop:0}}>
                            <h5>問題の難易度を10段階で評価してく<br/>
                                ださい かんたん 1 ↔ 10 難しい</h5>
                            {/* Put the difficulty rating dropdown menu here */}
                            <div style={{position: "relative", top: -10}}>
                                <label style={{position: "absolute", left: -5, top: 10, fontSize: "90%"}}>難易度</label>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        style={{backgroundColor: 'white'}}
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        open={openDifficultyRating}
                                        onClose={handleCloseDifficultyRating}
                                        onOpen={handleOpenDifficultyRating}
                                        value={difficultyRating}
                                        onChange={handleChangeDifficultyRating}>
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={6}>6</MenuItem>
                                        <MenuItem value={7}>7</MenuItem>
                                        <MenuItem value={8}>8</MenuItem>
                                        <MenuItem value={9}>9</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <h5 style={{position: "relative", top: -20}}>問題のお気に入り度を <br/>5段階で評価してください</h5>
                            <div style={{position: "relative", top: -30}}>
                                {/* Put the rating dropdown menu here */}
                                <label style={{position: "absolute", left: -15,paddingRight:16, top: 8,fontSize: "90%"}}>お気に入り</label>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        style={{backgroundColor: 'white'}}
                                        labelId="demo-controlled-open-select-label"
                                        id="demo-controlled-open-select"
                                        open={openRating}
                                        onClose={handleCloseRating}
                                        onOpen={handleOpenRating}
                                        value={rating}
                                        onChange={handleChangeRating}>
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            {/* Put the social icon here */}
                            <TwitterShareButton url={'http://puz.puzkey.com/sudoku'} title={shareMessage}>
                                <TwitterIcon size={30}/></TwitterShareButton>
                            <FacebookShareButton url={'http://puz.puzkey.com/sudoku'} quote={shareMessage}><FacebookIcon
                                size={30}/></FacebookShareButton>
                            <LineShareButton url={'http://puz.puzkey.com/sudoku'}
                                             title={shareMessage}><LineIcon size={30}/></LineShareButton>
                            {/* Submit Rating Button 閉じる*/}
                            <br/>
                            <button type='button'
                                    // style={{height: 30, width: 120, backgroundColor: 'white', color: '#000'}}
                                    style={{height: "10%", width: 120, backgroundColor: 'white', color: '#000'}}
                                    onClick={() => addRating(rating, difficultyRating)}>閉じる
                            </button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
