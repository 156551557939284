import React from 'react';


function PostCompletionScreen(props) {
    const redirectTo = (event, path) => {
        props.history.push(path)
    };
    return (
        <div>
            {/* <Header/> <br />  */}
            <div className="header">
                問題作成　サイズ選択
            </div>

{/* ============================================================================= */}

            {/* <Text Area 1/> <br />  */}
            <div id="bodyText">
                問題を投稿してくださって、ありがとうございました！
                <br></br>
                審査終了までお待ちください
            </div>

{/* ============================================================================= */}
            <div className="btnDiv">
                <div>
                    {/*Continue to create another problem*/}
                    <button className="bodyBtnWhite" onClick={(event) => redirectTo(event,'/create')}>続けて作成する</button>
                </div>
            </div>

{/* ============================================================================= */}

            <div className="footer" onClick={(event) => redirectTo(event,'/illustrology_main')}>
                イラストロジックTOPへ戻る
            </div>



            
        </div>
    );
}

export default PostCompletionScreen;
