import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Switch from "@material-ui/core/Switch";
import './grid_diagonal_setting.css';
import { saveSettings } from '../../utils/functions';


const styles = {
    root: {
        color: 'rgb(182,182,182)',
    },
};

function GridDiagonalSetting(props) {
    const [settings, setSettings] = React.useState({
        night_mode: true,
        auto_save: true,
        midway_check: true,
        highlight_selected: true,
        vertical_horizontal_select: true,
        gray_nine_times: true,
        auto_delete: true
    });
    const {toggleTheme} = props;
    const handleChange = (name) => (event) => {
        let newSettings = {...settings, [name]: event.target.checked};
        // save this settings into the localStorage
        if(name === 'night_mode'){
            let sudoku = JSON.parse(localStorage.getItem('sudoku'));
            sudoku.settings.night_mode = newSettings.night_mode;
            localStorage.setItem('sudoku', JSON.stringify(sudoku));
            toggleTheme();
        }
        if (localStorage.getItem('sudoku_diagonal')) {
            let sudoku = JSON.parse(localStorage.getItem('sudoku_diagonal'));
            sudoku.settings = newSettings;
            localStorage.setItem('sudoku_diagonal', JSON.stringify(sudoku));
        }
        setSettings(newSettings);
    };
    const {classes} = props;


    const redirectTo = (event, path) => {
        event.preventDefault(); // this will prevent it's default behaviour. which is redirecting to another page
        props.history.push(path)
    };

    const loadSettings = () => {
        if (localStorage.getItem('sudoku_diagonal')) {
            let new_settings = JSON.parse(localStorage.getItem('sudoku_diagonal')).settings
            new_settings.night_mode = JSON.parse(localStorage.getItem('sudoku')).settings.night_mode;
            setSettings(new_settings);
        }
    };

    // Application will load here. so we need to save setting in the localStorage here
    // similar to class based lifecycle hook method componentDidMount
    // componentDidUpdate componentWillUnmount componentWillUnmount
    useEffect(() => {
        saveSettings();
        loadSettings();
    }, []);

    return (
        <div className="wrapper_setting">

            <div className="setHead">
                ナンプレの設定
            </div>
            <h5 className="letterh5">画面の設定</h5>
            <div className="slist">
                <ul>
                    <li>夜間モード
                        <div className='switch_div'>
                            <Switch color={"primary"} className={classes.root}
                                    checked={settings.night_mode}
                                    onChange={handleChange('night_mode')}
                                    value="night_mode"
                                    inputProps={{'aria-label': 'secondary checkbox'}}/>
                        </div>
                    </li>
                </ul>
            </div>
            <div>
                <h5 className="letterh5">入力の設定</h5>
                <div className="slist">
                    <ul>
                        <li>自動保存
                            <div className='switch_div'>
                                <Switch color={"primary"}
                                        checked={settings.auto_save}
                                        onChange={handleChange('auto_save')}
                                        value="auto_save"
                                        inputProps={{'aria-label': 'secondary checkbox'}}/>
                            </div>
                        </li>
                    </ul>

                </div>
                <div className="slist">
                    <ul>
                        <li>途中チェック機能
                            <div className='switch_div'>
                                <Switch color={"primary"}
                                        checked={settings.midway_check}
                                        onChange={handleChange('midway_check')}
                                        value="midway_check"
                                        inputProps={{'aria-label': 'secondary checkbox'}}/>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="slist">
                    <ul>
                        <li>選択した数字のハイライト
                            <div className='switch_div'>
                                <Switch color={"primary"}
                                        checked={settings.highlight_selected}
                                        onChange={handleChange('highlight_selected')}
                                        value="highlight_selected"
                                        inputProps={{'aria-label': 'secondary checkbox'}}/>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="slist">
                    <ul>
                        <li>選択したマスの縦･横･エリアのハイライト
                            <div className='switch_div'>
                                <Switch color={"primary"}
                                        checked={settings.vertical_horizontal_select}
                                        onChange={handleChange('vertical_horizontal_select')}
                                        value="vertical_horizontal_select"
                                        inputProps={{'aria-label': 'secondary checkbox'}}/>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="slist">
                    <ul>
                        <li>9回使用した数字のグレーアウト
                            <div className='switch_div'>
                                <Switch color={"primary"}
                                        checked={settings.gray_nine_times}
                                        onChange={handleChange('gray_nine_times')}
                                        value="gray_nine_times"
                                        inputProps={{'aria-label': 'secondary checkbox'}}/>
                            </div>
                        </li>
                    </ul>

                </div>
                <div className="slist">
                    <ul>
                        <li>確定後にメモを自動削除
                            <div className='switch_div'>
                                <Switch color={"primary"}
                                        checked={settings.auto_delete}
                                        onChange={handleChange('auto_delete')}
                                        value="auto_delete"
                                        inputProps={{'aria-label': 'secondary checkbox'}}/>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="sbuttonDiv">
                {/* <button className="sbutton" onClick={(e) => redirectTo(e, '/sudoku_diagonal')}>ナンプレに戻る</button> */}
                <button className="sbutton" onClick={(e) =>  props.history.goBack()}>ナンプレに戻る</button> 
            </div>
        </div>
    );
}

GridDiagonalSetting.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GridDiagonalSetting);
