import React from "react";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { Backdrop, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: 'rgba(65, 98, 162, 0.6)',
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),

        },
    }),
);

export default function SudokuLoseModal(props) {
    const classes = useStyles();
    const { loseDialog, setLoseDialog, endTime, onContinue, onStartAgain } = props;

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={loseDialog}
                onClose={() => setLoseDialog(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={loseDialog}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title" style={{ color: 'white', textAlign: 'center' }}>残念！不正解です</h2>
                        <h5 style={{ color: 'white', textAlign: 'center' }}>タイム：{endTime ? endTime : '0'}</h5>
                        <div style={{ color: 'white', textAlign: 'center', marginTop: 10 }}>
                            <button type='button' style={{ height: 30, width: 130, backgroundColor: 'white', color: '#000' }} onClick={() => onContinue(false)}>問題に戻る</button>
                            <br />
                            <button type='button' style={{ height: 30, width: 130, backgroundColor: 'white', color: '#000', marginTop: 10 }} onClick={() => onStartAgain()}>最初から</button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
