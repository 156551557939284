import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

function SizeSelectionScreen(props) {
    const classes = useStyles();
    const [gridSize, setGridSize] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const handleChange = event => {
        setGridSize(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const redirectTo = (event, path) => {
        props.history.push(path, {gridSize: gridSize === '' ? 10 : gridSize})
    };

    const onClickHandler = (event) => {
        if (gridSize == '') {
            alert('サイズを選択してください');
        } else {
            redirectTo(event, '/input');
        }
    }

    return (
        <div>
            {/* <Header/> <br />  */}
            <div className="header">
                問題作成 サイズ選択
            </div>

            {/* ============================================================================= */}

            {/* <Text Area/> <br />  */}
            <div id="bodyText">
                作成する問題のサイズをプルダウンから選んでください
            </div>

            {/* ============================================================================= */}

            <div id="centerAllign">
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-controlled-open-select-label">Grid Size</InputLabel>
                    <Select
                        labelId="demo-controlled-open-select-label"
                        id="demo-controlled-open-select"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={gridSize}
                        onChange={handleChange}>

                        <MenuItem value={10}>10X10</MenuItem>
                        <MenuItem value={15}>15X15</MenuItem>
                        <MenuItem value={20}>20X20</MenuItem>
                        <MenuItem value={25}>25X25</MenuItem>
                        <MenuItem value={30}>30X30</MenuItem>
                    </Select>
                </FormControl>
            </div>

            {/* ============================================================================= */}

            <br/>
            <br/>
            <div className="btnDiv">
                <button className="bodyBtnWhite" onClick={onClickHandler}>決定</button>
            </div>

            {/* ============================================================================= */}
            <div className="footer" onClick={(event) => redirectTo(event, '/illustrology_main')}>
                イラストロジックTOPへ戻る
            </div>

        </div>
    );
}

export default SizeSelectionScreen;
