import React, { useEffect, useState } from 'react';
import './top_page.css';
import { BASE_URL } from '../../config/index';

function TopPage(props) {
	const [title, setTitle] = useState("");

    const redirectTo = (event, path) => {
        event.preventDefault(); // this will prevent it's default behaviour. which is redirecting to another page
        if(path === '/sudoku'){
            let dailyChallenge = true;
            props.history.push(path,{dailyChallenge})
        }
        else{
            props.history.push(path)
        }
    };

	const getTitle = () => {
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		let requestOptions = {
			method: "GET",
			headers: myHeaders,
		};

		fetch(BASE_URL + '/api/getAppTitle', requestOptions)
			.then((response) => response.json())
			.then((result) => {
				setTitle(result.content);
			})
			.catch((error) => {
				console.log("error", error);
			});
	};

	useEffect(() => {
		getTitle();
	});

    return (
        <div className="wrapper">
            <div className="header">{title}</div>
            <div className="box1">
                <div className="boxbuttonTop" onClick={(event) => redirectTo(event, '/sudoku')}>
                    ナンプレで遊ぶ
                </div>
                <div className="boxbuttonMiddle" onClick={(event) => redirectTo(event, '/illustrology_main')}>
                    イラストロジックで遊ぶ
                </div>
                <div className="boxbuttonBottom">
                    広告
                </div>
            </div>
            <div className="myfooter">
                <a href='https://ma-ko-to.com/'>管理会社</a>
            </div>
        </div>
    );
}

export default TopPage;
