import React, { useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './components/Theme';
import { GlobalStyles } from './components/ThemeStyle';

import Sudoku from './screens/Board';
import SudokuFour from './screens/GridFourBoard';
import SudokuDiagonal from './screens/GridDiagonalBoard';
import SudokuJigsaw from './screens/GridJigsawBoard';
import SudokuSum from './screens/GridSumBoard';
import SudokuFourSetting from "./screens/GridFourSetting";
import SudokuDiagonalSetting from "./screens/GridDiagonalSetting";
import SudokuJigsawSetting from "./screens/GridJigsawSetting";
import SudokuSumSetting from "./screens/GridSumSetting";
import GridProblemBook from "./screens/GridProblemBook";
import Setting from "./screens/Setting";
import Home from "./screens/TopPage";
import Article from "./screens/HowToPlayIllustrology";
import IllustrologyPlayByGrid from "./screens/PlayIllustrologyByGrid"
import Create from "./screens/CreateProblem/SizeSelectionScreen"
import Input from "./screens/CreateProblem/ProblemInputScreen"
import Submit from "./screens/CreateProblem/ProblemSubmitScreen"
import Post from "./screens/CreateProblem/PostCompletionScreen"
import IllustrationLogic from "./screens/IllustrationLogicSetting"
import HowToPlayDetails from "./screens/HowToPlayDetails"
import HowToPlayTitles from "./screens/HowToPlayTitles"
import UnusualProblemsView from "./screens/UnusualProblemsView"
import HighlyRatedNamePlay from "./screens/HighlyRatedNamePlay";
import ProblemQuestions from "./screens/ProblemQuestion";
import IllustrologyMain from './screens/IllustrologyMain/IllustrologyMain';
import CreateAProblemTopView from './screens/CreateAProblemTopView/CreateAProblemTopView';
import IllustrologyPlayByLevel from './screens/PlayIllustrologyByLevel/IllustrologyPlayByLevel'
import IllustrologyArticleDetails from './screens/IllustrologyArticleDetails/illustrologyArticleDetails';
import IllustroloyBoard from "./screens/IllustrologyBoard";
import { saveSettings } from './utils/functions';

const useDarkMode = () => {
    const [theme, setTheme] = React.useState('light');

    const toggleTheme = () => {
        if (theme === 'light') {
            setTheme('dark');
            window.localStorage.setItem('theme', 'dark');
        } else {
            setTheme('light');
            window.localStorage.setItem('theme', 'light');
        }
    };
    useEffect(() => {
        saveSettings();
        const localTheme = JSON.parse(localStorage.getItem('sudoku')).settings.night_mode ? 'dark' : 'light';
        setTheme(localTheme);
    }, []);
    return [theme, toggleTheme]
};

function App() {
    const [theme, toggleTheme] = useDarkMode();
    const themeMode = theme === 'light' ? lightTheme : darkTheme;

    return (
        <ThemeProvider theme={themeMode}>
            <GlobalStyles />
            <div className="App">
                <Switch>
                    {/*TOP IS THE FIRST PAGE*/}
                    <Route path="/playByLevel" component={IllustrologyPlayByLevel} />
                    <Route path="/playByGrid" component={IllustrologyPlayByGrid} />
                    <Route path="/create" component={Create} />
                    <Route path="/input" component={Input} />
                    <Route path="/submit" component={Submit} />
                    <Route path="/post" component={Post} />
                    <Route path="/setting" render={(props) => <Setting {...props} toggleTheme={toggleTheme} />} />
                    <Route path="/setting_four" render={(props) => <SudokuFourSetting {...props} toggleTheme={toggleTheme} />} />
                    <Route path="/setting_diagonal" render={(props) => <SudokuDiagonalSetting {...props} toggleTheme={toggleTheme} />} />
                    <Route path="/setting_jigsaw" render={(props) => <SudokuJigsawSetting {...props} toggleTheme={toggleTheme} />} />
                    <Route path="/setting_sum" render={(props) => <SudokuSumSetting {...props} toggleTheme={toggleTheme} />} />
                    <Route path="/how_to_play_illustrology" component={Article} />
                    <Route path="/sudoku" component={Sudoku} />
                    <Route path="/sudoku_four" component={SudokuFour} />
                    <Route path="/sudoku_diagonal" component={SudokuDiagonal} />
                    <Route path="/sudoku_jigsaw" component={SudokuJigsaw} />
                    <Route path="/sudoku_sum" component={SudokuSum} />
                    <Route path="/sudoku_unusual" component={GridProblemBook} />
                    <Route path="/illustrologic" component={IllustroloyBoard} />
                    <Route path="/illustration_logic_setting" render={(props) => <IllustrationLogic {...props} toggleTheme={toggleTheme} />} />
                    <Route path="/how_to_play_details" component={HowToPlayDetails} />
                    <Route path="/how_to_play_titles" component={HowToPlayTitles} />
                    <Route path="/unusual_problems_view" component={UnusualProblemsView} />
                    <Route path="/problem_questions" component={ProblemQuestions} />
                    <Route path="/highly_rated_name_play" component={HighlyRatedNamePlay} />
                    <Route path="/illustrology_main" component={IllustrologyMain} />
                    <Route path="/createProblemTop" component={CreateAProblemTopView} />
                    <Route path="/illustrologyArticleDetails" component={IllustrologyArticleDetails} />
                    <Route path="/" exact component={Home} />
                </Switch>
            </div>
        </ThemeProvider>
    )
}

export default App;
