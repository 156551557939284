// theme.js
export const lightTheme = {
    body: '#FFFFFF',
    blueTitle: '#dfeaf5',
    text: '#363537',
    toggleBorder: '#FFF',
    selected: '#000000',
    table: '#000000',
    problem_cell: '#0000e6',
    gradient: 'linear-gradient(#39598A, #79D7ED)',
};

export const darkTheme = {
    body: '#363537',
    blueTitle: '#000000',
    text: '#FAFAFA',
    table: '#e6e6e6',
    selected: '#000000',
    toggleBorder: '#6B8096',
    problem_cell: '#8898aa',
    gradient: 'linear-gradient(#091236, #1E215D)',
};
