import React, {useState} from 'react'
import './play_illustrology_by_grid.css'
import {Link} from 'react-router-dom'
import {FaCog, FaCheck} from "react-icons/fa";
import {useEffect} from 'react';
import {BASE_URL} from '../../config';
import {saveSettings,compareValues} from '../../utils/functions';

var tempBoardGridData = ""
var gridSizelLocal="10"
//This is needed so that after sort we can set the data again
var copyOfBoardData= []
function IllustrologyPlayByGrid(props) {

    const [grid, setGrid] = useState('10');
    const [sortProperty, setSortProperty] = useState(''); // popularity, new_to_old, old_to_new
    const initialGrid = {'10': [], '15': [], '20': [], '25': [], '30': []};
    const [boardByGrid, setBoardByGrid] = useState(initialGrid);
    const [completedLevels, setCompletedLevels] = useState([]);


    // fetch all illustrologyboards and store them by levels
    useEffect(() => {
        saveSettings();
        let numplay_settings = JSON.parse(localStorage.getItem('numplay'));
        if (numplay_settings) {
            let completed_levels = [...numplay_settings.completed_levels];
            setCompletedLevels(completed_levels);
        }
        if (props.location.state) {
            setGrid(props.location.state.startingLevel);
            gridSizelLocal = props.location.state.startingLevel
        }
        const fetchData = async () => {
            await fetch(BASE_URL + '/api/illustroboards')
                .then(res => res.json())
                .then(json => {
                    categorizeDataByLevel(json.data);
                })
        };
        fetchData();
    }, []);

    const startGame = (id) => {
        props.history.push('/illustrologic', {id});
    };

    const categorizeDataByLevel = (data) => {
        let tempGrid = {...initialGrid};
        for (let key in initialGrid) {
            tempGrid[key] = data.filter(x => x.grid_size === key);
        }
        tempBoardGridData = tempGrid
        copyOfBoardData = {...tempGrid}
        sortData(sortProperty); 
    };

    const setGridSize = (event, size) => {
        event.preventDefault();
        gridSizelLocal = size;
        setGrid(size);
        sortData(sortProperty);
        props.history.replace(props.location.pathname, {...props.location.state, startingLevel: gridSizelLocal});
    };

    const redirectTo = (event, path) => {
        event.preventDefault();
        props.history.push(path);
    };

    const sortData = (sort_value) => {
        var board_by_grid = {...boardByGrid};
        if(board_by_grid[gridSizelLocal].length==0)
        {
            board_by_grid = tempBoardGridData
        }

        if (sort_value == '') {
            if (props.location.state) {
                sort_value = props.location.state.sortOrder;
            } else {
                sort_value = 'popularity'; // default value
            }
        }
        props.history.replace(props.location.pathName, {...props.location.state, sortOrder: sort_value});

        if (sort_value === 'popularity') {            
            let tempData = copyOfBoardData[gridSizelLocal].filter(item => item.person_rated>10)
            board_by_grid[gridSizelLocal] = tempData
            board_by_grid[gridSizelLocal].sort(compareValues('ratings', 'desc'));
        } else if (sort_value === 'new_to_old') {
            board_by_grid[gridSizelLocal] = copyOfBoardData[gridSizelLocal]
            board_by_grid[gridSizelLocal].sort(compareValues('created_at', 'desc'));
        }else{
            board_by_grid[gridSizelLocal] = copyOfBoardData[gridSizelLocal]
            board_by_grid[gridSizelLocal].sort(compareValues('created_at', 'asc'));
        }

        if(boardByGrid[gridSizelLocal].length==0)
        {
            setBoardByGrid(tempBoardGridData);
        }

        setBoardByGrid(board_by_grid);
        setSortProperty(sort_value);
    };


    return (
        <div>
            <div className="header">イラストロジック</div>
            <div className="top-nav">
                {/*初級 Introductory*/}

                <a href="/" className={`custom_a ${grid === '10' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setGridSize(e, '10')}>10x10</a>

                {/*初級 Beginner*/}

                <a href="/" className={`custom_a ${grid === '15' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setGridSize(e, '15')}>15x15</a>

                {/*中級 Intermediate*/}

                <a href="/" className={`custom_a ${grid === '20' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setGridSize(e, '20')}>20x20</a>

                {/*上級 Advanced*/}

                <a href="/" className={`custom_a ${grid === '25' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setGridSize(e, '25')}>25x25</a>

                {/*難問 Expert*/}

                <a href="/" className={`custom_a ${grid === '30' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setGridSize(e, '30')}>30x30</a>

                {/* Settings */}
                <Link to='/illustration_logic_setting' className='nav_box_setting'> <FaCog/> </Link>
            </div>
            <div className="secondNav">
                {/* Order of popularity */}
                <div className={(sortProperty === 'popularity' ? 'active_sort' : '')}
                     onClick={() => sortData('popularity')}>人気順
                </div>
                {/* Order of posted date (New to Old) */}
                <div className={sortProperty === 'new_to_old' ? 'active_sort' : ''}
                     onClick={() => sortData('new_to_old')}>投稿日昇順
                </div>
                {/* Order of posted date (Old to New) */}
                <div className={sortProperty === 'old_to_new' ? 'active_sort' : ''}
                     onClick={() => sortData('old_to_new')}>投稿日降順
                </div>
            </div>
            <div className="table-div">
                <table className="table-containing-boardbygrid">
                    <tbody>
                    {/* load data by levels */}
                    {
                        boardByGrid[grid].map((row, key) => {
                            let level_completed = completedLevels.find(cl => cl.difficulty_level === row.difficulty && cl.level === row.level);
                            return (
                                <tr key={key}>
                                    <td>{level_completed ? (<FaCheck/>) : ''}</td>
                                    <td>{row.title} / {row.publisher}</td>
                                    <td>
                                        <button onClick={() => startGame(row.id)}>START</button>
                                    </td>
                                </tr>
                            );
                        })
                    }

                    </tbody>
                </table>
            </div>
            <div className="footer" onClick={event => {
                redirectTo(event, '/')
            }}>イラストロジックTOPへ戻る
            </div>
        </div>
    )
}

export default IllustrologyPlayByGrid;
