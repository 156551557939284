import React, {Fragment, useEffect, useState} from 'react';
import ReactDOMServer from 'react-dom/server';
import {FaCog, FaCheck} from "react-icons/fa";
import './problem_question_navbar.css'
import './problem_question.css'
import {Link} from 'react-router-dom'
import {BASE_URL} from '../../config';
import {createChunk, saveSettings} from '../../utils/functions';
import {difficulty_japanese} from '../../utils/variables';
import SudokuPdfContent from "../../components/SudokuPdfContent";
import sudokuTemplate from "../../components/SudokuTemplate";
import {Container} from "@material-ui/core";


function createProblemChunk(myArray, chunk_size) {
  let arrayLength = myArray.length;
  let tempArray = [];
  
  for (let index = 0; index < arrayLength; index += chunk_size) {
    let myChunk = myArray.slice(index, index + chunk_size);
    myChunk = myChunk.filter(problem => problem.level >= index && problem.level <= index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
}

function ProblemQuestions(props) {

    const [difficulty_level, setDifficultyLevel] = useState('introductory');
    let serial = 0;

    const levelObject = {'introductory': [], 'beginner': [], 'intermediate': [], 'advanced': [], 'expert': []};
    const [completedLevels, setCompletedLevels] = useState([]);
    const [data, setData] = useState(levelObject);
    const [difficultyGroupItems, setDifficultyGroupItems] = useState([]);

    const headerRef = React.createRef();

    const [showHideArray, setShowHideArray] = useState([]);
    const [titlesIcon, setTitlesIcon] = useState([]);

    const changeDifficulty = (temp_data, difficulty = 'introductory') => {

        // group items 5 groups
        // introductory -> currently difficult level
        let difficulty_items = temp_data[difficulty];
        // create a chunk of 5 items
        let difficulty_group_items = createProblemChunk(difficulty_items, 5);
        let show_hide_array = [];
        let icon_array = [];
        for (let i = 1; i <= difficulty_group_items.length; i++) {
            icon_array.push('▽');
            show_hide_array.push(true);
        }
        setDifficultyLevel(difficulty);
        setTitlesIcon(icon_array);
        setShowHideArray(show_hide_array);
        setDifficultyGroupItems(difficulty_group_items);
    };

    const changeDifficultyHandler = (event, difficulty) => {
        event.preventDefault();
        changeDifficulty(data, difficulty);
        props.history.replace(props.location.pathname, {difficulty: difficulty});
    };

    function storeData(data, difficulty) {

        let temp_data = {...data};

        Object.keys(levelObject).map((key) => {
            temp_data[key] = data.filter((object) => {
                return object['difficulty_level'] === key;
            });
        });

        changeDifficulty(temp_data, difficulty);
        setData(temp_data);
    }

    function hideOnClick(id) {
        let tempArray = [...showHideArray];
        let tempTitle = [...titlesIcon];

        if (tempArray[id] === true) {
            tempArray[id] = false;
            tempTitle[id] = '▽';
        } else {
            tempArray[id] = true;
            tempTitle[id] = '△';
        }

        setShowHideArray(tempArray);
        setTitlesIcon(tempTitle);
    }

    const redirectTo = (event, path) => {
        event.preventDefault();
        props.history.push(path,{difficulty_level});
    };

    const contentHtml = (problems) => {
        return (
            <Fragment>
                <SudokuPdfContent problems={problems}/>
            </Fragment>
        )
    };
    const onPrintPdf = (index) => {
        // render the html and send to server for generating pdf.
        let static_content_html = ReactDOMServer.renderToStaticMarkup(contentHtml(difficultyGroupItems[index]));
        let html = sudokuTemplate(static_content_html);
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let data = {
            html: html
        };

        var bodyData = JSON.stringify(data)
        bodyData = bodyData.replace(/\\n/g, '')
        fetch(BASE_URL + '/api/sudoku/pdf', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: myHeaders
        }).then(res => res.blob())
            .then(blob => {
                const a = document.createElement('a');
                document.body.appendChild(a);
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = 'sudoku.pdf';
                a.click();
                setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                }, 0)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    const startGame = (id, difficulty_level, group_index) => {
        props.history.push('/sudoku', {id, difficulty_level, problems: difficultyGroupItems[group_index]});
    };

    useEffect(() => {
        saveSettings();
        let sudoku_settings = JSON.parse(localStorage.getItem('sudoku'));
        if (sudoku_settings) {
            let completed_levels = [...sudoku_settings.completed_levels];
            setCompletedLevels(completed_levels);
        }
        const fetchData = async () => {
            fetch(BASE_URL + '/api/boards/published')
                .then(res => res.json())
                .then(json => {
                    if (props.location.state) {
                        storeData(json.data, props.location.state.difficulty);
                    } else {
                        storeData(json.data)
                    }
                })
                .catch(error => console.log(error))
        };
        fetchData();

    }, []);

    const incrementSerial = function (value) {
        serial = value;
    };


    return (
        <div id='parent_wrapper'>

            <div id='header' ref={headerRef}>
                問題集(過去問)
            </div>

            <div className="top-nav">
                {/*初級 Introductory*/}

                <a href="/" className={`custom_a ${difficulty_level === 'introductory' ? 'top-nav_active' : ''}`}
                   onClick={event => changeDifficultyHandler(event, 'introductory')}>入門</a>

                {/*初級 Beginner*/}

                <a href="/" className={`custom_a ${difficulty_level === 'beginner' ? 'top-nav_active' : ''}`}
                   onClick={event => changeDifficultyHandler(event, 'beginner')}>初級</a>

                {/*中級 Intermediate*/}

                <a href="/" className={`custom_a ${difficulty_level === 'intermediate' ? 'top-nav_active' : ''}`}
                   onClick={event => changeDifficultyHandler(event, 'intermediate')}>中級</a>

                {/*上級 Advanced*/}

                <a href="/" className={`custom_a ${difficulty_level === 'advanced' ? 'top-nav_active' : ''}`}
                   onClick={event => changeDifficultyHandler(event, 'advanced')}>上級</a>

                {/*難問 Difficult question*/}

                <a href="/" className={`custom_a ${difficulty_level === 'expert' ? 'top-nav_active' : ''}`}
                   onClick={event => changeDifficultyHandler(event, 'expert')}>難問</a>

                {/* Settings */}

                <Link to='/setting' className='nav_box_setting'><FaCog/></Link>
            </div>

            <div id='topBlueButtonDiv'>
                <button onClick={(e) => redirectTo(e, '/highly_rated_name_play')}>評価の高いナンプレを遊ぶ</button>
            </div>

            {/*loop through the difficulty group items*/}
            {
                difficultyGroupItems.map((group, group_index) => {
                    let s = serial;
                    return (
                        <div id='group' key={group_index}>
                            {
                                difficultyGroupItems.length === group_index + 1 ?
                                    null :
                                    <Fragment>
                                        <div id='titleDesign' onClick={() => hideOnClick(group_index)}>
                                            {titlesIcon[group_index]} {difficulty_japanese[difficulty_level]} {serial + 1}～{serial + 5}
                                        </div>
                                        {
                                            showHideArray[group_index] ?
                                                <div id="content">
                                                    <table>
                                                        <tbody>
                                                        {
                                                            group.map((row, key) => {
                                                                let level_completed = completedLevels.find(cl => cl.difficulty_level === row.difficulty_level && cl.level === row.level);
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{level_completed ? (<FaCheck/>) : ''}</td>
                                                                        <td>問題</td>
                                                                        <td>{row.level}</td>
                                                                        <td>
                                                                            <button className='start_button'
                                                                                    onClick={() => startGame(row.id, row.difficulty_level, group_index)}>START
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div> : null
                                        }
                                        {/* Body List Ends */}
                                        <button id='whiteButton'
                                                onClick={() => onPrintPdf(group_index)}>{serial + 1}～{serial + 5} を印刷
                                        </button>
                                        {
                                            incrementSerial(serial + group.length)
                                        }
                                    </Fragment>
                            }
                        </div>
                    )
                })
            }

            <div id="footer" onClick={event => redirectTo(event, '/')}>
                ナンプレTOPへ戻る
            </div>

        </div>


    );
}

export default ProblemQuestions;
