import React from "react";
import './BoardInput.css';
import {createChunk} from "../../../../utils/functions";

const BoardInput = (props) => {
    const {dataObject, sum} = props;
    const {value, color, memo, is_editable} = dataObject;
    // color value is either black = default_color or green = safe_color or red = unsafe_color
    let color_value = '';

    // here check the memo
    // if memo array is empty then load the actual value
    // else show the memo table inside the cell
    let memo_array = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

    let render;
    if (memo.length > 0) {
        let memo_chunk = createChunk(memo_array, 3);
        // loop through memo_chunk
        render = <table className="memo-table">
            <tbody>
            {memo_chunk.map((rows, key) => {
                return (
                    <tr key={key} className="memo-table-row">
                        {
                            rows.map((col, index) => {
                                return (
                                    <td key={index}
                                        className="memo-table-row-col">{memo.find(m => m === col) ? col : ''}</td>
                                )
                            })
                        }
                    </tr>
                )
            })
            }
            </tbody>
        </table>
        // check if the loop index match with the memo then add that value into the td cell
        // if no matching value that means that table cell will be empty
        // chunk the memo_array
        // so that memo array will be a 2d array with 3 * 3 means 3 row and 3 col
    } else {
        render = value === '0' || value === 0 ? '' : <div className='input_value'>{value}</div>
    }

    if (color === 'green') {
        color_value = 'safe_color';
    } else if (color === 'red') {
        color_value = 'unsafe_color';
    } else {
        color_value = `default_color ${!is_editable ? 'problem_input' : 'input_cell'}`;
    }

    let sum_value = '';
    if (sum) {
        sum_value = sum.value === '0' ? '' : sum.value
    }
    return (
        <div className={`sum_input ${color_value} 
                         ${dataObject.left ? 'sum_left_border' : ''}
                         ${dataObject.right ? 'sum_right_border' : ''}
                         ${dataObject.top ? 'sum_top_border' : ''}
                         ${dataObject.bottom ? 'sum_bottom_border' : ''}`}>
            {render}
            <div className={`sum_value`}>{sum_value}</div>
        </div>
    )
};

export default BoardInput;
