import React, { useEffect, useState } from 'react';
import IllustrationLogicInput from '../../components/IllustrationLogicInput';
import { createChunk, saveSettings } from '../../utils/functions';
import { BASE_URL } from '../../config';

var tableDataFromProblemInputScreen = [];

function ProblemSubmitScreen(props) {
	const extraCells = {
		10: 5, // grid size 10 will have 5 extra problem cells
		15: 8, // grid size 15 will have 8 extra problem cells
		20: 10, // grid size 20 will have 10 extra problem cells
		25: 13, // grid size 25 will have 13 extra problem cells
		30: 15, // grid size 30 will have 15 extra problem cells
	};
	// this is the game table array. this is a 2d array
	const [tableData, setTableData] = useState([]);
	const [difficultyLevel, setDifficultyLevel] = useState('');
	const [publisher, setPublisher] = useState('');
	const [title, setTitle] = useState('');
	const [answerTitle, setAnswerTitle] = useState('');
	const [problem, setProblem] = useState('');
	const [solution, setSolution] = useState('');
	const [gridSize, setGridSize] = useState(10);

	const onClickProblemCell = (row, col) => {
		console.log(row, col);
	};
	const redirectTo = (event, path) => {
		props.history.push(path);
	};

	const countColumnsOne = (col, table, extra_cells) => {
		let count_cols_one = [];
		let count = 0;
		for (let i = extra_cells; i < table[col].length; i++) {
			// scan the vertical problem
			let value =
				table[i][col].value === 'cross' || table[i][col].value === 'dot' || table[i][col].value === '0' ? '0' : '1';
			// if value '1' and next value is also '1' continue counting
			// if value '1' and next value zero count and push into the count_rows_one
			// after pushing set the count to zero
			// and final condition if the last value and count not zero. then push the count value into the count_rows_one
			if (value === '1' && !isNextRowZero(i, col, table)) {
				count++;
			} else if (value === '1' && isNextRowZero(i, col, table)) {
				count++;
				count_cols_one.push(count);
				count = 0;
			}
		}
		return count_cols_one;
	};

	const countRowsOne = (row, table, extra_cells) => {
		let count = 0;
		let count_rows_one = [];
		for (let i = extra_cells; i < table[row].length; i++) {
			// scan the horizontal problem
			let value =
				table[row][i].value === 'cross' || table[row][i].value === 'dot' || table[row][i].value === '0' ? '0' : '1';
			// if value '1' and selected row next column value is also '1' continue counting
			if (value === '1' && !isNextColZero(row, i, table)) {
				count++;
			} else if (value === '1' && isNextColZero(row, i, table)) {
				count++;
				count_rows_one.push(count);
				count = 0;
			}
		}
		return count_rows_one;
	};

	const isNextColZero = (row, col, table) => {
		// check the selected row next column value
		if (col + 1 > table.length - 1) return true;

		let value =
			table[row][col + 1].value === 'cross' || table[row][col + 1].value === 'dot' || table[row][col + 1].value === '0'
				? '0'
				: '1';

		return value === '0';
	};

	const isNextRowZero = (row, col, table) => {
		// check the selected column next row value
		if (row + 1 > table.length - 1) return true;

		let value =
			table[row + 1][col].value === 'cross' || table[row + 1][col].value === 'dot' || table[row + 1][col].value === '0'
				? '0'
				: '1';

		return value === '0';
	};

	const loadInitialBoard = () => {
		let data_array = [];
		if (props.location.state) {
			let grid_size = parseInt(props.location.state.gridSize);
			let extra_cells = extraCells[grid_size];
			let table_data = props.location.state.tableData;
			tableDataFromProblemInputScreen = table_data;
			let grid_with_problem = grid_size + extra_cells;
			for (let i = 0; i < grid_with_problem; i++) {
				for (let j = 0; j < grid_with_problem; j++) {
					data_array.push({
						value: '0',
						is_fill: false,
						is_editable: true,
						valid: true,
						is_focused: false,
						color: 'black',
					});
				}
			}
			let table_chunk = createChunk(data_array, grid_with_problem);
			for (let i = extra_cells; i < grid_with_problem; i++) {
				for (let j = extra_cells; j < grid_with_problem; j++) {
					table_chunk[i][j] = table_data[i - extra_cells][j - extra_cells];
				}
			}
			// here generate solution

			// GENERATE SOLUTION FOR HORIZONTAL ROW
			// for horizontal row, row value is fixed column value will change
			let startingRow = extra_cells;
			for (let i = extra_cells; i < table_chunk.length; i++) {
				// after doing all the task increment startingRow value
				let rowsOne = countRowsOne(startingRow, table_chunk, extra_cells);
				let start_col_index = extra_cells - 1;
				while (rowsOne.length !== 0) {
					// pop item from the rowsOne and put into the problem cell accordingly
					table_chunk[startingRow][start_col_index].value = rowsOne.pop();
					start_col_index--;
				}
				startingRow++;
			}

			// GENERATE SOLUTION FOR VERTICAL COLUMN
			// for vertical column, column value is fixed row value will change
			let startingColumn = extra_cells;
			for (let i = extra_cells; i < table_chunk.length; i++) {
				// after doing all the task increment startingColumn value
				let columnsOne = countColumnsOne(startingColumn, table_chunk, extra_cells);
				let start_row_index = extra_cells - 1;
				while (columnsOne.length !== 0) {
					// pop item from the rowsOne and put into the problem cell accordingly
					table_chunk[start_row_index][startingColumn].value = columnsOne.pop();
					start_row_index--;
				}
				startingColumn++;
			}
			// after doing all the processing
			// parse the problem from the table_chunk
			setSolution(props.location.state.solution);
			let problem = parseProblem(table_chunk, grid_with_problem, extra_cells);
			countSkippableRows(table_chunk, grid_size);
			countSkippableCols(table_chunk, grid_size);
			setProblem(problem);
			setGridSize(grid_size);
			setTableData(table_chunk);
		}
	};

	const parseProblem = (table, grid_size, extra_cells) => {
		// **************** PARSE THE PROBLEM ***************
		let result = '';
		for (let row = extra_cells; row < grid_size; row++) {
			let temp = '';
			for (let col = extra_cells - 1; col >= 0; col--) {
				let data = table[row][col].value;
				if (data === '0') break;

				if (col !== extra_cells - 1) temp += ',';
				temp += data;
			}
			result += temp;

			if (row + 1 < grid_size) result += '+';
		}

		result += '|';

		for (let col = extra_cells; col < grid_size; col++) {
			let temp = '';
			for (let row = extra_cells - 1; row >= 0; row--) {
				let data = table[row][col].value;
				if (data === '0') break;

				if (row !== extra_cells - 1) temp += ',';
				temp += data;
			}
			result += temp;

			if (col + 1 < grid_size) result += '+';
		}
		return result;
	};

	const createProblem = () => {
		let myHeaders = new Headers();
		myHeaders.append('Content-Type', 'application/json');

		let raw = JSON.stringify({
			title: title,
			created_by: publisher,
			answer_title: answerTitle,
			difficulty: difficultyLevel,
			grid_size: gridSize.toString(),
			solution: solution,
			board_data: problem,
		});

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
		};

		fetch(BASE_URL + '/api/illustrologyupload', requestOptions)
			.then((response) => response.text())
			.then((result) => props.history.push('/post'))
			.catch((error) => alert('Unable to submit the problem'));
	};
	const submitProblem = () => {
		let errors = [];
		if (publisher === '' || answerTitle === '' || title === '' || difficultyLevel === '') {
			errors.push('全て入力してください');
		}
		if (answerTitle === title) {
			errors.push('問題のタイトルと答えはちがうものにしてください');
		}
		// send the api request here for creating the problem
		// validate the input and if there is no error then submit the problem
		if (errors.length === 0) createProblem();
		else alert(errors[0]);
	};


	// similar to class based lifecycle hook method componentDidMount
	// componentDidUpdate componentWillMount
	useEffect(() => {
		saveSettings();
		loadInitialBoard();
	}, []);

	const isProblemRowEmpty = (row, rowindex, grid_size) => {
		if (rowindex < extraCells[grid_size]) {
			let sum = 0;
			sum = row.reduce((a, c) => (a += parseInt(c.value)), 0);
			return sum == 0;
		} else {
			return false;
		}
	};

	const isProblemColEmpty_v2 = (table, colindex, grid_size) => {
		if (colindex < extraCells[grid_size]) {
			let sum = 0;
            for (let i = extraCells[grid_size] - 1 ; i < extraCells[grid_size] + grid_size; i++) {
				sum += parseInt(table[i][colindex].value);
            }
            return sum == 0;
		} else {
			return false;
		}
	};

	const [skipRowsCount, setSkipRowsCount] = useState(0);
	const countSkippableRows = (table, grid_size) => {
		let count = 0;
		let index = 0;
		while (index < extraCells[grid_size]) {
			if (isProblemRowEmpty(table[index], index, grid_size)) {
				count++;
			}
			index++;
        }
        setSkipRowsCount(count);
	};
	const [skipColsCount, setSkipColsCount] = useState(0);
    const countSkippableCols = (table, grid_size) => {
        let count = 0;
		let index = 0;
		while (index < extraCells[grid_size]) {
			if (isProblemColEmpty_v2(table, index, grid_size)) count++;
			index++;
        }
        setSkipColsCount(count);
	};

	return (
		<div>
			<div className="firstBody">
				<div className="header">問題作成 問題の投稿</div>

				<div id="bodyText">
					複数解はありませんでした。
					<br />
					下の図案でよければ、
					<br />
					問題のタイトルを付けて「決定」を押してください。
				</div>

				{/* Board start */}
					<div className={`game_table_submit`}>
                    {tableData.slice(skipRowsCount).map((rows, row) => {
                        let numberOfGhor = tableData.length;
                        let numberOfGhorToPaint = numberOfGhor - (skipColsCount < skipRowsCount ? skipColsCount : skipRowsCount);
                        let height = 300 / numberOfGhorToPaint + "px";
                        let width = 300 / numberOfGhorToPaint + "px";
							return (
								<div key={row} className="game_table_tr_submit">
									{rows.slice(skipColsCount).map((cell, col) => {
										return (
											<div key={col} className={`game_table_td_submit`} style={{width: width, height: height}}>
												<IllustrationLogicInput
													dataObject={cell}
													onClickProblemCell={onClickProblemCell}
													row={row + skipRowsCount}
													col={col + skipColsCount}
													gridSize={gridSize}
													extraCells={extraCells[gridSize]}
													fontSize={8}
												/> 
                                            </div>
										);
									})}
								</div>
							);
						})}
					</div>
				{/* Board End */}
				<br />
				<div>
				{/* Text Input fields start */}
				<div className="form_container">
					<div className="lebel_side">
						<div id="leftTextAllign">
							{/*PROBLEM TITLE*/}
							問題のタイトル
						</div>
					</div>
					<div className="input_side" id="leftAllign">
						<input
							type="text"
							className="textBox"
							placeholder="タイトル"
							onChange={(event) => setTitle(event.target.value)}
						/>
					</div>
				</div>
					<div className="smallText">
						{/*Do not write the answer in the title*/}
						※タイトルに答えを書かないでください。
					</div>
					<div className="form_container">
						<div className="lebel_side">
							<div id="leftTextAllign">
								{/*PROBLEM ANSWER TITLE*/}
								問題の答え
							</div>
						</div>
						<div className="input_side" id="leftAllign">
							<input
								type="text"
								className="textBox"
								placeholder="答え"
								onChange={(event) => setAnswerTitle(event.target.value)}
							/>
						</div>
					</div>
					<div className="smallText">
						{/* ※個人を特定できない名前にしてください。 */}
						<br></br>
					</div>
					<div className="form_container">
						<div className="lebel_side">
							<div id="leftTextAllign">
								{/*Nickname*/}
								ハンドルネーム
							</div>
						</div>
						<div className="input_side" id="leftAllign">
							<input
								type="text"
								className="textBox"
								placeholder="答ハンドルネームえ"
								onChange={(event) => setPublisher(event.target.value)}
							/>
						</div>
					</div>
					<div className="smallText">※個人を特定できない名前にしてください。</div>
					<br />
					<div className="form_container">
						<div className="lebel_side">
							<div id="leftTextAllign">
								{/*Problem Difficulty Selection*/}
								希望難易度(必須)
							</div>
						</div>
						<div className="input_side" id="leftAllign">
							<select name="level" className={'textBox'} onChange={(event) => setDifficultyLevel(event.target.value)}>
								<option value="">難易度を選択 ▽</option>
								<option value="introductory">入門</option>
								<option value="beginner">初級</option>
								<option value="intermediate">中級</option>
								<option value="advanced">上級</option>
								<option value="expert">難問</option>
							</select>
						</div>
					</div>
					<div className="smallText">※個人を特定できない名前にしてください。</div>
						{/* Text Input fields end */}
				</div>
			</div>

			<br />

			<div className="bigButtonsBody">
				<button className="bodyBtnWhite" onClick={(event) => submitProblem()}>
					送信
				</button>
				<button
					className="bodyBtnBlue"
					onClick={(event) => props.history.push('/input', { tableData: tableDataFromProblemInputScreen, gridSize })}
				>
					問題入力に戻る
				</button>
			</div>

			<div className="footer" onClick={(event) => redirectTo(event, '/illustrology_main')}>
				イラストロジックTOPへ戻る
			</div>
		</div>
	);
}

export default ProblemSubmitScreen;
