import React, { useEffect } from "react";
import { FaCog, FaUndoAlt } from "react-icons/fa";
import BoardInput from "./components/BoardInput";
import "./grid_sum_board.css";
import SudokuSuccessModal from "../../components/SudokuSuccessModal";
import SudokuLoseModal from "../../components/SudokuLoseModal";
import { Link } from "react-router-dom";
import {
  createChunk,
  getCurrentTime,
  saveSettings,
  getRandomInt,
} from "../../utils/functions";
import { BASE_URL } from "../../config";
import moment from "moment";

// ******** GAME SETTINGS **********
// Night Mode
// Auto Save
// Midway Check Function "Validate the input"
// Highlight selected number
// Highlight the vertical, horizontal and grid area of the selected cell
// Gray out numbers used nine times

// Automatically delete notes after confirmation
// when ON means need to delete if other memo cell containing the same confirmation number
// in the row col
// when OFF means no need to delete other memo cell containing the same number

// ********* STYLE RULES *********
// Rather than adding style directly add className. so that we have more control

var idLocal = 0;
var difficultyLevelLocal = 0;

function GridSumBoard(props) {
  const difficulty_japanese = {
    introductory: "入門",
    beginner: "初級",
    intermediate: "中級",
    advanced: "上級",
    expert: "難問",
  };
  // using hooks
  // which cell input is changing we should keep track
  const num_counter = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 };

  // problem stages help to track problem by category
  const problemStages = {
    introductory: [],
    beginner: [],
    intermediate: [],
    advanced: [],
    expert: [],
  };
  // levels by category
  const [levels, setLevels] = React.useState(problemStages);
  // game settings {settings:{}, board:[], level:1, difficulty_level:'introductory'}
  const [sudokuSettings, setSudokuSettings] = React.useState({});
  // game start time
  const [startTime, setStartTime] = React.useState(new Date());
  // num counter for tracking a number how many times used
  const [numCounter, setNumCounter] = React.useState(num_counter);
  // operation stack is used undo operation
  const [operationStack, setOperationStack] = React.useState([]);
  // this is the end time of the game
  const [endTime, setEndTime] = React.useState("");
  // when a level is complete show the success dialog
  const [successDialog, setSuccessDialog] = React.useState(false);
  // when a user lose the game then show the lose dialog
  const [loseDialog, setLoseDialog] = React.useState(false);
  // this will check if the continuous input is on or not
  const [continuousInput, setContinuousInput] = React.useState({
    is_continuous: false,
    label: "",
  });
  // this will check if the memo is on or off
  // memo means in a single cell can give multiple input
  const [memo, setMemo] = React.useState({ is_memo: false, label: "" });
  // this is the difficultyLevel of the current game
  const [difficultyLevel, setDifficultyLevel] = React.useState("introductory");
  // this is the game board array
  const [boardArray, setBoardArray] = React.useState([]);
  // this keeps track focus input cell
  const [focusInput, setFocusInput] = React.useState({
    row: -1,
    col: -1,
    focused: false,
  });
  // after fetching all the problems will set into the problems array
  const [problems, setProblems] = React.useState([]);
  // this will set the current game level
  const [currentLevel, setCurrentLevel] = React.useState(1);
  // this will keep track for retire from the game
  const [retire, setRetire] = React.useState(false);
  // create a chunk of gridSize
  // if gridSize is 9 and there are 81 items then chunkSize will be 9
  const [successMessage, setSuccessMessage] = React.useState("");
  // facebook, twitter, line share message
  const [shareMessage, setShareMessage] = React.useState("");
  // load the solution array
  const [solution, setSolution] = React.useState([]);
  // grid size of the board
  const [gridSize, setGridSize] = React.useState(9);
  // id of the current board array
  const [id, setId] = React.useState("");
  // success modal image url
  const [imageUrl, setImageUrl] = React.useState("");
  // paths
  const [paths, setPaths] = React.useState([]);
  // sums
  const [sums, setSums] = React.useState([]);

  const [activeInput, setActiveInput] = React.useState("");

  // for undo operation we should keep track table data and numCounter

  const onFocusInput = (row, col) => {
    let newBoardArray = [...boardArray];
    let boardObject = newBoardArray[row][col];

    // ****** Apply settings *******
    // on focus an input cell if the number of that cell is not zero. then highlight all the number
    if (sudokuSettings.settings.highlight_selected) {
      if (boardObject.value !== "0") {
        highlightNumber(boardObject.value);
      } else {
        highlightNumber("");
      }
    }
    if (!boardObject.is_editable || retire) {
      return;
    }
    let newSudokuSettings = { ...sudokuSettings };
    let operation_stack = [...operationStack];

    // first check if memo and continuous input both is on
    // else check if continuousInput.is_continuous && continuousInput.label !== ''
    if (
      memo.is_memo &&
      continuousInput.is_continuous &&
      continuousInput.label !== ""
    ) {
      let boardObject = newBoardArray[row][col];
      let new_memo = [...boardObject.memo];
      // since we are adding two new fields to the boardObject. we need to spread first
      operation_stack.push({
        ...boardObject,
        row: row,
        col: col,
      });
      setOperationStack(operation_stack);
      // check the boardObject memo already contains the number
      if (!boardObject.memo.find((n) => n === continuousInput.label)) {
        new_memo.push(continuousInput.label);
        boardObject.memo = new_memo;
        newBoardArray[row][col] = boardObject;
        newSudokuSettings.board = newBoardArray;
      } else {
        // means found the number already in the memo. just remove the number from the memo
        new_memo = new_memo.filter((m) => m !== continuousInput.label);
        boardObject.memo = new_memo;
        newBoardArray[row][col] = boardObject;
        newSudokuSettings.board = newBoardArray;
      }
    } else if (continuousInput.is_continuous && continuousInput.label !== "") {
      let num_counter = { ...numCounter };
      num_counter[continuousInput.label] =
        num_counter[continuousInput.label] + 1;
      // since we are adding two new fields to the boardObject. we need to spread first
      operation_stack.push({
        ...boardObject,
        row: row,
        col: col,
      });
      boardObject.value = continuousInput.label;
      setNumCounter(num_counter);
      setOperationStack(operation_stack);
    }

    // check if previously focused input clicked
    if (focusInput.focused) {
      // check if the same input was clicked before
      if (row === focusInput.row && col === focusInput.col) {
        let newFocused = { row, col, focused: false };
        boardObject.border = "1px solid black";

        // ******* Apply Settings ********
        // on focus select the horizontal and vertical cell also grid
        newBoardArray = sudokuSettings.settings.vertical_horizontal_select
          ? horizontalVerticalGridSelect(row, col, newBoardArray, true)
          : newBoardArray;
        setFocusInput(newFocused);
      } else {
        let newFocused = { row, col, focused: true };
        boardObject.border = "2px solid blue";
        // ******* Apply Settings ********
        // on focus select the horizontal and vertical cell also grid
        newBoardArray = sudokuSettings.settings.vertical_horizontal_select
          ? horizontalVerticalGridSelect(row, col, newBoardArray)
          : newBoardArray;
        // clear the previously focused input
        newBoardArray[focusInput.row][focusInput.col].border =
          "1px solid black";
        setFocusInput(newFocused);
      }
    } else {
      let newFocused = { row, col, focused: true };
      boardObject.border = "2px solid blue";
      // ******* Apply Settings ********
      // on focus select the horizontal and vertical cell also grid
      newBoardArray = sudokuSettings.settings.vertical_horizontal_select
        ? horizontalVerticalGridSelect(row, col, newBoardArray)
        : newBoardArray;
      setFocusInput(newFocused);
    }
    newBoardArray[row][col] = boardObject;
    // save the newBoardArray in the localStorage
    newSudokuSettings.board = newBoardArray;

    saveSudokuSettings(newSudokuSettings);
    if (continuousInput.is_continuous && continuousInput.label !== "") {
      let number = newBoardArray[row][col].value;
      validateInput(row, col, number, newBoardArray);
    } else {
      setBoardArray(newBoardArray);
    }
    if (isLastInput()) {
      checkAnswer();
    }
  };

  const highlightNumber = (number) => {
    let newBoardArray = [...boardArray];
    newBoardArray.forEach((rows) => {
      rows.forEach((b) => {
        if (b.memo.length === 0) {
          b.is_highlight = b.value === number;
        } else b.is_highlight = false;
      });
    });
    setBoardArray(newBoardArray);
  };

  const horizontalVerticalGridSelect = (
    row,
    col,
    board_array,
    clear = false
  ) => {
    // first clear the previous selection
    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        board_array[i][j].is_selected = false;
      }
    }
    // this means we want to clear all the previously selected cells.
    if (clear) return board_array;

    // ROW SELECTION
    for (let i = 0; i < gridSize; i++) {
      board_array[row][i].is_selected = true;
    }

    // COLUMN SELECTION
    for (let i = 0; i < gridSize; i++) {
      board_array[i][col].is_selected = true;
    }

    // GRID SELECTION
    const r = row - (row % 3);
    const c = col - (col % 3);
    for (let i = r; i < r + 3; i++) {
      for (let j = c; j < c + 3; j++) {
        board_array[i][j].is_selected = true;
      }
    }
    return board_array;
  };

  const findPaths = (row, col) => {
    for (let i = 0; i < paths.length; i++) {
      let found = paths[i].find((p) => p.row === row && p.col === col);
      if (found) return paths[i];
    }
  };

  const onInputNumber = (number) => {
    // check the currently focused input
    // At a single cell user can give input many times
    let newBoardArray = [...boardArray];
    let operation_stack = [...operationStack];

    if (memo.is_memo) {
      // set the number as current memo label
      setMemo({ is_memo: true, label: number });
    }

    if (memo.is_memo && focusInput.focused) {
      let boardObject = newBoardArray[focusInput.row][focusInput.col];
      let new_memo = [...boardObject.memo];
      // since we are adding two new fields to the boardObject. we need to spread first
      operation_stack.push({
        ...boardObject,
        row: focusInput.row,
        col: focusInput.col,
      });
      setOperationStack(operation_stack);
      // check the boardObject memo already contains the number
      if (!boardObject.memo.find((n) => n === number)) {
        new_memo.push(number);
        boardObject.memo = new_memo;
        newBoardArray[focusInput.row][focusInput.col] = boardObject;
        // save the newBoardArray in the localStorage
        let newSudokuSettings = { ...sudokuSettings };
        newSudokuSettings.board = newBoardArray;
        saveSudokuSettings(newSudokuSettings);
        setBoardArray(newBoardArray);
      } else {
        // means found the number already in the memo. just remove the number from the memo
        new_memo = new_memo.filter((m) => m !== number);
        boardObject.memo = new_memo;
        newBoardArray[focusInput.row][focusInput.col] = boardObject;
        // save the newBoardArray in the localStorage
        let newSudokuSettings = { ...sudokuSettings };
        newSudokuSettings.board = newBoardArray;
        saveSudokuSettings(newSudokuSettings);
        setBoardArray(newBoardArray);
      }
    } else if (focusInput.focused) {
      let boardObject = newBoardArray[focusInput.row][focusInput.col];
      let num_counter = { ...numCounter };
      // if the currently input number is not the same as cell value then count
      if (boardObject.value !== number) {
        num_counter[number] += 1;
        operation_stack.push({
          ...newBoardArray[focusInput.row][focusInput.col],
          row: focusInput.row,
          col: focusInput.col,
        });
        setOperationStack(operation_stack);
      }

      // here validate the input before pushing it to the array
      // if number is_safe then make it's color to green else red

      boardObject.memo = [];
      boardObject.value = number;
      newBoardArray[focusInput.row][focusInput.col] = boardObject;
      // ****** Apply settings *******
      // Check the Midway value in the localStorage. if it's set to true then make it red or green onInput
      validateInput(focusInput.row, focusInput.col, number, newBoardArray);
      // save the newBoardArray in the localStorage
      let newSudokuSettings = { ...sudokuSettings };
      newSudokuSettings.board = newBoardArray;
      saveSudokuSettings(newSudokuSettings);
      setNumCounter(num_counter);
    }
    if (continuousInput.is_continuous) {
      // set the number as current input
      setActiveInput(number);
      setContinuousInput({ is_continuous: true, label: number });
    }

    //By farhan
    let newSudokuSettings = { ...sudokuSettings };
    newSudokuSettings.level = currentLevel;
    saveSudokuSettings(newSudokuSettings);

    // Highlight the selected number
    // gray out number used nine times

    // check if there is any zero value in the board
    // if no zero value then check if the solution is correct or not.
    if (isLastInput()) {
      checkAnswer();
    }
  };

  const deleteNotes = (row, col, number, board_array) => {
    // scan the entire row
    // scan the entire col

    // ROW SELECTION
    for (let i = 0; i < gridSize; i++) {
      // check if the memo contains the number then remove that number from the memo array
      let new_memo = [...board_array[row][i].memo];
      board_array[row][i].memo = new_memo.filter((n) => n !== number);
    }

    // COLUMN SELECTION
    for (let i = 0; i < gridSize; i++) {
      // check if the memo contains the number then remove that number from the memo array
      let new_memo = [...board_array[i][col].memo];
      board_array[i][col].memo = new_memo.filter((n) => n !== number);
    }

    //3*3 area selection
    // GRID SELECTION
    const r = row - (row % 3);
    const c = col - (col % 3);
    for (let i = r; i < r + 3; i++) {
      for (let j = c; j < c + 3; j++) {
        let new_memo = [...board_array[i][j].memo];
        board_array[i][j].memo = new_memo.filter((n) => n !== number);
      }
    }

    // finally make the board_array memo empty
    board_array[row][col].memo = [];
    board_array[row][col].value = number;
    return board_array;
  };

  // if new input is given then validate all the input
  const validateInput = (row, col, number, board_array) => {
    if (sudokuSettings.settings.auto_delete && !memo.is_memo) {
      board_array = deleteNotes(row, col, number, board_array);
    }
    if (
      sudokuSettings.settings.midway_check &&
      board_array[row][col].value !== "0"
    ) {
      // validate that row col and grid
      if (isSafe(row, col, number)) {
        board_array[row][col].color = "default";
      } else {
        board_array[row][col].color = "red";
      }
      // revalidate all the inputs again
      board_array = revalidateInput(row, col, board_array);
    }
    setBoardArray(board_array);
  };

  const revalidateInput = (row, col, board_array) => {
    // validate the row
    for (let i = 0; i < gridSize; i++) {
      // row and col is i
      if (board_array[row][i].is_editable) {
        if (isSafe(row, i, board_array[row][i].value)) {
          board_array[row][i].color = "default";
        } else {
          board_array[row][i].color = "red";
        }
      }
    }
    // validate the column
    for (let i = 0; i < gridSize; i++) {
      // row is i and col
      if (board_array[i][col].is_editable) {
        if (isSafe(i, col, board_array[i][col].value)) {
          board_array[i][col].color = "default";
        } else {
          board_array[i][col].color = "red";
        }
      }
    }
    // validate the grid
    const r = row - (row % 3);
    const c = col - (col % 3);
    for (let i = r; i < r + 3; i++) {
      for (let j = c; j < c + 3; j++) {
        if (board_array[i][j].is_editable) {
          if (isSafe(i, j, board_array[i][j].value)) {
            board_array[i][j].color = "default";
          } else {
            board_array[i][j].color = "red";
          }
        }
      }
    }
    return board_array;
  };

  const arrangeProblemStages = (problems) => {
    let temp_data = { ...levels };

    Object.keys(levels).map((key) => {
      temp_data[key] = problems.filter((object) => {
        return object["difficulty_level"] === key;
      });
    });
    // temp_data for each keys filter out the data by recent publish date
    Object.keys(temp_data).map((key) => {
      // find the temp_data[key] max publish date
      let max_date = temp_data[key].reduce(function (a, b) {
        return new Date(a.pulish_date) > new Date(b.publish_date)
          ? a.publish_date
          : b.publish_date;
      }, "");
      if (max_date !== "") {
        // finally filter the data by max date
        temp_data[key] = temp_data[key].filter(
          (t) => t.publish_date === max_date
        );
      }
    });
    setLevels(temp_data);
  };

  const saveSudokuSettings = (settings) => {
    localStorage.setItem("sudoku_sum", JSON.stringify(settings));
    setSudokuSettings(settings);
  };

  const t = (obj) => {
    return parseInt(obj);
  };

  const onLoadLevel = (
    level,
    difficulty_level = "introductory",
    problems = []
  ) => {
    setActiveInput("");
    setProblems(problems);
    setOperationStack([]);
    setSuccessDialog(false);
    setLoseDialog(false);
    // load a new level by the clicked level
    let filtered_problems = problems.filter(
      (p) => p.difficulty_level === difficulty_level
    );
    let single_problem = filtered_problems.find((p) => t(p.level) === t(level));
    if (filtered_problems.length > 0) {
      if (!single_problem) single_problem = filtered_problems[0];
    }
    if (single_problem) {
      // set the current level
      // save the level and difficulty_level in the localStorage
      let newSudokuSettings = { ...sudokuSettings };
      newSudokuSettings.level = single_problem.level;
      newSudokuSettings.difficulty_level = difficulty_level;
      newSudokuSettings.board = [];
      newSudokuSettings.id = single_problem.id;
      generateSolution(single_problem.solution, single_problem.grid_size);
      generatePaths(single_problem.paths);
      generateSums(single_problem.sums);
      // update if there is settings object in the newSudokuSettings
      if (newSudokuSettings.hasOwnProperty("settings")) {
        saveSudokuSettings(newSudokuSettings);
      }

      if (single_problem.image_url) setImageUrl(single_problem.image_url);
      setId(single_problem.id);
      setGridSize(t(single_problem.grid_size));
      setCurrentLevel(single_problem.level);
      setRetire(false);
      if (props.location.state && !props.location.state.timer)
        setStartTime(new Date());
      setBoardData(single_problem.problem);

      //Update the current level in history
      let id = single_problem.id;
      idLocal = id;
      difficultyLevelLocal = difficulty_level;
      props.history.replace("/sudoku_sum", { id, difficulty_level });
    } else {
      setBoardArray([]);
      setCurrentLevel(1);
      alert("Level not found");
    }
  };

  const generateSolution = (solution, grid_size) => {
    let solution_modified_text = solution.replace(/&quot;/g, '"');
    let solution_chunk = JSON.parse(solution_modified_text);
    setSolution(solution_chunk);
  };

  const generatePaths = (paths) => {
    let paths_modified_text = paths.replace(/&quot;/g, '"');
    let paths_chunk = JSON.parse(paths_modified_text);
    setPaths(paths_chunk);
  };

  const generateSums = (sums) => {
    let sums_modified_text = sums.replace(/&quot;/g, '"');
    let sums_chunk = JSON.parse(sums_modified_text);
    setSums(sums_chunk);
  };

  const isBoardEmpty = () => {
    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        if (boardArray[i][j].is_editable && boardArray[i][j].value !== "0") {
          return false;
        }
      }
    }
    return true;
  };

  const checkBoardBeforeChangeLevel = (
    level,
    difficulty_level = "introductory",
    problems = []
  ) => {
    if (!isBoardEmpty()) {
      var result = window.confirm("問題を解答中ですがよろしいですか？");
      if (!result) {
        return;
      }
    }
    resetTimer();
    onLoadLevel(level, difficulty_level, problems);
  };

  const loadNextProblem = () => {
    // currentLevel + 1
    if (currentLevel + 1 <= getMaxLevel(levels[difficultyLevel])) {
      checkBoardBeforeChangeLevel(
        parseInt(currentLevel) + 1,
        difficultyLevel,
        problems
      );
    }
  };

  const loadPreviousProblem = () => {
    if (currentLevel - 1 >= 1) {
      checkBoardBeforeChangeLevel(currentLevel - 1, difficultyLevel, problems);
    }
  };

  const getMaxLevel = (array) => {
    let max = -1;
    array.forEach((p) => {
      max = parseInt(p.level) > max ? p.level : max;
    });
    return "" + max;
  };

  const getMinLevel = (array) => {
    if (array.length === 0) return -1;
    let min = 1000;
    array.forEach((p) => {
      min = p.level < min ? p.level : min;
    });
    return min;
  };

  const isLastInput = () => {
    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        if (boardArray[i][j].value === "0") {
          return false;
        }
      }
    }
    return true;
  };

  //Saving the timer
  const saveTimer = () => {
    let pauseTime = new Date();
    let timer = startTime;
    let id = idLocal;
    let difficulty_level = difficultyLevelLocal;
    props.history.replace("/sudoku_sum", {
      timer,
      pauseTime,
      id,
      difficulty_level,
    });
  };
  const resetTimer = () => {
    let timer = new Date();
    props.history.replace("/sudoku_sum", { timer });
    setStartTime(timer);
  };

  const checkAnswer = () => {
    if (retire) return;
    let final_time = "";
    let current_time = getCurrentTime(startTime, new Date());
    final_time += current_time.hh === 0 ? final_time : current_time.hh + ":";
    final_time +=
      current_time.mm < 10
        ? "0" + current_time.mm + ":"
        : current_time.mm + ":";
    final_time +=
      current_time.ss < 10 ? "0" + current_time.ss : current_time.ss;
    // here validate the input
    if (isLastInput() && isCorrectSolution()) {
      // first check the sudokuSettings.completed_levels with the currentLevel and difficulty_level
      // if not find then save the level into the difficulty_level
      // save the current level into the completed levels array update localStorage
      let successMessages = ["正解です！", "Congratulations！", "お見事"];
      // generate a random index within 0 to 2.
      let messageIndex = getRandomInt(3); // expected 0 or 1 or 2
      // process the shareMessage here
      let share_message = `デイリーチャレンジ（入門編・${difficulty_japanese[difficultyLevel]}${currentLevel}）を${final_time}でクリアしました！`;
      setShareMessage(share_message);
      // デイリーチャレンジ（入門編・問題01）をMM:SSでクリアしました！
      setSuccessMessage(successMessages[messageIndex]);
      let found = sudokuSettings.completed_levels.find(
        (cl) =>
          cl.level === currentLevel && cl.difficulty_level === difficultyLevel
      );
      if (!found) {
        let sudoku_settings = { ...sudokuSettings };
        sudoku_settings.completed_levels.push({
          id: id,
          level: currentLevel,
          difficulty_level: difficultyLevel,
          rated: false,
        });
        saveSudokuSettings(sudoku_settings);
      }
      saveTimer();
      setSuccessDialog(true);
      setEndTime(final_time);
    } else {
      saveTimer();
      setLoseDialog(true);
      setEndTime(final_time);
    }
  };

  const retireGame = () => {
    if (window.confirm("本当にリタイアしますか？")) {
      // load the solution from the problems with the current level
      let current_problem = problems.find(
        (p) =>
          t(p.level) === t(currentLevel) &&
          p.difficulty_level === difficultyLevel
      );
      if (current_problem) {
        setOperationStack([]);
        setRetire(true);
        setBoardData(current_problem.solution, true);

        let newSudokuSettings = { ...sudokuSettings };
        newSudokuSettings.board = boardArray;
        newSudokuSettings.level = currentLevel;
        saveSudokuSettings(newSudokuSettings);
      }
    }
  };

  const continuousInputSelection = () => {
    if (continuousInput.is_continuous) {
      // remove the active selection
      setActiveInput("");
    }
    setContinuousInput({
      ...continuousInput,
      is_continuous: !continuousInput.is_continuous,
      label: continuousInput.is_continuous ? continuousInput.label : "",
    });
  };

  const erase = () => {
    if (focusInput.focused) {
      let newBoardArray = [...boardArray];
      let boardObject = newBoardArray[focusInput.row][focusInput.col];
      let operation_stack = [...operationStack];
      let num_counter = { ...numCounter };
      if (num_counter[boardObject.value] !== 0) {
        num_counter[boardObject.value] -= 1;
        setNumCounter(num_counter);
      }
      boardObject.value = "0";
      operation_stack.push({
        ...newBoardArray[focusInput.row][focusInput.col],
        row: focusInput.row,
        col: focusInput.col,
      });
      newBoardArray[focusInput.row][focusInput.col] = boardObject;
      // save the newBoardArray in the localStorage
      let newSudokuSettings = { ...sudokuSettings };
      newSudokuSettings.board = newBoardArray;
      saveSudokuSettings(newSudokuSettings);
      setBoardArray(newBoardArray);
      setOperationStack(operation_stack);
    }
  };

  const eraseAll = () => {
    if (window.confirm("本当に消去しますか？")) {
      onLoadLevel(currentLevel, difficultyLevel, problems);
    }
  };

  const addRating = (rating) => {
    setSuccessDialog(false);
    let newSudokuSettings = { ...sudokuSettings };
    // get the completed_levels from the sudokuSettings
    let completed_levels = [...sudokuSettings.completed_levels];
    // get the last element from the completed_levels
    let level_completed = sudokuSettings.completed_levels.find(
      (cl) =>
        cl.difficulty_level === difficultyLevel && cl.level === currentLevel
    );
    // get the id
    if (level_completed && rating && rating !== 0 && !level_completed.rated) {
      // call the server for add rating
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_AUTHORIZATION_TOKEN);

      let raw = JSON.stringify({
        board_id: level_completed.id,
        rating: rating,
      });

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
      };

      fetch(BASE_URL + "/api/board/rating", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          let index = completed_levels.findIndex(
            (cl) =>
              cl.difficulty_level === difficultyLevel &&
              cl.level === currentLevel
          );
          completed_levels[index].rated = true;
          newSudokuSettings.completed_levels = completed_levels;
          saveSudokuSettings(newSudokuSettings);
        })
        .catch((error) => console.log("error", error));
    }
  };

  const undo = () => {
    // check the operation stack first
    if (operationStack.length > 0) {
      let operation_stack = JSON.parse(JSON.stringify(operationStack));
      let copy_operation_stack = JSON.parse(JSON.stringify(operationStack));
      let board_array = [...boardArray];
      let lastOperation = operation_stack.pop();
      lastOperation.border = "1px solid black";
      // numCounter needs to update here
      let num_counter = { ...numCounter };
      if (
        num_counter[board_array[lastOperation.row][lastOperation.col].value] !==
        0
      ) {
        num_counter[
          board_array[lastOperation.row][lastOperation.col].value
        ] -= 1;
        setNumCounter(num_counter);
      }
      board_array[lastOperation.row][lastOperation.col] = lastOperation;
      lastOperation.row = undefined;
      lastOperation.col = undefined;
      setOperationStack(operation_stack);
      board_array = revalidateInput(
        copy_operation_stack[0].row,
        copy_operation_stack[0].col,
        board_array
      );
      setBoardArray(board_array);
      // save the newBoardArray in the localStorage
      let newSudokuSettings = { ...sudokuSettings };
      newSudokuSettings.board = board_array;
      saveSudokuSettings(newSudokuSettings);
    }
  };

  // LOW COUPLING (Reduce the dependency as much as possible)
  // responsibility only set the board data
  const setBoardData = (problem, retire = false) => {
    let problem_modified_text = problem.replace(/&quot;/g, '"');

    // first we need to split the array got from the server by using comma separator
    let board = JSON.parse(problem_modified_text); // here board is a 2d array

    // transform board into an array of objects {value, color, border}
    let boardObject = [];
    if (retire) {
      // checkout the boardArray
      boardArray.forEach((rows, row_index) => {
        rows.forEach((b, col_index) => {
          b.memo = [];
          if (!b.is_editable) {
            boardObject.push(b);
          } else {
            b.value = board[row_index][col_index].value;
            b.color = "red";
            boardObject.push(b);
          }
        });
      });
    } else {
      // is_selected will help to select the horizontal vertical and grid cell

      board.forEach((rows) => {
        rows.forEach((cell) => {
          num_counter[cell.value] = 0;
          boardObject.push({
            value: cell.value,
            color: "black",
            border: "1px solid black",
            is_editable: cell.value === "0",
            is_highlight: false,
            is_selected: false,
            left: cell.left,
            right: cell.right,
            top: cell.top,
            bottom: cell.bottom,
            memo: [],
          });
        });
      });
      setNumCounter(num_counter);
    }

    console.log(gridSize);
    // now create a chunk from the separated array
    let board_chunk = createChunk(boardObject, gridSize); // board_chunk is a two dimensional array

    // set the chunk to the boardArray state
    setBoardArray(board_chunk);
  };

  // ******************* Algorithm for checking given input is safe or not ***************
  const containsInRow = (row, col, number) => {
    for (let i = 0; i < gridSize; i++) {
      // don't need to check the same cell
      if (i !== col) {
        if (boardArray[row][i].value === number) {
          return true;
        }
      }
    }
    return false;
  };

  const containsInCol = (row, col, number) => {
    for (let i = 0; i < gridSize; i++) {
      // don't need to check the same cell
      if (i !== row) {
        if (boardArray[i][col].value === number) {
          return true;
        }
      }
    }
    return false;
  };

  const containsInBlock = (row, col, number) => {
    //By farhan
    const r = row - (row % 3);
    const c = col - (col % 3);
    for (let i = r; i < r + 3; i++) {
      for (let j = c; j < c + 3; j++) {
        if (row !== i && col !== j) {
          if (boardArray[i][j].value === number) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const isSafe = (row, col, number) => {
    return !(
      containsInRow(row, col, number) ||
      containsInCol(row, col, number) ||
      containsInBlock(row, col, number)
    );
  };

  const isCorrectSolution = () => {
    // count will keep track if there is inputted something in a cell
    let count = 0;
    // loop through the solution
    // check the board_array is editable and
    for (let i = 0; i < gridSize; i++) {
      for (let j = 0; j < gridSize; j++) {
        if (boardArray[i][j].is_editable && boardArray[i][j].value !== "0") {
          count++;
          // check the value with the solution array
          // if that value is wrong then return false
          if (boardArray[i][j].value !== solution[i][j].value) return false;
        }
      }
    }
    return count !== 0;
  };

  const refreshBoard = (board_array) => {
    // is_highlight = false, is_selected = false, border = '1px solid black'
    board_array.map((rows) => {
      rows.map((cell) => {
        if (cell.is_editable) {
          num_counter[cell.value] += 1;
        }
        cell.is_highlight = false;
        cell.is_selected = false;
        cell.border = "1px solid black";
        cell.color = "black";
      });
    });
    setBoardArray(board_array);
    setNumCounter(num_counter);
  };
  const loadData = () => {
    let id = -1;
    var isItReturnedFromSettings = false;
    // first check the localStorage. if there is data then load that data.
    let sudoku_settings = JSON.parse(localStorage.getItem("sudoku_sum"));
    setSudokuSettings(sudoku_settings);

    if (props.location.state && props.location.state.timer) {
      //Checking timer saving
      setStartTime(getStartTimeWhenBack());
      isItReturnedFromSettings = true;
    }

    if (props.location.state && props.location.state.id) {
      id = props.location.state.id;
      setDifficultyLevel(props.location.state.difficulty_level);
      //Original
      //setCurrentLevel(id)
    } else {
      setDifficultyLevel(sudoku_settings.difficulty_level);
    }

    // otherwise fetch data from server
    // we will load the game array from server here.
    // initially it will load first level board
    fetch(BASE_URL + "/api/sum_boards")
      .then((res) => res.json())
      .then((resData) => {
        arrangeProblemStages(resData.data);
        // here data will be set asynchronously. so we will not get the data immediately

        let problem = resData.data.find((p) => t(p.id) === t(id));

        if (
          problem !== undefined &&
          id !== -1 &&
          problem.level != sudoku_settings.level
        ) {
          onLoadLevel(problem.level, problem.difficulty_level, resData.data);
        } else if (
          sudoku_settings.board.length !== 0 &&
          (sudoku_settings.settings.auto_save || isItReturnedFromSettings)
        ) {
          clearBoardInSettings();

          let single_problem = resData.data.find(
            (p) =>
              t(p.level) === t(sudoku_settings.level) &&
              p.difficulty_level === sudoku_settings.difficulty_level
          );
          setProblems(resData.data);
          generateSums(single_problem.sums);
          refreshBoard(sudoku_settings.board);
          generateSolution(single_problem.solution, single_problem.grid_size);
          generatePaths(single_problem.paths);
          setId(single_problem.id);
          if (single_problem.image_url) setImageUrl(single_problem.image_url);

          setCurrentLevel(sudoku_settings.level);
          setGridSize(sudoku_settings.board.length);

          setDifficultyLevel(sudoku_settings.difficulty_level);
        } else {
          onLoadLevel(
            sudoku_settings.level,
            sudoku_settings.difficulty_level,
            resData.data
          );
        }
      })
      .catch(console.log);
  };

  // similar to class based lifecycle hook method componentDidMount
  // componentDidUpdate componentWillUnmount componentWillUnmount
  useEffect(() => {
    saveSettings();
    let navigation = performance.getEntriesByType("navigation")[0];
    if (navigation && navigation.type === "reload") {
      setTimerToNullWhenAutoSaveIsOff();
    }
    loadData();
  }, []);

  useEffect(() => {
    if (successDialog || loseDialog) saveTimer();
    else {
      setTimerToNullWhenAutoSaveIsOff();
      setStartTime(getStartTimeWhenBack);
    }
  }, [successDialog, loseDialog]);

  const setTimerToNullWhenAutoSaveIsOff = () => {
    let sudoku_settings = JSON.parse(localStorage.getItem("sudoku_sum"));
    if (!sudoku_settings.settings.auto_save) {
      //Means it is reloaded
      let timer = null;
      props.history.replace("/sudoku_sum", { timer });
    }
  };

  const clearBoardInSettings = () => {
    if (!isBoardEmpty) return;
    let sudoku_settings = JSON.parse(localStorage.getItem("sudoku_sum"));
    if (!sudoku_settings.settings.auto_save) {
      let sudoku_settings = JSON.parse(localStorage.getItem("sudoku_sum"));
      sudoku_settings.board = [];
      localStorage.setItem("sudoku_sum", JSON.stringify(sudoku_settings));
    }
  };

  const getStartTimeWhenBack = () => {
    if (props.location.state && props.location.state.timer) {
      var timeDifference = moment(props.location.state.pauseTime).diff(
        moment(props.location.state.timer)
      );
      return moment().subtract(timeDifference).toDate();
    }
    return new Date();
  };

  const redirectTo = (event, path) => {
    resetTimer();
    event.preventDefault(); // this will prevent it's default behaviour. which is redirecting to another page
    props.history.push(path);
  };

  return (
    <div className="board_page_wrapper">
      <Link to="/setting_sum" className="nav_box_setting" onClick={saveTimer}>
        <FaCog />
      </Link>
      <div className="wrapper">
        <SudokuSuccessModal
          successDialog={successDialog}
          onNext={loadNextProblem}
          setSuccessDialog={setSuccessDialog}
          successMessage={successMessage}
          shareMessage={shareMessage}
          imageUrl={imageUrl}
          addRating={addRating}
          endTime={endTime}
        />

        <SudokuLoseModal
          loseDialog={loseDialog}
          onContinue={setLoseDialog}
          onStartAgain={eraseAll}
          setLoseDialog={setLoseDialog}
          endTime={endTime}
        />

        <div className="board_wrapper">
          <div className="board_title">問題 {currentLevel}</div>
          {/* Sudoku table start */}
          <table className="sum_table">
            <tbody>
              {
                // boards is a two dimensional boards
                // boards = [[1,2,3,4,5,6,7,8,9],[1,2,3,4,5,6,7,8,9]]
                boardArray.map((rows, row) => {
                  return (
                    <tr key={row}>
                      {rows.map((value, col) => {
                        return (
                          <td
                            key={col}
                            className={`${
                              value.is_highlight ? "highlight_cell" : ""
                            } 
                                                                ${
                                                                  value.is_selected
                                                                    ? "selected_cell"
                                                                    : ""
                                                                }
                                                                `}
                            style={{ border: value.border }}
                            onClick={() => onFocusInput(row, col)}
                          >
                            {/*If value is zero it will send empty string otherwise set that value*/}
                            <BoardInput
                              row={row}
                              col={col}
                              sum={sums[row][col]}
                              dataObject={value}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
        {/* Sudoku table End */}

        <div className="number_selector_wrapper">
          {/* Number button table start */}
          <table>
            <tbody>
              <tr>
                {boardArray.map((b, index) => {
                  return (
                    <td
                      key={index}
                      className={`numbutton  
                                        ${
                                          numCounter[`${index + 1}`] >= 9 &&
                                          sudokuSettings.settings
                                            .gray_nine_times
                                            ? "selected_input"
                                            : ""
                                        } 
                                        ${
                                          activeInput === `${index + 1}`
                                            ? "active_input"
                                            : ""
                                        }`}
                      onClick={() => onInputNumber(`${index + 1}`)}
                    >
                      {" "}
                      {index + 1}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
        {/* Number button table end */}

        {/* Big Buttons start */}
        <div className="stacked_buttons_wrapper">
          <div className="btn_grp_left">
            <table>
              <tbody>
                <tr className="top_row">
                  {/*Erase all erase all given input*/}
                  <td onClick={() => eraseAll()}>全消去</td>
                </tr>
                <tr>
                  {/*Retire*/}
                  <td onClick={() => retireGame()}>リタイア</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="btn_grp_right_1">
            <table>
              <tbody>
                <tr>
                  {/*Memoicon*/}
                  <td
                    className={memo.is_memo ? "btn_memo_input_active" : ""}
                    onClick={() =>
                      setMemo({
                        ...memo,
                        is_memo: !memo.is_memo,
                        label: memo.is_memo ? memo.label : "",
                      })
                    }
                  >
                    メモｱｲｺﾝ
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="btn_grp_right_2">
            <table>
              <tbody>
                <tr>
                  {/*Continuous input - Renzoku nyūryoku*/}
                  <td
                    className={
                      continuousInput.is_continuous
                        ? "btn_continuous_input_active"
                        : ""
                    }
                    onClick={() => continuousInputSelection()}
                  >
                    連続入力
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="btn_grp_right_3">
            <table>
              <tbody>
                <tr className="top_row">
                  <td onClick={() => undo()}>
                    <FaUndoAlt />
                  </td>
                </tr>
                <tr className="small_font_row">
                  {/*Erase erase the recently given input*/}
                  <td onClick={() => erase()}>ひとマス消す</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="answer_button_div">
          {/*check the answer - Kotae-awase*/}
          <button onClick={() => checkAnswer()}>答えあわせ</button>
        </div>
        <div className="div_below_answer_btn">
          {/*Previous Problem - Mae no mondai*/}
          <button className="bigbtn3" onClick={() => loadPreviousProblem()}>
            前の問題へ
          </button>
          {/*To the next problem - Tsugi no mondai e*/}
          <button className="bigbtn4" onClick={() => loadNextProblem()}>
            次の問題へ
          </button>
        </div>
      </div>
      <div className="wrapper_page_end_buttons">
        {/* 変わった問題 */}
        <div className="last_div">
          <h4>変わった問題</h4>
          <button
            className="top_button bigbtn6"
            onClick={() => props.history.push("sudoku_unusual")}
          >
            {" "}
            超入門 (4×4)
          </button>
          <br />
          <button
            className="bigbtn6"
            onClick={() => props.history.push("sudoku_unusual")}
          >
            {" "}
            ジグソーナンプレ
          </button>
          <button
            className="bigbtn6"
            onClick={() => props.history.push("sudoku_unusual")}
          >
            {" "}
            対角線ナンプレ
          </button>
          <button
            className="bigbtn6"
            onClick={() => props.history.push("sudoku_unusual")}
          >
            {" "}
            足し算ナンプレ
          </button>
        </div>
      </div>
      {/* white box */}
      <h4
        onClick={(e) => redirectTo(e, "/how_to_play_titles")}
        className="letterh4"
      >
        ナンプレの遊び方・コツ
      </h4>{" "}
      <br />
      <div className="white_box">
        <Link to="">広告</Link>
      </div>
      <span
        id="last_span"
        onClick={(event) => redirectTo(event, "/illustrology_main")}
        className="letterh6"
      >
        イラストロジックで遊ぶ
      </span>
      <div id="board_footer">
        <a href="https://ma-ko-to.com/">管理会社</a>
      </div>
    </div>
  );
}

export default GridSumBoard;
