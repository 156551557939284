import React from "react";
import './IllustrationLogicInput.css';

const dict = {
    gray: 'gray',
    dot: 'dot',
    cross: 'cross'
};

const dictVal = {
    gray: '',
    dot: '•',
    cross: '╳',
};
const max = (a, b) => a > b ? a : b;
const min = (a, b) => a < b ? a : b;
const IllustrationLogicInput = (props) => {
    const {dataObject, onClickProblemCell, row, col, gridSize, extraCells, fontSize} = props;
    const { value, color, completed, valid } = dataObject;

    let color_value = '';
    if(color === 'green'){
        color_value = 'safe_cell';
    } else if(color === 'red') {
        color_value = 'unsafe_cell';
    } else {
        color_value = 'default_cell';
    }

    let cell_div;

    if (value !== '0' && !(value in dict) && (col <= extraCells|| row <= extraCells)) {
        cell_div = <div
            className={`problem_cell ${completed ? 'completed_input ' : ''} ${!valid ? 'invalid_cell' : ''}`}
            style={{fontSize: min(fontSize, 8)}}
            onClick={() => onClickProblemCell(row, col)}>
            {value}
        </div>
    } else if (value === '0' && (col < extraCells || row < extraCells)) {
        cell_div = <div className={`blank_problem_cell`}/>
    } else if (value === '0' || value === 0) {
        cell_div = <div className='blank_cell'/>
    } else {
        cell_div = <div className={dict[value]} style={{fontSize: min(fontSize, 8)}}>{dictVal[value]}</div>
    }

    return (
        <div className={`td_data ${color_value} ${(col>=extraCells && row>=extraCells)? 'black_border':''}`}>
            {cell_div}
        </div>
    )
};

export default IllustrationLogicInput;
