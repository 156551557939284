import React from 'react'
import './createAProblemTopView.css'
import { Link } from 'react-router-dom';

function CreateAProblemTopView(props) {

    const redirectToPage = (event, link) => {
        event.preventDefault();
        props.history.push(link);
    };

    return (
        <div>
            <div className="header">イラストロジック</div>
            <div className="illustrologyMainNavbar">

                <Link to="/illustrology_main">問題を解</Link>
                <Link to="/createProblemTop" className="active-navbutton">問題を作る</Link>

            </div>
            <div className="content">
                問題を作る前に
                <div className="innerContent">
                    必ずオリジナルの問題をご作成ください。<br/>
                    盗作が発覚した場合、<br/>
                    掲載後であっても予告なく問題を削除します。<br/><br/>
                    作成いただいた問題は運営会社で確認のうえ、<br/>
                    掲載可否を判断します。<br/><br/>
                    掲載できない問題の例<br/>
                    ・盗作である<br/>
                    ・複数回答が可能である<br/>
                    ・掲載済みの問題と完全に一致している<br/>
                    ・掲載に不適切な図案である<br/>
                </div>
                <div className="button-div">
                    <button type='button' onClick={event=>{redirectToPage(event, '/create')}}>問題作成に進む</button>
                </div>
                <div className="advertisement-div">広告</div>
            </div>
            <a href="/" className="link" onClick={event=>{redirectToPage(event, '/')}}>ナンプレで遊ぶ</a>
            <div className="footer-illustrology-main">
                <a href="/">プライバシーポリシー・免責事項</a>
                <a href="https://ma-ko-to.com/" id="footer-last-link">管理会社</a>
            </div>
        
        </div>
    )
}

export default CreateAProblemTopView;
