import React, { useState, useEffect, Fragment } from 'react';
import './grid_problem_book.css';
import { BASE_URL } from '../../config';
import { FaCheck } from "react-icons/fa";
import { saveSettings } from "../../utils/functions";


function GridProblemBook(props) {

    const options = { 0: false, 1: false, 2: false, 3: false };
    const [dropDownOption, setDropDownOption] = useState(options);
    const [arrowIcons, setArrowIcons] = useState(options)
    const [sudokuFourCompletedLevels, setSudokuFourCompletedLevels] = useState([]);
    const [sudokuDiagonalCompletedLevels, setSudokuDiagonalCompletedLevels] = useState([]);
    const [sudokuJigsawCompletedLevels, setSudokuJigsawCompletedLevels] = useState([]);
    const [sudokuSumCompletedLevels, setSudokuSumCompletedLevels] = useState([]);
    const [fourBoard, setFourBoard] = useState([]);
    const [diagonalBoard, setDiagonalBoard] = useState([]);
    const [jigsawBoard, setJigsawBoard] = useState([]);
    const [sumBoard, setSumBoard] = useState([]);

    const fetchFourBoard = () => {
        fetch(BASE_URL + '/api/four_boards')
            .then(res => res.json())
            .then(json => {
                let new_board = json.data
                setFourBoard(new_board);
            })
            .catch(error => console.log(error));
    }
    const fetchDiagonalBoard = () => {
        fetch(BASE_URL + '/api/diagonal_boards')
            .then(res => res.json())
            .then(json => {
                let new_board = json.data
                setDiagonalBoard(new_board);
            })
            .catch(error => console.log(error));
    }

    const fetchJigsawBoard = () => {
        fetch(BASE_URL + '/api/jigsaw_boards')
            .then(res => res.json())
            .then(json => {
                let new_board = json.data
                setJigsawBoard(new_board);
            })
            .catch(error => console.log(error));
    }
    const fetchSumBoard = () => {
        fetch(BASE_URL + '/api/sum_boards')
            .then(res => res.json())
            .then(json => {
                let new_board = json.data
                setSumBoard(new_board);
            })
            .catch(error => console.log(error));
    }
    const fetchData = () => {
        // get the sudoku four board
        fetchFourBoard();
        // get the sudoku diagonal board
        fetchDiagonalBoard();
        // get the sudoku jigsaw board
        fetchJigsawBoard();
        // get the sudoku sum board
        fetchSumBoard();
    }

    useEffect(() => {
        saveSettings();

        let four_completed_levels = JSON.parse(localStorage.getItem('sudoku_four')).completed_levels;
        let diagonal_completed_levels = JSON.parse(localStorage.getItem('sudoku_diagonal')).completed_levels;
        let jigsaw_completed_levels = JSON.parse(localStorage.getItem('sudoku_jigsaw')).completed_levels;
        let sum_completed_levels = JSON.parse(localStorage.getItem('sudoku_sum')).completed_levels;

        setSudokuFourCompletedLevels(four_completed_levels);
        setSudokuDiagonalCompletedLevels(diagonal_completed_levels);
        setSudokuJigsawCompletedLevels(jigsaw_completed_levels);
        setSudokuSumCompletedLevels(sum_completed_levels);

        fetchData();
    }, []);

    const startGame = (id,difficulty_level, path) => {
        props.history.push(path, { id, difficulty_level });
    };

    const toggleDropDown = (value) => {
        const newOption = { ...dropDownOption };
        const newArrowIcons = { ...arrowIcons };
        newOption[value] = !newOption[value];
        newArrowIcons[value] = !newArrowIcons[value];
        setDropDownOption(newOption);
        setArrowIcons(newArrowIcons);
    };

    return (
        <div>
            <div className="header">変わった問題</div>
            <div className="select-by-difficulty">
                {/* 4×4 problem */}
                <div className="blue-title" onClick={() => toggleDropDown(0)}>{arrowIcons[0] ? '△' : '▽'} 超入門 (4×4)</div>
                {
                    dropDownOption[0] && (
                        <Fragment>
                            <div id="content">
                                <table>
                                    <tbody>
                                        {
                                            fourBoard.map((row, index) => {
                                                let level_completed = sudokuFourCompletedLevels.find(cl => cl.difficulty_level === row.difficulty_level && cl.level === row.level);
                                                return (
                                                    <tr key={row.id}>
                                                        <td>{level_completed ? (<FaCheck />) : ''}</td>
                                                        <td>問題</td>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <button className='start_button'
                                                                onClick={() => startGame(row.id, row.difficulty_level, 'sudoku_four')}>START
                                                </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })

                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Fragment>
                    )
                }

            </div>
            <div className="select-by-grid">
                {/*Diagonal*/}
                <div className="blue-title" onClick={() => toggleDropDown(1)}>{arrowIcons[1] ? '△' : '▽'} 対角線ナンプレ</div>
                {
                    dropDownOption[1] && (
                        <Fragment>
                            <div id="content">
                                <table>
                                    <tbody>
                                        {
                                            diagonalBoard.map((row, index) => {
                                                let level_completed = sudokuDiagonalCompletedLevels.find(cl => cl.difficulty_level === row.difficulty_level && cl.level === row.level);
                                                return (
                                                    <tr key={row.id}>
                                                        <td>{level_completed ? (<FaCheck />) : ''}</td>
                                                        <td>問題</td>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <button className='start_button'
                                                                onClick={() => startGame(row.id, row.difficulty_level, 'sudoku_diagonal')}>START
                                                </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })

                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Fragment>
                    )
                }

            </div>
            <div className="new-questions-div">

                <div className="blue-title" onClick={() => toggleDropDown(2)}>{arrowIcons[2] ? '△' : '▽'} ジグソーナンプレ</div>
                {
                    dropDownOption[2] && (
                        <Fragment>
                            <div id="content">
                                <table>
                                    <tbody>
                                        {
                                            jigsawBoard.map((row, index) => {
                                                let level_completed = sudokuJigsawCompletedLevels.find(cl => cl.difficulty_level === row.difficulty_level && cl.level === row.level);
                                                return (
                                                    <tr key={row.id}>
                                                        <td>{level_completed ? (<FaCheck />) : ''}</td>
                                                        <td>問題</td>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <button className='start_button'
                                                                onClick={() => startGame(row.id, row.difficulty_level, 'sudoku_jigsaw')}>START
                                                </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })

                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Fragment>
                    )
                }
            </div>
            <div className="new-questions-div">

                <div className="blue-title" onClick={() => toggleDropDown(3)}>{arrowIcons[3] ? '△' : '▽'} 足し算ナンプレ</div>
                {
                    dropDownOption[3] && (
                        <Fragment>
                            <div id="content">
                                <table>
                                    <tbody>
                                        {
                                            sumBoard.map((row, index) => {
                                                let level_completed = sudokuSumCompletedLevels.find(cl => cl.difficulty_level === row.difficulty_level && cl.level === row.level);
                                                return (
                                                    <tr key={row.id}>
                                                        <td>{level_completed ? (<FaCheck />) : ''}</td>
                                                        <td>問題</td>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <button className='start_button'
                                                                onClick={() => startGame(row.id, row.difficulty_level, 'sudoku_sum')}>START
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })

                                        }
                                    </tbody>
                                </table>
                            </div>

                        </Fragment>
                    )
                }
            </div>
            <div className="footer" onClick={() => { props.history.push('/sudoku') }}>
                ナンプレTOPへ戻る
            </div>
        </div>
    );

}

export default GridProblemBook;
