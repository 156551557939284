import React, { useEffect, useState } from "react";
import moment from "moment";
import "./illustrologyBoard.css";
import { FaUndoAlt, FaCog } from "react-icons/fa";
import {
  createChunk,
  getCurrentTime,
  saveSettings,
  compareValues,
} from "../../utils/functions";
import IllustrationLogicInput from "../../components/IllustrationLogicInput";
import SuccessModal from "../../components/NumpureSuccessModal";
import LoseModal from "../../components/NumpureLoseModal";
import { BASE_URL } from "../../config";
import { Link } from "react-router-dom";
import { ConsoleHelper } from "../../helper/consoleHelper";

// Game Logic
// Add extra five rows and columns for inputting problem data

function IllustroloyBoard(props) {
  const difficulty_japanese = {
    introductory: "入門",
    beginner: "初級",
    intermediate: "中級",
    advanced: "上級",
    expert: "難問",
  };

  const dict = {
    1: "gray",
    2: "cross",
    3: "dot",
    4: "0",
  };

  const extraCells = {
    10: 5, // grid size 10 will have 5 extra problem cells
    15: 8, // grid size 15 will have 8 extra problem cells
    20: 10, // grid size 20 will have 10 extra problem cells
    25: 13, // grid size 25 will have 13 extra problem cells
    30: 15, // grid size 30 will have 15 extra problem cells
  };

  const problemStages = {
    introductory: [],
    beginner: [],
    intermediate: [],
    advanced: [],
    expert: [],
  };

  const [solution, setSolution] = React.useState([]);

  //keep track which gameInputButtonIsSelected
  //1 : gray , 2 : cross , 3 : dot , 4:blank
  const [selectedGameInputButton, setSelectedGameInputButton] = useState(1);

  // solutionTable this will render in the correct effect screen
  const [solutionTable, setSolutionTable] = React.useState([]);

  const [levels, setLevels] = React.useState(problemStages);
  // when a level is complete show the success dialog
  const [successDialog, setSuccessDialog] = React.useState(false);
  // when a user lose the game then show the lose dialog
  const [loseDialog, setLoseDialog] = React.useState(false);
  // game settings {settings:{}, board:[], level:1, difficulty_level:'introductory'}
  const [numPlaySettings, setNumPlaySettings] = useState({});
  // operation stack is used undo operation
  const [operationStack, setOperationStack] = React.useState([]);
  // this is the end time of the game
  const [endTime, setEndTime] = React.useState("");
  // this will check if the continuous input is on or not
  const [continuousInput, setContinuousInput] = React.useState({
    is_continuous: false,
    label: "",
  });
  // this is the difficultyLevel of the current game
  const [difficultyLevel, setDifficultyLevel] = React.useState("introductory");
  // this will set the current game level
  const [currentLevel, setCurrentLevel] = React.useState(1);
  // game start time
  const [startTime, setStartTime] = React.useState(() =>
    props.location.state && props.location.state.startTime
      ? moment()
          .subtract(
            moment(props.location.state.pauseTime).diff(
              moment(props.location.state.startTime)
            )
          )
          .toDate()
      : new Date()
  );
  // this keeps track focus input cell
  const [focusInput, setFocusInput] = useState({ row: 5, col: 5, times: 0 });
  // this is the game table array. this is a 2d array
  const [tableData, setTableData] = useState([]);
  // after fetching all the problems will set into the problems array
  const [problems, setProblems] = React.useState([]);
  // this will keep track for retire from the game
  const [retire, setRetire] = React.useState(false);
  // facebook, twitter, line share message
  const [shareMessage, setShareMessage] = React.useState("");
  // title
  const [title, setTitle] = React.useState("");
  // success modal image url
  const [imageUrl, setImageUrl] = React.useState("");
  // id of the current board array
  const [id, setId] = React.useState("");
  // Answer title
  const [answer, setAnswer] = React.useState("");
  // drag
  const [isDraggable, setIsDraggable] = React.useState(false);
  // draggable element
  const [selectedCell, setSelectedCell] = React.useState(null);

  const [gridSize, setGridSize] = React.useState(10);

  const [divider, setDivider] = useState({ divideby: 1, skip: 0 });

  const parser = (data, separator) => {
    return data.reduce((acc, current) => {
      acc.push(current.split(separator));
      return acc;
    }, []);
  };

  const onFocusInput = (event, row, col, type) => {
    event.preventDefault(); // preventing the default behaviour
    if (type == "onContextMenu") return;

    if (row < extraCells[gridSize] || col < extraCells[gridSize]) return;
    let new_focused_input = { ...focusInput };
    let new_table_data = [...tableData];

    // if click first time mark the cell as gray cell
    // if click second time mark the cell as cross cell
    // if click third time mark the cell as dot cell
    // if click fourth time mark the cell as blank cell

    if (row === focusInput.row && col === focusInput.col) {
      // increase the focusInput.times
      let times = focusInput.times + 1;
      new_focused_input.times = times > 4 ? 1 : times;
      new_focused_input.row = row;
      new_focused_input.col = col;
    } else {
      // this means clicked for the first time
      var focusInputTimes = selectedGameInputButton;
      if (selectedGameInputButton != 0)
        focusInputTimes = selectedGameInputButton;

      new_focused_input.row = row;
      new_focused_input.col = col;
      new_focused_input.times = focusInputTimes;
    }

    // this is the mouse right and left click event
    if (event.button === 2 || event.button == 0) {
      if (event.button == 2) {
        if (tableData[row][col].value == "cross") new_focused_input.times = 4;
        else new_focused_input.times = 2;
      }
      setIsDraggable(true);
    }
    new_table_data[focusInput.row][focusInput.col].is_focused = false;
    new_table_data[new_focused_input.row][
      new_focused_input.col
    ].is_focused = true;
    setFocusInput(new_focused_input);
    setSelectedCell(
      new_table_data[new_focused_input.row][new_focused_input.col]
    );
    validateInput(row, col, dict[new_focused_input.times], new_table_data);
  };

  const onInput = (value) => {
    //Find which button is selected . So that border color can be changer
    var selectedFocusInput = 1;
    for (var key in dict) {
      if (dict[key] == value) {
        selectedFocusInput = key;
        break;
      }
    }

    // value can be either 0 or dot or gray or cross
    // check the currently focusedInput cell
    const { row, col } = focusInput;
    if (continuousInput.is_continuous) {
      // set the number as current input
      setContinuousInput({ is_continuous: true, label: value });
    }

    setSelectedGameInputButton(selectedFocusInput);
    setGameInputInSelectedCell(selectedFocusInput);
  };

  const validateInput = (row, col, value, table) => {
    let operation_stack = [...operationStack];
    operation_stack.push({ ...table[row][col], row, col });
    setOperationStack(operation_stack);

    // dot cross and 0 don't need to validate
    if (!numPlaySettings.settings.midway_check) {
      table[row][col].value = value;
      let newNumPlaySettings = { ...numPlaySettings };
      newNumPlaySettings.board = table;
      saveNumPlaySettings(newNumPlaySettings);
      setTableData(table);
    } else {
      table[row][col].value = value;
      // validate that row col and grid
      table = isSafe(row, col, table, solution, gridSize);
      updateIsSafeResult(table);
    }
  };

  const t = (obj) => {
    return parseInt(obj);
  };

  const isSafe = (row, col, table, solution, grid_size) => {
    grid_size = parseInt(grid_size);
    // initially mark all the problem rows to incomplete
    // ROW PROBLEMS
    for (let j = extraCells[grid_size] - 1; j >= 0; j--) {
      if (table[row][j].value !== "0") table[row][j].completed = false;
      table[row][j].valid = true;
    }
    // initially mark all the problem columns to incomplete
    // COLUMN PROBLEMS
    for (let i = extraCells[grid_size] - 1; i >= 0; i--) {
      if (table[i][col].value !== "0") table[i][col].completed = false;
      table[i][col].valid = true;
    }

    function get_row_problem_set() {
      let holder = [];
      for (let i = 0; i < extraCells[grid_size]; i++) {
        if (parseInt(table[row][i].value))
          holder.push({
            value: parseInt(table[row][i].value),
            row: row,
            col: i,
            completed: false,
          });
      }
      return holder;
    }

    function get_col_problem_set() {
      let holder = [];
      for (let i = 0; i < extraCells[grid_size]; i++) {
        if (parseInt(table[i][col].value))
          holder.push({
            value: parseInt(table[i][col].value),
            row: i,
            col: col,
            completed: false,
          });
      }
      return holder;
    }

    function get_row_solution_set() {
      let holder = [];
      let row_partial_solution_start = -1;
      let row_partial_solution_end = -1;
      let row_partial_solution_length = 0;
      let row_withextra = row - extraCells[grid_size];
      for (let j = 0; j < grid_size; j++) {
        if (solution[row_withextra][j] == "1" && j < solution.length - 1) {
          row_partial_solution_length++;
          row_partial_solution_start =
            row_partial_solution_start == -1
              ? j + extraCells[grid_size]
              : row_partial_solution_start;
          row_partial_solution_end = j + extraCells[grid_size];
        } else if (
          solution[row_withextra][j] == "0" &&
          row_partial_solution_length > 0
        ) {
          holder.push({
            length: row_partial_solution_length,
            start: row_partial_solution_start,
            end: row_partial_solution_end,
          });
          row_partial_solution_length = 0;
          row_partial_solution_start = -1;
          row_partial_solution_end = -1;
        } else if (
          solution[row_withextra][j] == "1" &&
          j == solution.length - 1
        ) {
          row_partial_solution_length++;
          row_partial_solution_start =
            row_partial_solution_start == -1
              ? j + extraCells[grid_size]
              : row_partial_solution_start;
          row_partial_solution_end = j + extraCells[grid_size];
          holder.push({
            length: row_partial_solution_length,
            start: row_partial_solution_start,
            end: row_partial_solution_end,
          });
          row_partial_solution_length = 0;
          row_partial_solution_start = -1;
          row_partial_solution_end = -1;
        }
      }
      return holder;
    }

    function get_col_solution_set() {
      let holder = [];
      let col_partial_solution_start = -1;
      let col_partial_solution_end = -1;
      let col_partial_solution_length = 0;
      for (let j = 0; j < grid_size; j++) {
        if (
          solution[j][col - extraCells[grid_size]] == "1" &&
          j < solution.length - 1
        ) {
          col_partial_solution_length++;
          col_partial_solution_start =
            col_partial_solution_start == -1
              ? j + extraCells[grid_size]
              : col_partial_solution_start;
          col_partial_solution_end = j + extraCells[grid_size];
        } else if (
          solution[j][col - extraCells[grid_size]] == "0" &&
          col_partial_solution_length > 0
        ) {
          holder.push({
            length: col_partial_solution_length,
            start: col_partial_solution_start,
            end: col_partial_solution_end,
          });
          col_partial_solution_length = 0;
          col_partial_solution_start = -1;
          col_partial_solution_end = -1;
        } else if (
          solution[j][col - extraCells[grid_size]] == "1" &&
          j == solution.length - 1
        ) {
          col_partial_solution_length++;
          col_partial_solution_start =
            col_partial_solution_start == -1
              ? j + extraCells[grid_size]
              : col_partial_solution_start;
          col_partial_solution_end = j + extraCells[grid_size];
          holder.push({
            length: col_partial_solution_length,
            start: col_partial_solution_start,
            end: col_partial_solution_end,
          });
          col_partial_solution_length = 0;
          col_partial_solution_start = -1;
          col_partial_solution_end = -1;
        }
      }
      return holder;
    }

    function get_row_trackers() {
      let holder = [];
      let row_count = 0;
      let row_start_index = -1;
      let row_end_index = -1;
      for (
        let i = parseInt(extraCells[grid_size]);
        i < parseInt(grid_size) + parseInt(extraCells[grid_size]);
        i++
      ) {
        let value =
          table[row][i].value === "cross" ||
          table[row][i].value === "dot" ||
          table[row][i].value == "0"
            ? 0
            : 1;
        if (value == 1 && i < grid_size + extraCells[grid_size] - 1) {
          row_count++;
          row_start_index = row_start_index == -1 ? i : row_start_index;
          row_end_index = i;
        } else if (value == 0 && row_count > 0) {
          holder.push({
            count: row_count,
            start_index: row_start_index,
            end_index: row_end_index,
          });
          row_count = 0;
          row_start_index = -1;
        } else if (value == 1 && i == grid_size + extraCells[grid_size] - 1) {
          row_count++;
          row_start_index = row_start_index == -1 ? i : row_start_index;
          holder.push({
            count: row_count,
            start_index: row_start_index,
            end_index: i,
          });
          row_count = 0;
          row_start_index = -1;
        }
      }
      return holder;
    }

    function get_col_trackers() {
      let holder = [];
      let col_count = 0;
      let col_start_index = -1;
      let col_end_index = -1;
      for (
        let i = extraCells[grid_size];
        i < grid_size + extraCells[grid_size];
        i++
      ) {
        let value =
          table[i][col].value === "cross" ||
          table[i][col].value === "dot" ||
          table[i][col].value == "0"
            ? 0
            : 1;
        if (value == 1 && i < grid_size + extraCells[grid_size] - 1) {
          col_count++;
          col_start_index = col_start_index == -1 ? i : col_start_index;
          col_end_index = i;
        } else if (value == 0 && col_count > 0) {
          holder.push({
            count: col_count,
            start_index: col_start_index,
            end_index: col_end_index,
          });
          col_count = 0;
          col_start_index = -1;
        } else if (value == 1 && i == grid_size + extraCells[grid_size] - 1) {
          col_count++;
          col_start_index = col_start_index == -1 ? i : col_start_index;
          col_end_index = i;
          holder.push({
            count: col_count,
            start_index: col_start_index,
            end_index: i,
          });
          col_count = 0;
          col_start_index = -1;
        }
      }
      return holder;
    }

    let row_problem_set = get_row_problem_set();
    let col_problem_set = get_col_problem_set();
    let row_solution_set = get_row_solution_set();
    let col_solution_set = get_col_solution_set();
    let row_trackers = get_row_trackers();
    let col_trackers = get_col_trackers();

    // match the solution and answer and mark the problem cells
    col_solution_set.map((s, index) => {
      let obj = col_problem_set[index];
      col_trackers.map((t, j) => {
        if (s.start == t.start_index && s.end == t.end_index) {
          table[obj.row][obj.col].valid = true;
          table[obj.row][obj.col].completed = true;
        } else if (
          s.start >= t.start_index &&
          s.end <= t.end_index &&
          s.length < t.count
        ) {
          table[obj.row][obj.col].valid = false;
          table[obj.row][obj.col].completed = false;
        }
      });
    });

    // match the solution and answer and mark the problem cells
    row_solution_set.map((s, i) => {
      let obj = row_problem_set[i];
      row_trackers.map((t, j) => {
        if (s.start == t.start_index && s.end == t.end_index) {
          table[obj.row][obj.col].valid = true;
          table[obj.row][obj.col].completed = true;
        } else if (
          s.start >= t.start_index &&
          s.end <= t.end_index &&
          s.length < t.count
        ) {
          table[obj.row][obj.col].valid = false;
          table[obj.row][obj.col].completed = false;
        }
      });
    });

    return table;
  };

  const updateIsSafeResult = (table) => {
    let newNumPlaySettings = { ...numPlaySettings };
    newNumPlaySettings.board = table;
    saveNumPlaySettings(newNumPlaySettings);
    setTableData(table);
  };

  const isNextColZero = (row, col, table) => {
    // check the selected row next column value
    if (col + 1 > table.length - 1) return true;

    let v = table[row][col + 1].value;
    let value = v === "cross" || v === "dot" || v === "0" ? "0" : "1";

    return value === "0";
  };

  const isNextRowZero = (row, col, table) => {
    // check the selected column next row value
    if (row + 1 > table.length - 1) return true;

    let v = table[row + 1][col].value;
    let value = v === "cross" || v === "dot" || v === "0" ? "0" : "1";

    return value === "0";
  };

  const isNextRowSolutionZero = (row, col, table) => {
    if (row + 1 > table.length - 1) return true;

    let v = table[row + 1][col];
    let value = v === "cross" || v === "dot" || v === "0" ? "0" : "1";

    return value === "0";
  };

  const isNextColSolutionZero = (row, col, table) => {
    // check the selected row next column value
    if (col + 1 > table.length - 1) return true;

    let v = table[row][col + 1];
    let value = v === "cross" || v === "dot" || v === "0" ? "0" : "1";

    return value === "0";
  };

  const isConsecutiveRowSolution = (row_tracker, row) => {
    let start_index = row_tracker.start_index - extraCells[gridSize];
    let end_index = row_tracker.end_index - extraCells[gridSize];
    let starting_row = row - extraCells[gridSize];

    for (let i = start_index; i <= end_index; i++) {
      if (solution[starting_row][i] === "0") return false;
    }
    return isNextColSolutionZero(starting_row, end_index, solution);
  };

  const isConsecutiveColSolution = (col_tracker, col) => {
    let start_index = col_tracker.start_index - extraCells[gridSize];
    let end_index = col_tracker.end_index - extraCells[gridSize];
    let starting_col = col - extraCells[gridSize];
    for (let i = start_index; i <= end_index; i++) {
      if (solution[i][starting_col] === "0") return false;
    }
    return isNextRowSolutionZero(end_index, starting_col, solution);
  };

  const mouseEnter = (row, col) => {
    if (row < extraCells[gridSize] || col < extraCells[gridSize]) return;

    // here check the boundary value
    if (isDraggable && selectedCell) {
      let new_table_data = [...tableData];
      validateInput(row, col, selectedCell.value, new_table_data);
      new_table_data[focusInput.row][focusInput.col].is_focused = false;
      new_table_data[row][col].is_focused = true;
      let new_focus_input = { ...focusInput };
      new_focus_input.row = row;
      new_focus_input.col = col;
      setFocusInput(new_focus_input);
    }
  };
  const dragEnd = () => {
    setSelectedCell(null);
    setIsDraggable(false);
  };

  const loadNextProblem = () => {
    // currentLevel + 1
    if (currentLevel + 1 <= getMaxLevel(levels[difficultyLevel])) {
      onLoadLevel(currentLevel + 1, difficultyLevel, problems);
    }
  };

  const getMaxLevel = (array) => {
    let max = -1;
    array.forEach((p) => {
      max = p.level > max ? p.level : max;
    });
    return max;
  };

  const saveNumPlaySettings = (settings) => {
    localStorage.setItem("numplay", JSON.stringify(settings));
    setNumPlaySettings(settings);
  };

  const onLoadLevel = (
    level,
    difficulty_level = "introductory",
    problems = []
  ) => {
    setProblems(problems);
    setOperationStack([]);
    setSuccessDialog(false);
    setLoseDialog(false);
    setRetire(false);
    // load a new level by the clicked level
    let single_problem = problems.find(
      (p) => t(p.level) === t(level) && p.difficulty === difficulty_level
    );
    if (single_problem) {
      // set the current level
      // save the level and difficulty_level in the localStorage
      let newNumPlaySettings = { ...numPlaySettings };
      newNumPlaySettings.level = level;
      newNumPlaySettings.difficulty_level = difficulty_level;
      newNumPlaySettings.board = [];
      newNumPlaySettings.id = single_problem.id;
      // update if there is settings object in the newSudokuSettings
      if (newNumPlaySettings.hasOwnProperty("settings")) {
        saveNumPlaySettings(newNumPlaySettings);
      }
      let problem = single_problem.board_data;
      let split_problem = problem.split("|");
      let problem_rows = parser(split_problem[0].split("+"), ",");
      let problem_columns = parser(split_problem[1].split("+"), ",");
      let grid_size = parseInt(single_problem.grid_size);
      let table_data = createNewBoard(grid_size);
      let new_table = createChunk(
        table_data,
        grid_size + extraCells[grid_size]
      );
      new_table = rowsDataParse(new_table, problem_rows, grid_size);
      new_table = columnsDataParse(new_table, problem_columns, grid_size);
      new_table[extraCells[grid_size]][extraCells[grid_size]].is_focused = true;
      let new_solution = parseSolution(single_problem.solution, grid_size);
      setTitle(single_problem.title);
      if (single_problem.image_url) setImageUrl(single_problem.image_url);
      setId(single_problem.id);
      setAnswer(single_problem.answer_title);
      setSolution(new_solution);
      setCurrentLevel(level);
      if (!props.location.state || !props.location.state.startTime) {
        setStartTime(new Date());
      }
      countSkippableRows(new_table, grid_size);
      countSkippableCols(new_table, grid_size);
      setTableData(new_table);
      setGridSize(grid_size);
      setFocusInput({
        row: extraCells[grid_size],
        col: extraCells[grid_size],
        times: 0,
      });
    } else {
      setTableData([]);
      setCurrentLevel(1);
      alert("Level not found");
    }
  };

  const parseSolution = (sol, grid_size) => {
    // sol is a string
    // first we need to split the string with ''
    let split_sol = sol.split("");
    // then we need to create the chunk by the grid size
    // finally return the chunk
    return createChunk(split_sol, grid_size);
  };

  const setGameInputInSelectedCell = (selectedFocusInput) => {
    ConsoleHelper("enter setGameInputInSelectedCell");
    const { row, col } = focusInput;
    if (!tableData[row][col].is_focused) return;

    let new_focused_input = { ...focusInput };
    let new_table_data = [...tableData];
    new_focused_input.row = row;
    new_focused_input.col = col;
    new_focused_input.times = selectedFocusInput;
    setFocusInput(new_focused_input);
    validateInput(row, col, dict[new_focused_input.times], new_table_data);
  };

  // boundary value is [5,5] [5,14] [14, 5] [14,14]  that means row=5 and col=5 row can't be less than 5 col can't be less than 5
  const onSelectLeftCell = () => {
    // check if the column is not less than boundary value
    let new_focus_col = focusInput.col - 1;
    let boundary = extraCells[gridSize];
    if (new_focus_col >= boundary) {
      let new_table_data = [...tableData];
      // first clear the previous focus cell
      new_table_data[focusInput.row][focusInput.col].is_focused = false;
      // set the new focus cell
      new_table_data[focusInput.row][new_focus_col].is_focused = true;
      // point the focusInput to the newly focused cell
      let new_focused_input = { ...focusInput };
      new_focused_input.row = focusInput.row;
      new_focused_input.col = new_focus_col;
      setFocusInput(new_focused_input);
      setTableData(new_table_data);
      if (continuousInput.is_continuous && continuousInput.label !== "") {
        let new_table_data = [...tableData];
        // remove the previous focus input
        new_table_data[focusInput.row][focusInput.col].is_focused = false;
        // set the current row and col as focused
        new_table_data[new_focused_input.row][
          new_focused_input.col
        ].is_focused = true;
        // set the continuousInput.label as the new value
        validateInput(
          new_focused_input.row,
          new_focused_input.col,
          continuousInput.label,
          new_table_data
        );
      }
    }
  };
  const onSelectRightCell = () => {
    // check if the column is not greater than boundary value
    let new_focus_col = focusInput.col + 1;
    let boundary = gridSize + extraCells[gridSize] - 1;
    if (new_focus_col <= boundary) {
      let new_table_data = [...tableData];
      // first clear the previous focus cell
      new_table_data[focusInput.row][focusInput.col].is_focused = false;
      // set the new focus cell
      new_table_data[focusInput.row][new_focus_col].is_focused = true;
      // point the focusInput to the newly focused cell
      let new_focused_input = { ...focusInput };
      new_focused_input.row = focusInput.row;
      new_focused_input.col = new_focus_col;
      setFocusInput(new_focused_input);
      setTableData(new_table_data);
      if (continuousInput.is_continuous && continuousInput.label !== "") {
        let new_table_data = [...tableData];
        // remove the previous focus input
        new_table_data[focusInput.row][focusInput.col].is_focused = false;
        // set the current row and col as focused
        new_table_data[new_focused_input.row][
          new_focused_input.col
        ].is_focused = true;
        // set the continuousInput.label as the new value
        validateInput(
          new_focused_input.row,
          new_focused_input.col,
          continuousInput.label,
          new_table_data
        );
      }
    }
  };
  const onSelectTopCell = () => {
    // check if the row is not less than boundary value
    let new_focus_row = focusInput.row - 1;
    let boundary = extraCells[gridSize];
    if (new_focus_row >= boundary) {
      let new_table_data = [...tableData];
      // first clear the previous focus cell
      new_table_data[focusInput.row][focusInput.col].is_focused = false;
      // set the new focus cell
      new_table_data[new_focus_row][focusInput.col].is_focused = true;
      // point the focusInput to the newly focused cell
      let new_focused_input = { ...focusInput };
      new_focused_input.row = new_focus_row;
      new_focused_input.col = focusInput.col;
      setFocusInput(new_focused_input);
      setTableData(new_table_data);
      if (continuousInput.is_continuous && continuousInput.label !== "") {
        let new_table_data = [...tableData];
        // remove the previous focus input
        new_table_data[focusInput.row][focusInput.col].is_focused = false;
        // set the current row and col as focused
        new_table_data[new_focused_input.row][
          new_focused_input.col
        ].is_focused = true;
        // set the continuousInput.label as the new value
        validateInput(
          new_focused_input.row,
          new_focused_input.col,
          continuousInput.label,
          new_table_data
        );
      }
    }
  };

  const onSelectBottomCell = () => {
    // check if the row is not greater than boundary value
    let new_focus_row = focusInput.row + 1;
    let boundary = gridSize + extraCells[gridSize] - 1;
    if (new_focus_row <= boundary) {
      let new_table_data = [...tableData];
      // first clear the previous focus cell
      new_table_data[focusInput.row][focusInput.col].is_focused = false;
      // set the new focus cell
      new_table_data[new_focus_row][focusInput.col].is_focused = true;
      // point the focusInput to the newly focused cell
      let new_focused_input = { ...focusInput };
      new_focused_input.row = new_focus_row;
      new_focused_input.col = focusInput.col;
      setFocusInput(new_focused_input);
      setTableData(new_table_data);

      if (continuousInput.is_continuous && continuousInput.label !== "") {
        let new_table_data = [...tableData];
        // remove the previous focus input
        new_table_data[focusInput.row][focusInput.col].is_focused = false;
        // set the current row and col as focused
        new_table_data[new_focused_input.row][
          new_focused_input.col
        ].is_focused = true;
        // set the continuousInput.label as the new value
        validateInput(
          new_focused_input.row,
          new_focused_input.col,
          continuousInput.label,
          new_table_data
        );
      }
    }
  };

  const renderSolutionTable = () => {
    let solution_table = [];
    let start = extraCells[gridSize];
    for (let i = start; i < tableData.length; i++) {
      for (let j = start; j < tableData.length; j++) {
        solution_table.push(tableData[i][j]);
      }
    }
    setSolutionTable(
      createChunk(solution_table, tableData.length - extraCells[gridSize])
    );
  };

  const checkAnswer = () => {
    if (retire) return;
    let final_time = "";
    let current_time = getCurrentTime(startTime, new Date());
    final_time += current_time.hh === 0 ? final_time : current_time.hh + ":";
    final_time +=
      current_time.mm < 10
        ? "0" + current_time.mm + ":"
        : current_time.mm + ":";
    final_time +=
      current_time.ss < 10 ? "0" + current_time.ss : current_time.ss;

    if (isCorrectAnswer()) {
      // first check the sudokuSettings.completed_levels with the currentLevel and difficulty_level
      // if not find then save the level into the difficulty_level
      // save the current level into the completed levels array update localStorage
      renderSolutionTable();
      let found = numPlaySettings.completed_levels.find(
        (cl) =>
          cl.level === currentLevel && cl.difficulty_level === difficultyLevel
      );
      if (!found) {
        let num_play_settings = { ...numPlaySettings };
        num_play_settings.completed_levels.push({
          id: id,
          level: currentLevel,
          difficulty_level: difficultyLevel,
          rated: false,
        });
        saveNumPlaySettings(num_play_settings);
      }
      // process the shareMessage here
      let share_message = `デイリーチャレンジ（入門編・${difficulty_japanese[difficultyLevel]}${currentLevel}）を${final_time}でクリアしました！`;
      setShareMessage(share_message);
      // デイリーチャレンジ（入門編・問題01）をMM:SSでクリアしました！
      setSuccessDialog(true);
      setEndTime(final_time);
    } else {
      setLoseDialog(true);
      setEndTime(final_time);
    }
  };

  const isCorrectAnswer = () => {
    // keeps track number of inputted cell
    let count = 0;

    // ************ NEW LOGIC ****************
    // loop through the tableData
    // startingRow initially will be five
    // startingCol initially will be five
    // now solution[row - 5][col - 5] value needs to be checked
    // if any value don't match then return false
    // else return true
    let startingRow = extraCells[gridSize];
    let startingCol = extraCells[gridSize];

    for (let i = startingRow; i < tableData.length; i++) {
      for (let j = startingCol; j < tableData.length; j++) {
        let value =
          tableData[i][j].value === "cross" ||
          tableData[i][j].value === "dot" ||
          tableData[i][j].value === "0"
            ? "0"
            : "1";

        // check the value with the solution cell
        // if value is not same then return false

        if (
          solution[i - extraCells[gridSize]][j - extraCells[gridSize]] !== value
        )
          return false;
      }
    }

    return true;
  };

  const rowsDataParse = (table, problem_rows, grid_size) => {
    let start_row_index = extraCells[grid_size];
    for (let i = 0; i < problem_rows.length; i++) {
      let start_col_index = extraCells[grid_size] - 1;
      if (start_row_index < table.length) {
        for (let j = 0; j < problem_rows[i].length; j++) {
          table[start_row_index][start_col_index] = {
            value: problem_rows[i][j],
            is_fill: false,
            completed: false,
            valid: true,
          };
          start_col_index--;
        }
        start_row_index++;
      }
    }
    return table;
  };

  const columnsDataParse = (table, problem_columns, grid_size) => {
    let start_col_index = extraCells[grid_size];
    for (let i = 0; i < problem_columns.length; i++) {
      let start_row_index = extraCells[grid_size] - 1;
      if (start_col_index < table.length) {
        for (let j = 0; j < problem_columns[i].length; j++) {
          table[start_row_index][start_col_index] = {
            value: problem_columns[i][j],
            is_fill: false,
            is_editable: false,
            completed: false,
            valid: true,
          };
          start_row_index--;
        }
        start_col_index++;
      }
    }
    return table;
  };

  const createNewBoard = (grid_size) => {
    let table_data = [];
    let board_size = grid_size + extraCells[grid_size]; // extra cells for rowData and colData
    for (let i = 0; i < board_size; i++) {
      for (let j = 0; j < board_size; j++) {
        table_data.push({
          value: "0",
          is_fill: false,
          is_editable: true,
          is_focused: false,
          color: "black",
        });
      }
    }
    return table_data;
  };

  const eraseAll = () => {
    if (window.confirm("本当に消去しますか？")) {
      resetTimer();
      onLoadLevel(currentLevel, difficultyLevel, problems);
    }
  };

  const undo = () => {
    // check the operation stack first
    if (operationStack.length > 0) {
      let operation_stack = [...operationStack];
      let table_data = [...tableData];
      let lastOperation = operation_stack.pop();
      let row = lastOperation.row;
      let col = lastOperation.col;
      lastOperation.row = undefined;
      lastOperation.col = undefined;
      table_data[row][col] = lastOperation;
      table_data[row][col].is_focused = false;
      table_data = isSafe(row, col, table_data, solution, gridSize);
      updateIsSafeResult(table_data);
      setOperationStack(operation_stack);
      // save the newTableData in the localStorage
      let newNumPlaySettings = { ...numPlaySettings };
      newNumPlaySettings.board = table_data;
      saveNumPlaySettings(newNumPlaySettings);
    }
  };

  const onClickProblemCell = (row, col) => {
    let table_data = [...tableData];
    table_data[row][col].completed = !table_data[row][col].completed;
    setTableData(table_data);
  };

  const onRetire = () => {
    if (window.confirm("本当にあきらめますか?")) {
      const table = [...tableData];
      setRetire(true);
      for (let i = 0; i < solution.length; i++) {
        for (let j = 0; j < solution.length; j++) {
          table[i + extraCells[gridSize]][j + extraCells[gridSize]].value =
            solution[i][j] === "1" ? "gray" : "0";
        }
      }
      setTableData(table);
    }
  };

  const addRating = (rating, difficulty_rating) => {
    setSuccessDialog(false);
    let newNumPlaySettings = { ...numPlaySettings };
    // get the completed_levels from the sudokuSettings
    let completed_levels = [...numPlaySettings.completed_levels];
    // get the last element from the completed_levels
    let level_completed = numPlaySettings.completed_levels.find(
      (cl) =>
        cl.difficulty_level === difficultyLevel && cl.level === currentLevel
    );
    // get the id

    // set timeout function here

    if (level_completed && !level_completed.rated) {
      // call the server for add rating
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", process.env.REACT_APP_AUTHORIZATION_TOKEN);

      let raw = JSON.stringify({
        board_id: level_completed.id,
        rating: rating,
        difficulty_rating: difficulty_rating,
      });

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
      };

      fetch(BASE_URL + "/api/illustroboard/rating", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          let index = completed_levels.findIndex(
            (cl) =>
              cl.difficulty_level === difficultyLevel &&
              cl.level === currentLevel
          );
          completed_levels[index].rated = true;
          newNumPlaySettings.completed_levels = completed_levels;
          saveNumPlaySettings(newNumPlaySettings);
          // redirect to the illustrology main page
          redirectTo("", "/illustrology_main");
        })
        .catch((error) => {
          console.log("error", error);
          redirectTo("", "/illustrology_main");
        });
    } else {
      // redirect to the illustrology main page
      redirectTo("", "/illustrology_main");
    }
  };

  const refreshBoard = (table_data, grid_size, problem_solution, numset) => {
    // is_highlight = false, is_selected = false, border = '1px solid black'
    table_data.map((rows) => {
      rows.map((cell) => {
        cell.is_focused = false;
      });
    });
    countSkippableRows(table_data, grid_size);
    countSkippableCols(table_data, grid_size);
    if (!numset.midway_check) {
      // setting row cell hints off
      for (let row = 0; row < parseInt(extraCells[grid_size]); row++) {
        for (
          let col = parseInt(extraCells[grid_size]);
          col < parseInt(extraCells[grid_size]) + parseInt(grid_size);
          col++
        ) {
          table_data[row][col].completed = false;
        }
      }
      // setting col cell hints off
      for (
        let row = parseInt(extraCells[grid_size]);
        row < parseInt(extraCells[grid_size]) + parseInt(grid_size);
        row++
      ) {
        for (let col = 0; col < parseInt(extraCells[grid_size]); col++) {
          table_data[row][col].completed = false;
        }
      }
    } else {
      // setting cell hints on
      problem_solution = parseSolution(problem_solution, parseInt(grid_size));
      for (
        let i = parseInt(extraCells[grid_size]);
        i < parseInt(extraCells[grid_size]) + parseInt(grid_size);
        i++
      ) {
        table_data = isSafe(i, i, table_data, problem_solution, grid_size);
      }
    }
    setTableData(table_data);
  };

  const arrangeProblemStages = (problems) => {
    let temp_data = { ...levels };

    Object.keys(levels).map((key) => {
      temp_data[key] = problems.filter((object) => {
        return object["difficulty"] === key;
      });
    });
    setLevels(temp_data);
  };

  const isProblemRowEmpty = (row, rowindex, grid_size) => {
    if (rowindex < extraCells[grid_size]) {
      let sum = 0;
      sum = row.reduce((a, c) => (a += parseInt(c.value)), 0);
      return sum == 0;
    } else {
      return false;
    }
  };

  const isProblemColEmpty_v2 = (table, colindex, grid_size) => {
    if (colindex < extraCells[grid_size]) {
      let sum = 0;
      for (
        let i = extraCells[grid_size];
        i < parseInt(grid_size) + parseInt(extraCells[grid_size]);
        i++
      ) {
        sum += parseInt(table[i][colindex].value);
      }
      return sum == 0;
    } else {
      return false;
    }
  };

  const loadData = () => {
    let num_play_settings = JSON.parse(localStorage.getItem("numplay"));
    setNumPlaySettings(num_play_settings);

    const setProblemRelatedStates = (problem, data) => {
      let grid_size = parseInt(problem.grid_size);
      if (problem.image_url) setImageUrl(problem.image_url);
      setId(problem.id);
      setGridSize(grid_size);
      setAnswer(problem.answer_title);
      setTitle(problem.title);
      setProblems(data);
      setSolution(parseSolution(problem.solution, grid_size));
      setCurrentLevel(problem.level);
      setDifficultyLevel(problem.difficulty);
      setFocusInput({
        row: extraCells[grid_size],
        col: extraCells[grid_size],
        times: 0,
      });
    };

    const setLocalStorageRelatedStates = (problem) => {
      num_play_settings.difficulty_level = problem.difficulty;
      num_play_settings.level = problem.level;
      num_play_settings.id = problem.id;
      num_play_settings.board = [];
      saveNumPlaySettings(num_play_settings);
    };

    //console.log("loadData props.location.state.startTime ", props.location.state.startTime)

    // either the problem data will get from the server
    // we will load the game array from server here.
    // initially it will load first level board
    // when returning back from the setting page title is removed
    const fetchData = async () => {
      fetch(BASE_URL + "/api/illustroboards")
        .then((res) => res.json())
        .then((resData) => {
          arrangeProblemStages(resData.data);
          // check if the saved table is same as currently clicked
          let saved_problem = num_play_settings.id
            ? resData.data.find(
                (p) => parseInt(p.id) === parseInt(num_play_settings.id)
              )
            : resData.data.find(
                (p) =>
                  parseInt(p.level) === parseInt(num_play_settings.level) &&
                  p.difficulty === num_play_settings.difficulty_level
              );

          // check the if props.location.state
          let id =
            props.location.state && props.location.state.id
              ? props.location.state.id
              : saved_problem.id;

          /*
                        num_play_settings.board.length => progress saved => prosav
                        t(saved_problem_id) === t(id) => selected and previous problem are same => spppsa

                        prosav  spppsa  action
                        true    true    load localStorage.board data
                        true    false   discard localStorage.board data and load the new problem from resData and set localStorage.board data
                        false   true    load the new problem from resData and set localStorage.board data*
                        false   false   load the new problem from resData and set localStorage.board data*
                        * prosav is false so the last 3 conditions are same
                     */
          let prosav = num_play_settings.board.length !== 0;
          let spppsa = parseInt(saved_problem.id) === parseInt(id);

          if (
            prosav &&
            spppsa &&
            ((props.location.state && props.location.state.startTime) ||
              num_play_settings.settings.auto_save)
          ) {
            // console.log("case 1 prosav: ", prosav, " spppsa: ", spppsa);
            let single_problem = resData.data.find(
              (p) => parseInt(p.id) === parseInt(id)
            );
            setProblemRelatedStates(single_problem, resData.data);
            refreshBoard(
              num_play_settings.board,
              single_problem.grid_size,
              single_problem.solution,
              num_play_settings.settings
            );
          } else {
            let problem = resData.data.find(
              (p) => parseInt(p.id) === parseInt(id)
            );
            setProblemRelatedStates(problem, resData.data);
            setLocalStorageRelatedStates(problem);
            onLoadLevel(problem.level, problem.difficulty, resData.data);
          }
        })
        .catch(console.log);
      // from the local storage
      // and also when another page play button is clicked id will get. we should load that problem from the id
      // also it's better to fetch all the problems and store it into a array. so that when a game is finish immedietly
      // start the next problem
    };
    fetchData();
  };

  const keyPress = (event) => {
    // event.keyCode === 39 arrow right key code
    // event.keyCode === 37 arrow left key code
    // event.keyCode === 38 arrow up key code
    // event.keycode === 40 arrow down key code
    if (event.keyCode === 39) {
      onSelectRightCell();
    }
    if (event.keyCode === 37) {
      onSelectLeftCell();
    }
    if (event.keyCode === 38) {
      onSelectTopCell();
    }
    if (event.keyCode === 40) {
      onSelectBottomCell();
    }
  };

  // similar to class based lifecycle hook method componentDidMount
  // componentDidUpdate componentWillMount
  useEffect(() => {
    saveSettings();
    let navigation = performance.getEntriesByType("navigation")[0];
    if (navigation && navigation.type === "reload") {
      setInitialTimer();
    }
    loadData();
  }, []);

  const setInitialTimer = () => {
    let sudoku_settings = JSON.parse(localStorage.getItem("numplay"));
    if (!sudoku_settings.settings.auto_save) {
      //Means it is reloaded
      let startTime = null;
      props.history.replace("/illustrologic", { startTime });
    }
  };

  useEffect(() => {
    if (successDialog || loseDialog) persistStartTime();
    else {
      setInitialTimer();
      setStartTime(getStartTimeWhenBack);
    }
  }, [successDialog, loseDialog]);

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    // componentWillUnmount
    // onPointerUp event
    // onPointerCancel event
    document.addEventListener("pointerup", dragEnd);
    document.addEventListener("pointercancel", dragEnd);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.removeEventListener("pointerup", dragEnd);
      document.removeEventListener("pointercancel", dragEnd);
    };
  });

  const redirectTo = (event, path) => {
    setLoseDialog(false);
    props.history.push(path);
  };

  let gameTableDimension = { width: 0, height: 0 };
  const getHW = () => {
    let elem = window.document.getElementById("xxzx");
    if (elem) {
      let width = elem.offsetWidth;
      let height = elem.offsetHeight;
      let min_w = width < height ? width : height;
      gameTableDimension = { width: min_w, height: min_w };
      return { width: min_w, height: min_w };
    }
  };

  const [skipRowsCount, setSkipRowsCount] = useState(0);
  const countSkippableRows = (table, grid_size) => {
    let count = 0;
    let index = 0;
    while (index < extraCells[grid_size]) {
      if (isProblemRowEmpty(table[index], index, grid_size)) {
        count++;
      }
      index++;
    }
    setSkipRowsCount(count);
  };
  const [skipColsCount, setSkipColsCount] = useState(0);
  const countSkippableCols = (table, grid_size) => {
    let count = 0;
    let index = 0;
    while (index < extraCells[grid_size]) {
      if (isProblemColEmpty_v2(table, index, grid_size)) count++;
      index++;
    }
    setSkipColsCount(count);
  };

  const getDivider = (table_data) => {
    let emptyProblemRowColNum = { row: 0, col: 0 };
    if (table_data.length) {
      let empty_problem_rows = 0;
      for (let i = 0; i < extraCells[gridSize]; i++) {
        let sum = 0;
        for (let j = extraCells[gridSize]; j < gridSize; j++) {
          sum += parseInt(table_data[i][j].value);
        }
        if (sum == 0) empty_problem_rows++;
      }
      let empty_problem_cols = 0;
      for (let j = 0; j < extraCells[gridSize]; j++) {
        let sum = 0;
        for (let i = extraCells[gridSize]; i < gridSize; i++) {
          sum += parseInt(table_data[j][i].value);
        }
        if (sum == 0) empty_problem_cols++;
      }
      emptyProblemRowColNum = {
        row: empty_problem_rows,
        col: empty_problem_cols,
      };
    }
    let skip =
      emptyProblemRowColNum.row < emptyProblemRowColNum.col
        ? emptyProblemRowColNum.row
        : emptyProblemRowColNum.col;
    return {
      divideby: table_data.length - skip,
      skip: skip,
    };
  };

  const getStartTimeWhenBack = () => {
    if (props.location.state && props.location.state.startTime) {
      var timeDifference = moment(props.location.state.pauseTime).diff(
        moment(props.location.state.startTime)
      );
      return moment().subtract(timeDifference).toDate();
    }
    return new Date();
  };

  // keep the start time
  const persistStartTime = () => {
    let pauseTime = new Date();
    props.history.replace("/illustrologic", { startTime, id, pauseTime });
  };

  const resetTimer = () => {
    let startTime = new Date();
    props.history.replace("/illustrologic", { startTime });
    setStartTime(startTime);
  };

  return (
    <div>
      <div className="firstBody">
        <div className="header">イラストロジック</div>

        <div id="bodyText">
          {title}
          {/* Settings */}
          <Link
            to="/illustration_logic_setting"
            className="nav_box_setting"
            onClick={persistStartTime}
          >
            <FaCog />
          </Link>
        </div>
      </div>

      <SuccessModal
        successDialog={successDialog}
        onNext={loadNextProblem}
        setSuccessDialog={setSuccessDialog}
        shareMessage={shareMessage}
        answer={answer}
        solution={solutionTable}
        addRating={addRating}
        imageUrl={imageUrl}
        endTime={endTime}
      />

      <LoseModal
        loseDialog={loseDialog}
        onContinue={setLoseDialog}
        onStartAgain={eraseAll}
        setLoseDialog={setLoseDialog}
        redirectTo={redirectTo}
        endTime={endTime}
      />

      <div id="xxzx" className={`game_div`}>
        <div className={`game_table`} style={getHW()}>
          {tableData.map((rows, row) => {
            if (row < skipRowsCount) {
              return;
            }
            // let row_height = (gameTableDimension.height / divider.divideby);
            let row_height =
              gameTableDimension.height / (tableData.length - skipRowsCount);
            return (
              <div
                key={row}
                className={`game_table_tr game_table_tr_${gridSize}`}
              >
                {rows.map((cell, col) => {
                  if (col < skipColsCount) return;
                  return (
                    <div
                      key={col}
                      className={`game_table_td ${
                        cell.is_focused ? "cell_focused" : ""
                      }`}
                      onMouseDown={(event) =>
                        onFocusInput(event, row, col, "onMouseDown")
                      }
                      onContextMenu={(event) =>
                        onFocusInput(event, row, col, "onContextMenu")
                      }
                      onMouseEnter={() => mouseEnter(row, col)}
                      style={{ width: row_height, height: row_height }}
                    >
                      <IllustrationLogicInput
                        dataObject={cell}
                        onClickProblemCell={onClickProblemCell}
                        row={row}
                        col={col}
                        gridSize={gridSize}
                        extraCells={extraCells[gridSize]}
                        fontSize={row_height * 0.65}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className="buttons_div">
        {/* Buttons Start */}
        {/* Horizontal Divs */}
        <div className="buttons_container">
          <div className="left_buttons">
            <button className="btn btn_up" onClick={() => onSelectTopCell()}>
              ▲
            </button>
            <button className="btn btn_left" onClick={() => onSelectLeftCell()}>
              ▼
            </button>
            <button
              className="btn btn_right"
              onClick={() => onSelectRightCell()}
            >
              ▲
            </button>
            <button
              className="btn btn_down"
              onClick={() => onSelectBottomCell()}
            >
              ▼
            </button>
          </div>
          <div className="right_buttons">
            <div className="game_inputs_div">
              <button
                className={
                  selectedGameInputButton == 3
                    ? "gameInputButtonSelected"
                    : "gameInputButton"
                }
                onClick={() => onInput("dot")}
              >
                •
              </button>
              <button
                className={
                  selectedGameInputButton == 4
                    ? "gameInputButtonSelected"
                    : "gameInputButton"
                }
                onClick={() => onInput("0")}
              />
              <button
                className={
                  selectedGameInputButton == 1
                    ? "greyInputButtonSelected"
                    : "greyInputButton"
                }
                onClick={() => onInput("gray")}
              />
              <button
                className={
                  selectedGameInputButton == 2
                    ? "gameInputButtonSelected"
                    : "gameInputButton"
                }
                onClick={() => onInput("cross")}
              >
                X
              </button>
            </div>
            <div className="buttons_container2">
              <div className="left_buttons2">
                <button
                  className="btn btn_undo"
                  id="doubleSizeBtn"
                  onClick={() => undo()}
                >
                  <FaUndoAlt />
                </button>
                <button
                  className="btn btn_erase"
                  id="doubleSizeBtn"
                  onClick={() => eraseAll()}
                >
                  全消去
                </button>
              </div>
              <div className="right_buttons2">
                <button
                  id="doubleSizeBtn2"
                  className={
                    "btn btn_continuous " +
                    (continuousInput.is_continuous
                      ? "btn_continuous_input_active"
                      : "")
                  }
                  onClick={() =>
                    setContinuousInput({
                      ...continuousInput,
                      is_continuous: !continuousInput.is_continuous,
                      label: continuousInput.is_continuous
                        ? continuousInput.label
                        : "",
                    })
                  }
                >
                  連続入力
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Buttons End */}
      </div>
      <div className="bigButtonsBody">
        <div>
          <button onClick={() => checkAnswer()} className="bodyBtnBlue">
            答えあわせ
          </button>
        </div>
        <br />
        <div>
          <button className="bodyBtnWhite" onClick={() => onRetire()}>
            あきらめる
          </button>
        </div>
      </div>
      <div
        className="footer"
        onClick={(event) => {
          redirectTo(event, "/illustrology_main");
        }}
      >
        イラストロジックTOPへ戻る
      </div>
    </div>
  );
}

export default IllustroloyBoard;
