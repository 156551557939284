import React, {Fragment, useEffect, useState} from 'react';
import {FaCog} from "react-icons/fa";
import {Link} from "react-router-dom";
import './highlyRatedNameplay.css';
import './highly_rated_navbar.css'
import {BASE_URL} from '../../config';
import {createChunk, compareValues} from "../../utils/functions";
import ReactDOMServer from "react-dom/server";
import sudokuTemplate from "../../components/SudokuTemplate";
import SudokuPdfContent from "../../components/SudokuPdfContent";


function HighlyRatedNamePlay(props) {

    let serialNumber = 0;
    let serial = 0;

    const [difficulty_level, setDifficultyLevel] = useState(props.location.state.difficulty_level);
    const levelObject = {'introductory': [], 'beginner': [], 'intermediate': [], 'advanced': [], 'expert': []};
    const [data, setData] = useState(levelObject);
    const [difficultyGroupItems, setDifficultyGroupItems] = useState([]);

    const headerRef = React.createRef();

    const changeDifficulty = (temp_data, difficulty) => {
        // group items 5 groups
        // introductory -> currently difficult level
        let difficulty_items = temp_data[difficulty];
        // create a chunk of 5 items
        let difficulty_group_items = createChunk(difficulty_items, 5);
        setDifficultyLevel(difficulty);
        setDifficultyGroupItems(difficulty_group_items);
    };

    const changeDifficultyHandler = (event, difficulty) => {
        event.preventDefault();
        changeDifficulty(data, difficulty);
    };

    function storeData(data) {
        let temp_data = {...data};
        Object.keys(levelObject).map((key) => {
            temp_data[key] = data.filter((object) => {
                return object['difficulty_level'] === key;
            });
        });

        //Only taking the past problem .
        Object.keys(levelObject).map((key) => {
            temp_data[key] = temp_data[key].slice(0,((temp_data[key].length /5)-1)*5);
        });

        //sort temp_data based on ratings
        Object.keys(levelObject).forEach((key) => {
             temp_data[key].forEach((d) => {

                //If more than 10 people rated it then we will take it
                if ( d.person_rated >= 10) {
                    d.ratings = d.ratings / d.person_rated;
                }
                else
                {
                    temp_data[key] = temp_data[key].filter(data => data.id != d.id);
                }
                //d.ratings = d.person_rated === 0 ? 0 : d.ratings / d.person_rated;
             });
            temp_data[key].sort(compareValues('ratings', 'desc'));
        });
        changeDifficulty(temp_data, props.location.state.difficulty_level);
        setData(temp_data);
    }

    const contentHtml = (problems) => {
        return (
            <Fragment>
                <SudokuPdfContent problems={problems}/>
            </Fragment>
        )
    };

    const onPrintPdf = (index) => {
        // render the html and send to server for generating pdf.
        let static_content_html = ReactDOMServer.renderToStaticMarkup(contentHtml(difficultyGroupItems[index]));
        let html = sudokuTemplate(static_content_html);
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let data = {
            html: html
        };
        fetch(BASE_URL + '/api/sudoku/pdf', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: myHeaders
        }).then(res => res.blob())
            .then(blob => {
                const a = document.createElement('a');
                document.body.appendChild(a);
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = 'sudoku.pdf';
                a.click();
                setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                }, 0)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const redirectTo = (event, path) => {
        event.preventDefault();
        props.history.push(path);
    };
  
    const startGame = (id, difficulty_level, group_index) => {
        props.history.push('/sudoku', {id, difficulty_level, problems: difficultyGroupItems[group_index]});
    };
    useEffect(() => {
        const fetchData = async () => {
            await fetch(BASE_URL + '/api/boards')
                .then(res => res.json())
                .then(json => storeData(json.data))
                .catch(error => console.log(error))
        };
        fetchData();

    }, []);

    const incrementSerial = function (value) {
        serial = value;
    };

    return (
        <div id='hi_rate_parent_wrapper'>

            <div id="hi_rate_header" ref={headerRef}>
                問題集(過去問)
            </div>
            <div className="top-nav" id='hi_rate_top_nav'>
                {/*初級 Introductory*/}

                <a href="/" className={`custom_a ${difficulty_level === 'introductory' ? 'top-nav_active' : ''}`}
                   onClick={event => changeDifficultyHandler(event, 'introductory')}>入門</a>

                {/*初級 Beginner*/}

                <a href="/" className={`custom_a ${difficulty_level === 'beginner' ? 'top-nav_active' : ''}`}
                   onClick={event => changeDifficultyHandler(event, 'beginner')}>初級</a>

                {/*中級 Intermediate*/}

                <a href="/" className={`custom_a ${difficulty_level === 'intermediate' ? 'top-nav_active' : ''}`}
                   onClick={event => changeDifficultyHandler(event, 'intermediate')}>中級</a>

                {/*上級 Advanced*/}

                <a href="/" className={`custom_a ${difficulty_level === 'advanced' ? 'top-nav_active' : ''}`}
                   onClick={event => changeDifficultyHandler(event, 'advanced')}>上級</a>

                {/*難問 Difficult question*/}

                <a href="/" className={`custom_a ${difficulty_level === 'expert' ? 'top-nav_active' : ''}`}
                   onClick={event => changeDifficultyHandler(event, 'expert')}>難問</a>

                {/* Settings */}

                <Link to='/setting' className='nav_box_setting'><FaCog/></Link>
            </div>

            {
                difficultyGroupItems.map((group, group_index) => {
                    let s = serial;
                    return (
                        <div id='hi_rate_group' key={group_index}>
                            <div id="hi_rate_content">
                                <table>
                                    <thead>

                                        {
                                            group_index == 0 ? 
                                            <tr>
                                                <th>順位</th>
                                                <th>平均評価</th>
                                                <th>問題番号</th>
                                                <th/>
                                            </tr> :<tr style={{color: "#ffffff", background: "#ffffff"}}>
                                                <th>順位</th>
                                                <th>平均評価</th>
                                                <th>問題番号</th>
                                                <th/>
                                            </tr>
                                        }
                                    
                                    </thead>
                                    <tbody>
                                    {
                                        group.map((row, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{++serialNumber}</td>
                                                    <td>{row['ratings'].toFixed(2)}</td>
                                                    <td>問題 {row['level']}</td>
                                                    <td>
                                                        <button className='hi_rate_start_button'
                                                                onClick={() => startGame(row.id, row.difficulty_level,group_index)}>START
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                                <button id='hi_rate_whiteButton'
                                        onClick={() => onPrintPdf(group_index)}>トップ{serial + 1}～{serial + group.length}を印刷
                                </button>
                                {
                                    incrementSerial(serial + group.length)
                                }
                            </div>
                        </div>
                    )
                })
            }

            <div id="hi_rate_footer" onClick={event => redirectTo(event, '/')}>
                ナンプレTOPへ戻る
            </div>

        </div>
    );
}

export default HighlyRatedNamePlay;
