import React from "react";
import './NumpureSolutionTable.css';

const dict = {
    'gray': 'gray_solution',
    '1': 'gray_solution',
    '0': 'no_gray',
    'cross': 'no_gray',
    'dot': 'no_gray'
};

const NumpureSolutionTable = (props) => {
    const {solution, answer} = props;
    let height = 20 / solution.length;
    let width = height * solution.lrngth;
    height = height + "vh";
    width = width + "vh";
    const solutionTable = solution || [];
    return (
        <div className={`solution_div`} style={{
            width: "100%",
            backgroundColor: '#fff',
            marginTop: -8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <h5 style={{color: 'black', textAlign: 'center',marginBottom:4}}>{answer}</h5>
            <table className={`solution_table`} style={{width: width}}>
                <tbody>
                {
                    solutionTable.map((rows, row) => {
                        return (
                            <tr key={row} className={`solution_table_tr`}>
                                {
                                    rows.map((cell, col) => {
                                        return (
                                            <td key={col}
                                                className={`solution_table_td ${dict[cell.value]}`}
                                                style={{height: height, width: height}}    
                                            />
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
                </tbody>

            </table>
        </div>
    )
};

export default NumpureSolutionTable;
