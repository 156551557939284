import React, {useState} from 'react'
import './illustrologyPlayByLevel.css'
import {Link} from 'react-router-dom'
import {FaCog, FaCheck} from "react-icons/fa";
import {useEffect} from 'react';
import {BASE_URL} from '../../config';
import {saveSettings, compareValues} from '../../utils/functions';


var tempBoardByLevelData = ""
var difficultyLevelLocal="introductory"
//This is needed so that after sort we can set the data again
var copyOfBoardData= []

function IllustrologyPlayByLevel(props) {

    const [level, setLevel] = useState('introductory');
    const [sortProperty, setSortProperty] = useState(''); // popularity, new_to_old, old_to_new
    const initialBoards = {'introductory': [], 'beginner': [], 'intermediate': [], 'advanced': [], 'expert': []};
    const [boardByLevel, setBoardByLevel] = useState(initialBoards);
    const [completedLevels, setCompletedLevels] = useState([]);

    // fetch all illustrologyboards and store them by levels
    useEffect(() => {
        saveSettings();
        let numplay_settings = JSON.parse(localStorage.getItem('numplay'));
        if (numplay_settings) {
            let completed_levels = numplay_settings.completed_levels;
            setCompletedLevels(completed_levels);
        }
        if (props.location.state) {
            setLevel(props.location.state.startingLevel);
            difficultyLevelLocal = props.location.state.startingLevel
        }
        const fetchData = async () => {
            await fetch(BASE_URL + '/api/illustroboards')
                .then(res => res.json())
                .then(json => {
                    categorizeDataByLevel(json.data);
                })
        };
        fetchData();

    }, []);

    const startGame = (id) => {
        props.history.push('/illustrologic', {id});
    };

    const getSortedValueByDifficultyRatings=(data , level)=>{
            return data.filter(function(item) {

                if(item.person_rated_difficulty<=10)
                {
                    if(item.difficulty === level)return true
                    else return false
                }
                
                var averageDifficultyrating = 0.0
                if(item.difficulty_ratings !=0 && item.person_rated_difficulty !=0)
                {
                    averageDifficultyrating = item.difficulty_ratings.toFixed(2)/item.person_rated_difficulty.toFixed(2) ;

                }

                if(level == "introductory"){
                    if(averageDifficultyrating.toFixed(2) >= 1.0 && averageDifficultyrating.toFixed(2)<=1.99)
                        return true
                    else
                        return false
                }
                else  if(level == "beginner"){
                    if(averageDifficultyrating.toFixed(2) >= 2.0 && averageDifficultyrating.toFixed(2)<=3.99)
                        return true
                    else
                        return false
                }
                else  if(level == "intermediate"){
                    if(averageDifficultyrating.toFixed(2) >= 4.0 && averageDifficultyrating.toFixed(2)<=6.99)
                        return true
                    else
                        return false
                }
                else  if(level == "advanced"){
                    if(averageDifficultyrating.toFixed(2) >= 7.0 && averageDifficultyrating.toFixed(2)<=8.99)
                        return true
                    else
                        return false
                }
                else  if(level == "expert"){
                    if(averageDifficultyrating.toFixed(2) >= 9.0)
                        return true
                    else
                        return false
                }
                
          }); 
        
    }

    const categorizeDataByLevel = (data) => {

        let tempBoard = {...initialBoards};
        for (let key in initialBoards) {
             tempBoard[key] = getSortedValueByDifficultyRatings(data,key)
        }
        tempBoardByLevelData =tempBoard
        copyOfBoardData = {...tempBoard}
        sortData(sortProperty);
    };

    const setDifficultyLevel = (event, level) => {
        event.preventDefault();
        setLevel(level);
        difficultyLevelLocal = level;
        sortData(sortProperty);
        props.history.replace(props.location.pathname, {...props.location.state, startingLevel: difficultyLevelLocal});
    };

    const redirectTo = (event, path) => {
        event.preventDefault();
        props.history.push(path);
    };

    const sortData = (sort_value) => {

        var board_by_level = {...boardByLevel};
        if(board_by_level[difficultyLevelLocal].length==0)
        {
            board_by_level=tempBoardByLevelData
        } 

        if (sort_value == '') {
            if (props.location.state) {
                sort_value = props.location.state.sortOrder;
            } else {
                sort_value = 'popularity'; // default value
            }
        }
        props.history.replace(props.location.pathname, {...props.location.state, sortOrder: sort_value});

        if (sort_value === 'popularity') {
             let tempData = copyOfBoardData[difficultyLevelLocal].filter(item => item.person_rated>10)
             board_by_level[difficultyLevelLocal] = tempData
             board_by_level[difficultyLevelLocal].sort(compareValues('ratings', 'desc'));
        } else if (sort_value === 'new_to_old') {
            board_by_level[difficultyLevelLocal] = copyOfBoardData[difficultyLevelLocal]
            board_by_level[difficultyLevelLocal].sort(compareValues('created_at', 'desc'));
        }else{
            board_by_level[difficultyLevelLocal] = copyOfBoardData[difficultyLevelLocal]
            board_by_level[difficultyLevelLocal].sort(compareValues('created_at', 'asc'));
        }

        if(boardByLevel[difficultyLevelLocal].length==0)
        {
            setBoardByLevel(tempBoardByLevelData);
        }
       
        setBoardByLevel(board_by_level)
        setSortProperty(sort_value);
    };


    return (
        <div>
            <div className="header">イラストロジック</div>
            <div className="top-nav">
                {/*初級 Introductory*/}

                <a href="/" className={`custom_a ${level === 'introductory' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setDifficultyLevel(e, 'introductory')}>入門</a>

                {/*初級 Beginner*/}

                <a href="/" className={`custom_a ${level === 'beginner' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setDifficultyLevel(e, 'beginner')}>初級</a>

                {/*中級 Intermediate*/}

                <a href="/" className={`custom_a ${level === 'intermediate' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setDifficultyLevel(e, 'intermediate')}>中級</a>

                {/*上級 Advanced*/}

                <a href="/" className={`custom_a ${level === 'advanced' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setDifficultyLevel(e, 'advanced')}>上級</a>

                {/*難問 Expert*/}

                <a href="/" className={`custom_a ${level === 'expert' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setDifficultyLevel(e, 'expert')}>難問</a>

                {/* Settings */}
                <Link to='/illustration_logic_setting' className='nav_box_setting'><FaCog/></Link>
            </div>
            <div className="secondNav">
                {/* Order of popularity */}
                <div className={(sortProperty === 'popularity' ? 'active_sort' : '')}
                     onClick={() => sortData('popularity')}>人気順
                </div>
                {/* Order of posted date (New to Old) */}
                <div className={sortProperty === 'new_to_old' ? 'active_sort' : ''}
                     onClick={() => sortData('new_to_old')}>投稿日昇順
                </div>
                {/* Order of posted date (Old to New) */}
                <div className={sortProperty === 'old_to_new' ? 'active_sort' : ''}
                     onClick={() => sortData('old_to_new')}>投稿日降順
                </div>
            </div>
            <div className="table-div">
                <table className="table-containing-boardbylevels">
                    <tbody>
                    {/* load data by levels */}
                    {
                        boardByLevel[level].map((row, key) => {
                            let level_completed = completedLevels.find(cl => cl.difficulty_level === row.difficulty && cl.level === row.level);
                            return (
                                <tr key={key}>
                                    <td>{level_completed ? (<FaCheck/>) : ''}</td>
                                    <td>{row.title} / {row.publisher}</td>
                                    <td>
                                        <button onClick={() => startGame(row.id)}>START</button>
                                    </td>
                                </tr>
                            );
                        })
                    }

                    </tbody>
                </table>
            </div>
            <div className="footer" onClick={event => {
                redirectTo(event, '/')
            }}>イラストロジックTOPへ戻る
            </div>
        </div>
    )
}

export default IllustrologyPlayByLevel;
