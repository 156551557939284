import React from 'react';
import {FaCog} from "react-icons/fa";
import './header.css'
import { Link} from 'react-router-dom'


export default function Header(props) {

    const {difficultyLevel} = props;
    const setDifficultyLevel = (event, level) => {
        event.preventDefault(); // this will prevent it's default behaviour. which is redirecting to another page
        props.onSetDifficultyLevel(level); // this will call the parent function
    };

    //We will save timer for nunpuru
    const saveTimer =()=>{
        if(props.saveTimer())
        {
            props.saveTimer();
        }
        
    }

    return (
        <div>
            <div className='board_header'>
                デイリーチャレンジ
            </div>
            <div className="top-nav">
                {/*初級 Introductory*/}

                <a href="/" className={`custom_a ${difficultyLevel === 'introductory' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setDifficultyLevel(e, 'introductory')}>入門</a>

                {/*初級 Beginner*/}

                <a href="/" className={`custom_a ${difficultyLevel === 'beginner' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setDifficultyLevel(e, 'beginner')}>初級</a>

                {/*中級 Intermediate*/}

                <a href="/" className={`custom_a ${difficultyLevel === 'intermediate' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setDifficultyLevel(e, 'intermediate')}>中級</a>

                {/*上級 Advanced*/}

                <a href="/" className={`custom_a ${difficultyLevel === 'advanced' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setDifficultyLevel(e, 'advanced')}>上級</a>

                {/*難問 Difficult question*/}

                <a href="/" className={`custom_a ${difficultyLevel === 'expert' ? 'top-nav_active' : ''}`}
                   onClick={(e) => setDifficultyLevel(e, 'expert')}>難問</a>

                {/* Settings */}
                <Link to='/setting' className='nav_box_setting' onClick={saveTimer}><FaCog/></Link> 
            </div>
        </div>
    );
}
