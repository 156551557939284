import React, {useEffect, useState} from 'react';
import './index.css';
import {FaUndoAlt} from "react-icons/fa";
import {createChunk, saveSettings} from '../../utils/functions';
import ProblemInput from '../../components/ProblemInput';
import {BASE_URL} from '../../config';


//To track if dragging end . Because dragging work differently in windows and mac
var isEnteredInDragEnd = 1
var isLeftMouseClicked = false
var previousFocusInput = ""

// Game Logic
// Add extra five rows and columns for inputting problem data
function ProblemInputScreen(props) {

    const extraCells = {
        10: 5, // grid size 10 will have 5 extra problem cells
        15: 8, // grid size 15 will have 8 extra problem cells
        20: 10, // grid size 20 will have 10 extra problem cells
        25: 13, // grid size 25 will have 13 extra problem cells
        30: 15 // grid size 30 will have 15 extra problem cells
    }

    const selectedBlankCellColor = "greenBorder"
    const dict = {
        1: 'gray',
        2: 'cross',
        3: 'dot',
        4: '0'
    };

    //keep track which gameInputButtonIsSelected
    //1 : gray , 2 : cross , 3 : dot , 4:blank
    const [selectedGameInputButton, setSelectedGameInputButton] = useState(1);
    // operation stack is used undo operation
    const [operationStack, setOperationStack] = React.useState([]);
    // this will check if the continuous input is on or not
    const [continuousInput, setContinuousInput] = React.useState({is_continuous: false, label: ''});
    // this keeps track focus input cell
    const [focusInput, setFocusInput] = useState({row: 0, col: 0, times: 0});
    // this is the game table array. this is a 2d array
    const [tableData, setTableData] = useState([]);
    // grid size
    const [gridSize, setGridSize] = useState(10);
    // drag
    const [isDraggable, setIsDraggable] = React.useState(false);
    // draggable element
    const [selectedCell, setSelectedCell] = React.useState(null);


    const onFocusInput = (event, row, col,type) => {
        event.preventDefault();
        let new_focused_input = {...focusInput};
        let new_table_data = [...tableData];

        // if click first time mark the cell as gray cell
        // if click second time mark the cell as cross cell
        // if click third time mark the cell as dot cell
        // if click fourth time mark the cell as blank cell

        if (row === focusInput.row && col === focusInput.col) {
            // this condition is for clicking on the same cell again
            // increase the focusInput.times
            var times = focusInput.times + 1;
            new_focused_input.times = times > 4 ? 1 : times;
            new_focused_input.row = row;
            new_focused_input.col = col;
        } else {
            // this means clicked for the first time
            var focusInputTimes =selectedGameInputButton
            if(selectedGameInputButton!=0) focusInputTimes = selectedGameInputButton
            new_focused_input.times = focusInputTimes
            new_focused_input.row = row;
            new_focused_input.col = col;
        }

        // this is the mouse right and leftb click event
        if (event.button === 2 || event.button == 0) {

            if (event.button == 2) {
                new_focused_input.times = 4;
            } else isLeftMouseClicked = true

            if (isEnteredInDragEnd != 0 || isLeftMouseClicked) {
                setIsDraggable(true)
            }
            isEnteredInDragEnd = 1
        }

    
        new_table_data[focusInput.row][focusInput.col].is_focused = false;
        new_table_data[new_focused_input.row][new_focused_input.col].is_focused = true;
        setFocusInput(new_focused_input);
        setSelectedCell(new_table_data[new_focused_input.row][new_focused_input.col]);
        validateInput(row, col, dict[new_focused_input.times], new_table_data);
    };

    const onInput = (value) => {
        var selectedFocusInput = 0;
          for (var key in dict){
                if(dict[key]==value)
                {
                    selectedFocusInput = key;
                    break
                }
          }

        // value can be either 0 or dot or gray or cross
        // check the currently focusedInput cell
        const {row, col} = focusInput;

        if (continuousInput.is_continuous) {
            // set the number as current input
            setContinuousInput({is_continuous: true, label: value});
        }
        setSelectedGameInputButton(selectedFocusInput)
        setGameInputInSelectedCell(selectedFocusInput);
    };


    // boundary value is [5,5] [5,14] [14, 5] [14,14]  that means row=5 and col=5 row can't be less than 5 col can't be less than 5
    const onSelectLeftCell = () => {
        // check if the column is not less than boundary value
        let new_focus_col = focusInput.col - 1;
        if (new_focus_col >= 0) {
            let new_table_data = [...tableData];
            // first clear the previous focus cell
            new_table_data[focusInput.row][focusInput.col].is_focused = false;
            // set the new focus cell
            new_table_data[focusInput.row][new_focus_col].is_focused = true;
            // point the focusInput to the newly focused cell
            let new_focused_input = {...focusInput};
            new_focused_input.row = focusInput.row;
            new_focused_input.col = new_focus_col;
            setFocusInput(new_focused_input);
            if (continuousInput.is_continuous && continuousInput.label !== '') {
                validateInput(
                    new_focused_input.row,
                    new_focused_input.col,
                    continuousInput.label,
                    new_table_data
                    );
            }
        }
    };
    const onSelectRightCell = () => {
        // check if the column is not greater than boundary value
        let new_focus_col = focusInput.col + 1;
        if (new_focus_col < gridSize) {
            let new_table_data = [...tableData];
            // first clear the previous focus cell
            new_table_data[focusInput.row][focusInput.col].is_focused = false;
            // set the new focus cell
            new_table_data[focusInput.row][new_focus_col].is_focused = true;
            // point the focusInput to the newly focused cell
            let new_focused_input = {...focusInput};
            new_focused_input.row = focusInput.row;
            new_focused_input.col = new_focus_col;
            setFocusInput(new_focused_input);
            if (continuousInput.is_continuous && continuousInput.label !== '') {
                validateInput(
                    new_focused_input.row,
                    new_focused_input.col,
                    continuousInput.label,
                    new_table_data
                );
            }
        }
    };
    const onSelectTopCell = () => {
        // check if the row is not less than boundary value
        let new_focus_row = focusInput.row - 1;
        if (new_focus_row >= 0) {
            let new_table_data = [...tableData];
            // first clear the previous focus cell
            new_table_data[focusInput.row][focusInput.col].is_focused = false;
            // set the new focus cell
            new_table_data[new_focus_row][focusInput.col].is_focused = true;
            // point the focusInput to the newly focused cell
            let new_focused_input = {...focusInput};
            new_focused_input.row = new_focus_row;
            new_focused_input.col = focusInput.col;
            setFocusInput(new_focused_input);
            if (continuousInput.is_continuous && continuousInput.label !== '') {
                validateInput(
                    new_focused_input.row,
                    new_focused_input.col,
                    continuousInput.label,
                    new_table_data
                );
            }
        }
    };

    const onSelectBottomCell = () => {
    
        // check if the row is not greater than boundary value
        let new_focus_row = focusInput.row + 1;
        if (new_focus_row < gridSize) {
            let new_table_data = [...tableData];
            // first clear the previous focus cell
            new_table_data[focusInput.row][focusInput.col].is_focused = false;
            // set the new focus cell
            new_table_data[new_focus_row][focusInput.col].is_focused = true;
            // point the focusInput to the newly focused cell
            let new_focused_input = {...focusInput};
            new_focused_input.row = new_focus_row;
            new_focused_input.col = focusInput.col;
            setFocusInput(new_focused_input);
            if (continuousInput.is_continuous && continuousInput.label !== '') {
                validateInput(
                    new_focused_input.row,
                    new_focused_input.col,
                    continuousInput.label,
                    new_table_data
                );
            }
        }
    };

    const validateInput = (row, col, value, table) => {
        let operation_stack = [...operationStack];
        operation_stack.push({row: row, col: col, value: table[row][col].value});
        setOperationStack(operation_stack);
        
        table[row][col].value = value;
        setTableData(table);
    }

    const isMobileTablet=()=>{
        var check = false;
        (function(a){
            if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) 
                check = true;
        })(navigator.userAgent||navigator.vendor||window.opera);
        return check;
    }

    const setGameInputInSelectedCell = (selectedFocusInput) => {
        const { row, col } = focusInput;
        if (!tableData[row][col].is_focused) return;
    
        let new_focused_input = { ...focusInput };
        let new_table_data = [...tableData];
        new_focused_input.row = row;
        new_focused_input.col = col;
        new_focused_input.times = selectedFocusInput;
        setFocusInput(new_focused_input);
        validateInput(row, col, dict[new_focused_input.times], new_table_data);
      };

    const mouseEnter = (row, col) => {
        
        // here check the boundary value
        if (isDraggable && selectedCell) {
            if(isMobileTablet()) return

            let previous_focused_input = { ...focusInput };
            let new_table_data = [...tableData];
            let times = previous_focused_input.times;

            if(isLeftMouseClicked) 
            {
                 // adding for previous state
                let operation_stack = [...operationStack];
                operation_stack.push({row: previous_focused_input.row, col: previous_focused_input.col, value: dict[times]});
                setOperationStack(operation_stack);
                new_table_data[previous_focused_input.row][previous_focused_input.col].value = dict[times];
            }
            
            // adding last state
            let operation_stack = [...operationStack];
            operation_stack.push({row: row, col: col, value: tableData[row][col].value});
            setOperationStack(operation_stack);

            //let new_table_data = [...tableData];
            new_table_data[row][col].value = selectedCell.value;
            new_table_data[previous_focused_input.row][previous_focused_input.col].is_focused = false
            new_table_data[row][col].is_focused = true
            setTableData(new_table_data);

            //Set previos focus input
            let new_focused_input = { ...focusInput };
            new_focused_input.times = focusInput.times;
            new_focused_input.row = row;
            new_focused_input.col = col;
            setFocusInput(new_focused_input);
        }
    };

    const dragEnd = () => {

        if (isDraggable) {
            if (isLeftMouseClicked) {
                isLeftMouseClicked = false
                isEnteredInDragEnd = 1
            }
            else isEnteredInDragEnd = 0
        }
        setSelectedCell(null);
        setIsDraggable(false);
    };

    const eraseAll = () => {
        if (window.confirm("本当に消去しますか？")) {
            props.history.replace("/input")
            loadInitialBoard();
        }
    };

    const undo = () => {
        // check the operation stack first
        if (operationStack.length > 0) {
            let operation_stack = [...operationStack];
            let table_data = [...tableData];
            let lastOperation = operation_stack.pop();
            let row = lastOperation.row;
            let col = lastOperation.col;
            lastOperation.row = undefined;
            lastOperation.col = undefined;
            table_data[row][col] = lastOperation;
            table_data[row][col].is_focused = false;
            // table_data[lastOperation.row][lastOperation.col] = {
            //     ...table_data[lastOperation.row][lastOperation.col],
            //     ...{value: lastOperation.value, is_focused: ''},
            // };
            setOperationStack(operation_stack);
            setTableData(table_data);
            // setFocusInput({row: lastOperation.row, col: lastOperation.col, times: 0});
        }
    };

    const countColumnsOne = (col, table, extra_cells) => {
        let count_cols_one = [];
        let count = 0;
        for (let i = extra_cells; i < table[col].length; i++) {
            // scan the vertical problem
            let value = table[i][col].value === 'cross' ||
            table[i][col].value === 'dot' ||
            table[i][col].value === '0' ? '0' : '1';
            // if value '1' and next value is also '1' continue counting
            // if value '1' and next value zero count and push into the count_rows_one
            // after pushing set the count to zero
            // and final condition if the last value and count not zero. then push the count value into the count_rows_one
            if (value === '1' && !isNextRowZero(i, col, table)) {
                count++;
            } else if (value === '1' && isNextRowZero(i, col, table)) {
                count++;
                count_cols_one.push(count);
                count = 0;
            }
        }
        return count_cols_one;
    };

    const countRowsOne = (row, table, extra_cells) => {
        let count = 0;
        let count_rows_one = [];
        for (let i = extra_cells; i < table[row].length; i++) {
            // scan the horizontal problem
            let value = table[row][i].value === 'cross' ||
            table[row][i].value === 'dot' ||
            table[row][i].value === '0' ? '0' : '1';
            // if value '1' and selected row next column value is also '1' continue counting
            if (value === '1' && !isNextColZero(row, i, table)) {
                count++;
            } else if (value === '1' && isNextColZero(row, i, table)) {
                count++;
                count_rows_one.push(count);
                count = 0;
            }
        }
        return count_rows_one;
    };

    const isNextColZero = (row, col, table) => {
        // check the selected row next column value
        if (col + 1 > table.length - 1)
            return true;

        let value = table[row][col + 1].value === 'cross' ||
            table[row][col + 1].value === 'dot' ||
            table[row][col + 1].value === '0' ? '0' : '1';

        return value === '0';

    };

    const isNextRowZero = (row, col, table) => {
        // check the selected column next row value
        if (row + 1 > table.length - 1)
            return true;

        let value = table[row + 1][col].value === 'cross' ||
            table[row + 1][col].value === 'dot' ||
            table[row + 1][col].value === '0' ? '0' : '1';

        return value === '0';

    };

    const convertSolutionToProblem = () => {
        let data_array = [];
        let grid_size = gridSize;
        let extra_cells = extraCells[grid_size];
        let table_data = [...tableData];
        let grid_with_problem = grid_size + extra_cells;
        for (let i = 0; i < grid_with_problem; i++) {
            for (let j = 0; j < grid_with_problem; j++) {
                data_array.push({
                    value: '0',
                    is_fill: false,
                    is_editable: true,
                    valid: true,
                    is_focused: false,
                    color: 'black'
                });
            }
        }
        let table_chunk = createChunk(data_array, grid_with_problem);
        for (let i = extra_cells; i < grid_with_problem; i++) {
            for (let j = extra_cells; j < grid_with_problem; j++) {
                table_chunk[i][j] = table_data[i - extra_cells][j - extra_cells];
            }
        }

        // here generate solution
        // GENERATE SOLUTION FOR HORIZONTAL ROW
        // for horizontal row, row value is fixed column value will change
        let startingRow = extra_cells;
        for (let i = extra_cells; i < table_chunk.length; i++) {
            // after doing all the task increment startingRow value
            let rowsOne = countRowsOne(startingRow, table_chunk, extra_cells);
            let start_col_index = extra_cells - 1;
            while (rowsOne.length !== 0) {
                // pop item from the rowsOne and put into the problem cell accordingly
                table_chunk[startingRow][start_col_index].value = rowsOne.pop();
                start_col_index--;
            }
            startingRow++;
        }

        // GENERATE SOLUTION FOR VERTICAL COLUMN
        // for vertical column, column value is fixed row value will change
        let startingColumn = extra_cells;
        for (let i = extra_cells; i < table_chunk.length; i++) {
            // after doing all the task increment startingColumn value
            let columnsOne = countColumnsOne(startingColumn, table_chunk, extra_cells);
            let start_row_index = extra_cells - 1;
            while (columnsOne.length !== 0) {
                // pop item from the rowsOne and put into the problem cell accordingly
                table_chunk[start_row_index][startingColumn].value = columnsOne.pop();
                start_row_index--;
            }
            startingColumn++;
        }
        // after doing all the processing
        // parse the problem from the table_chunk
        return parseProblem(table_chunk, grid_with_problem, extra_cells);
    }


    const parseProblem = (table, grid_size, extra_cells) => {
        // **************** PARSE THE PROBLEM ***************
        let result = '';
        for (let row = extra_cells; row < grid_size; row++) {
            let temp = '';
            for (let col = extra_cells - 1; col >= 0; col--) {
                let data = table[row][col].value;
                if (data === '0')
                    break;

                if (col !== extra_cells - 1)
                    temp += ',';
                temp += data;
            }
            result += temp;

            if (row + 1 < grid_size)
                result += '+';
        }

        result += '|';

        for (let col = extra_cells; col < grid_size; col++) {
            let temp = '';
            for (let row = extra_cells - 1; row >= 0; row--) {
                let data = table[row][col].value;
                if (data === '0')
                    break;

                if (row !== extra_cells - 1)
                    temp += ',';
                temp += data;
            }
            result += temp;

            if (col + 1 < grid_size)
                result += '+';
        }
        return result;
    };
    const parser = (data, separator) => {
        return data.reduce((acc, current) => {
            acc.push(current.split(separator));
            return acc;
        }, []);
    };
    const checkSolutionExist = () => {
        let problem = convertSolutionToProblem();
        let split_problem = problem.split('|');
        let problem_rows = parser(split_problem[0].split('+'), ',');
        let problem_columns = parser(split_problem[1].split('+'), ',');
        let rows = [];
        let columns = [];

        for (let i = 0; i < problem_rows.length; i++) {
            let row_str = '';
            for (let j = problem_rows[i].length - 1; j >= 0; j--) {
                // this is the last element we don't need to add _
                if (j !== 0){
                    row_str += problem_rows[i][j] === ''? '0': problem_rows[i][j];
                    row_str +='_';
                } else {
                    row_str += problem_rows[i][j] === ''? '0': problem_rows[i][j];
                }
            }
            rows.push(row_str);
        }
        for (let i = 0; i < problem_columns.length; i++) {
            let col_str = '';
            for (let j = problem_columns[i].length - 1; j >= 0; j--) {
                if (j !== 0){
                    col_str += problem_columns[i][j] === ''? '0': problem_columns[i][j]
                    col_str +='_'
                } else {
                    col_str += problem_columns[i][j] === ''? '0': problem_columns[i][j]
                }
            }
            columns.push(col_str);
        }


        //Prepare solution Data
        var solution = ""
        for(let i=0;i<tableData.length;i++){
            var innerArrayLength = tableData[i].length;
            for (let j = 0; j < innerArrayLength; j++) {
                if(tableData[i][j].value != 0)
                    solution+="1"
                else
                    solution+="0"
                
            }
        }
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let raw = JSON.stringify({
            Rows: rows,
            "Cols": columns
        });
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };
        fetch('http://check.puzkey.com', requestOptions)
            .then(res => res.json())
            .then(json => {
                if (json.has_solution) {
                    alert('複数解がありました。問題の図案を修正してください');
                } else {
                    props.history.replace('/input', {tableData, gridSize,solution});
                    props.history.push('/submit', {tableData, gridSize,solution});
                }
            })
            .catch(error => console.log(error));

    };

    const loadInitialBoard = () => {
        if (props.location.state && props.location.state.tableData) {
            setTableData(props.location.state.tableData)
            let grid_size = parseInt(props.location.state.gridSize);
            setGridSize(grid_size)

        } else {
            let data_array = [];
        let grid_size = gridSize;
        if (props.location.state) {
            grid_size = parseInt(props.location.state.gridSize);
            setGridSize(grid_size)
        }
        for (let i = 0; i < grid_size; i++) {
            for (let j = 0; j < grid_size; j++) {
                data_array.push({value: '0', is_fill: false, is_editable: true, is_focused: false, color: 'black'});
            }
        }
        let table_chunk = createChunk(data_array, grid_size);
        table_chunk[0][0].is_focused = true;
        setTableData(table_chunk);
        }

        
    };

    const keyPress = (event) => {
        // event.keyCode === 39 arrow right key code
        // event.keyCode === 37 arrow left key code
        // event.keyCode === 38 arrow up key code
        // event.keycode === 40 arrow down key code
        if (event.keyCode === 39) {
            onSelectRightCell();
        }
        if (event.keyCode === 37) {
            onSelectLeftCell();
        }
        if (event.keyCode === 38) {
            onSelectTopCell();
        }
        if (event.keyCode === 40) {
            onSelectBottomCell();
        }
    };

    // similar to class based lifecycle hook method componentDidMount
    // componentDidUpdate componentWillMount
    useEffect(() => {
        saveSettings();
        loadInitialBoard();
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", keyPress);
        document.addEventListener("mouseup", dragEnd);
        return () => {
            document.removeEventListener("keydown", keyPress);
            document.removeEventListener('mouseup', dragEnd);
        };
    });

    const redirectTo = (event, path) => {
        props.history.push(path)
    };

    const checkIfEmpty = () => {
        for (let rowindex = 0; rowindex < tableData.length; rowindex++) {
            for (let colindex = 0; colindex < tableData[rowindex].length; colindex++) {
                if (tableData[rowindex][colindex].value != 0) return false;
            }          
        }
        return true;
    }

    const triggerCheckSolution = (event) => {

        if (checkIfEmpty()) {
            alert('問題を入力してください');
        } else {
            checkSolutionExist();
        }
    }

    return (
        <div>
            <div className="firstBody">
                <div className="header">
                    問題作成 問題の入力
                </div>

                <div id="bodyText">
                    問題の図案を入力してください
                </div>
            </div>

            <div className={`problem_div`}>
                <table id="problem_table_id" className={`problem_table`}>
                    <tbody>
                    {
                        tableData.map((rows, row) => {
                            return (
                                <tr key={row} className={`problem_table_tr`}>
                                    {
                                        rows.map((cell, col) => {
                                            return (
                                                <td key={col}
                                                    className={`problem_table_td`}
                                                    onMouseDown={event => onFocusInput(event, row, col,"onMouseDown")}
                                                    onContextMenu={event => onFocusInput(event, row, col,"onContextMenu")}
                                                    onMouseEnter={() => mouseEnter(row, col, "onMouseEnter")}>
                                                    <div className={`${cell.is_focused ? 'cell_focused' : 'default_cell'}`}>
                                                        <ProblemInput dataObject={cell} gridSize={gridSize} />
                                                    </div>
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                    </tbody>

                </table>
            </div>
            <div className="buttons_div">
                {/* Buttons Start */}
                {/* Horizontal Divs */}
                <div className="buttons_container">
                    <div className="left_buttons">
                        <button className="btn btn_up" onClick={() => onSelectTopCell()}>▲</button>
                        <button className="btn btn_left" onClick={() => onSelectLeftCell()}>▼</button>
                        <button className="btn btn_right" onClick={() => onSelectRightCell()}>▲</button>
                        <button className="btn btn_down" onClick={() => onSelectBottomCell()}>▼</button>
                    </div>
                    <div className="right_buttons" >
                        <div className="game_inputs_div" >
                            <button className={selectedGameInputButton == 3 ? "gameInputButtonSelected" : "gameInputButton"} onClick={() => onInput('dot')}>•</button>
                            <button className={selectedGameInputButton == 4 ? "gameInputButtonSelected" : "gameInputButton"} onClick={() => onInput('0')}/>
                            <button className={selectedGameInputButton == 1 ? "greyInputButtonSelected" : "greyInputButton"} onClick={() => onInput('gray')}/>
                            <button className={selectedGameInputButton == 2 ? "gameInputButtonSelected" : "gameInputButton"} onClick={() => onInput('cross')}>X</button>
                        </div>
                        <div className="buttons_container2">
                            <div className="left_buttons2">
                                <button className="btn btn_undo" id="doubleSizeBtn" onClick={() => undo()}><FaUndoAlt/>
                                </button>
                                <button className="btn btn_erase" id="doubleSizeBtn" onClick={() => eraseAll()}>全消去
                                </button>
                            </div>
                            <div className="right_buttons2">
                                <button id="doubleSizeBtn2"
                                        className={'btn btn_continuous ' +
                                        (continuousInput.is_continuous ? 'btn_continuous_input_active' : '')}
                                        onClick={() => setContinuousInput({
                                            ...continuousInput,
                                            is_continuous: !continuousInput.is_continuous,
                                            label: continuousInput.is_continuous ? continuousInput.label : ''
                                        })}>連続入力
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Buttons End */}
            </div>
            <div className="bigButtonsBody">
                <div>
                    <button className="bodyBtnWhite" onClick={triggerCheckSolution}>複数解をチェックする</button>
                </div>
                <br/>
                <div>
                    <button className="bodyBtnBlue" onClick={(event) => redirectTo(event, '/create')}>サイズ選択に戻る</button>
                </div>
            </div>
            <div className="footer" onClick={(event) => redirectTo(event, '/illustrology_main')}>
                イラストロジックTOPへ戻る
            </div>
        </div>
    )
}

export default ProblemInputScreen;
