import React from "react";
import './problemInput.css';

const dict = {
    gray: 'problemInputGray',
    dot: 'problemInputDot',
    cross: 'problemInputCross'
};
const dictVal = {
    gray: "",
    cross: "╳",
    dot: "•"
}

const IllustrationLogicInput = (props) => {
    const { dataObject, fontSize} = props;
    const { value } = dataObject;

    let color_value = 'default_cell';
    let cell_div;

     if (value === '0' || value === 0) {
        cell_div = <div className='problem_blank_cell'/>
     } else {
        cell_div = <div className={dict[value]}></div>
    }
    return (
        <div className={`td_data ${color_value}`}>
            {cell_div}
        </div>
    )
};

export default IllustrationLogicInput;
