import React, {useState, useEffect, Fragment} from 'react';
import './illustrologyMain.css';
import {BASE_URL} from '../../config';
import {Link} from 'react-router-dom';
import {saveSettings} from "../../utils/functions";


function IllustrologyMain(props) {

    const options = {0: false, 1: false, 2: false};
    const [dropDownOption, setDropDownOption] = useState(options);
    const [latest10data, setlatest10data] = useState([]);

    const getDate = (timeStamp) => {
        timeStamp = new Date(timeStamp).toDateString();
        return timeStamp;
    };

    useEffect(() => {
        saveSettings();
        const fetchData = async () => {
            // get 10 latest created illustrology boards
            fetch(BASE_URL + '/api/illustroboards/latest')
                .then(res => res.json())
                .then(json => {
                    setlatest10data(json);
                })
                .catch(error => console.log(error));
        };
        fetchData();

    }, []);

    const startGame = (id) => {
        props.history.push('/illustrologic', {id});
    };

    const redirectToGame = (event, link, startingLevel) => {
        event.preventDefault();
        let sortOrder = 'popularity';
        props.history.push(link, {startingLevel, sortOrder});
    };

    const toggleDropDown = (value) => {
        const newOption = {...dropDownOption};
        newOption[value] = !newOption[value];
        setDropDownOption(newOption);
    };

    const getDifficultyName = (key) => {
        const keyValuePair = {
            introductory: '入門', 
            beginner: '初級', 
            intermediate: '中級', 
            advanced: '級上', 
            expert: '難問', 
        };

        return keyValuePair[key];
    }

    return (
        <div>
            <div className="header">イラストロジック</div>
            <div className="illustrologyMainNavbar">

                <Link to="/illustrology_main" className="active-navbutton">問題を解く</Link>
                <Link to="/createProblemTop">問題を作る</Link>

            </div>
            <div className="select-by-difficulty">
                <div className="blue-title" onClick={() => toggleDropDown(0)}>難易度から選ぶ</div>
                {
                    dropDownOption[0] && (
                        <Fragment>
                            <button className="first-button button-illustrology-main centerButton" onClick={event => {
                                redirectToGame(event, '/playByLevel', 'introductory')
                            }}>入門
                            </button>
                            <br/>
                            <button className="button-illustrology-main centerButton" onClick={event => {
                                redirectToGame(event, '/playByLevel', 'beginner')
                            }}>初級
                            </button>
                            <br/>
                            <button className="button-illustrology-main centerButton" onClick={event => {
                                redirectToGame(event, '/playByLevel', 'intermediate')
                            }}>中級
                            </button>
                            <br/>
                            <button className="button-illustrology-main centerButton" onClick={event => {
                                redirectToGame(event, '/playByLevel', 'advanced')
                            }}>級上
                            </button>
                            <br/>
                            <button className="button-illustrology-main centerButton" onClick={event => {
                                redirectToGame(event, '/playByLevel', 'expert')
                            }}>難問
                            </button>
                        </Fragment>
                    )
                }

            </div>
            <div className="select-by-grid">
                <div className="blue-title" onClick={() => toggleDropDown(1)}>サイズから選ぶ</div>
                {
                    dropDownOption[1] && (
                        <Fragment>
                            <button className="first-button button-illustrology-main centerButton" onClick={event => {
                                redirectToGame(event, '/playByGrid', '10')
                            }}>10x10
                            </button>
                            <br/>
                            <button className="button-illustrology-main centerButton" onClick={event => {
                                redirectToGame(event, '/playByGrid', '15')
                            }}>15x15
                            </button>
                            <br/>
                            <button className="button-illustrology-main centerButton" onClick={event => {
                                redirectToGame(event, '/playByGrid', '20')
                            }}>20x20
                            </button>
                            <br/>
                            <button className="button-illustrology-main centerButton" onClick={event => {
                                redirectToGame(event, '/playByGrid', '25')
                            }}>25x25
                            </button>
                            <br/>
                            <button className="button-illustrology-main centerButton" onClick={event => {
                                redirectToGame(event, '/playByGrid', '30')
                            }}>30x30
                            </button>
                        </Fragment>
                    )
                }

            </div>
            <div className="new-questions-div">

                <div className="blue-title" onClick={() => toggleDropDown(2)}>新着10問から選ぶ</div>
                {
                    dropDownOption[2] && (
                        <Fragment>
                            <table>
                                <tbody>
                                <tr>
                                    <th>タイトル</th>
                                    <th>難易度</th>
                                    <th>サイズ</th>
                                    <th>追加日</th>
                                </tr>
                                {
                                    latest10data.map((row, key) => {
                                        return (
                                            <tr key={key} onClick={() => startGame(row.id)}>
                                                <td>{row.title}</td>
                                                <td>{getDifficultyName(row.difficulty)}</td>
                                                <td>{row.grid_size}×{row.grid_size}</td>
                                                <td>{getDate(row.created_at)}</td>
                                            </tr>
                                        );
                                    })
                                }
                                </tbody>

                            </table>
                        </Fragment>
                    )
                }
            </div>
            <div className="article-div">
                <div className="blue-title" onClick={() => props.history.push('/how_to_play_illustrology')}>イラストロジックの遊び方・コツ</div>
            </div>
            <div className="advertisement-div">広告</div>
            <Link className="link" to="/">ナンプレで遊ぶ</Link>
            <div className="footer-illustrology-main">
                <Link to="/">プライバシーポリシー・免責事項</Link>
                <a href="https://ma-ko-to.com/" id="footer-last-link">管理会社</a>
            </div>
        </div>
    );

}

export default IllustrologyMain;