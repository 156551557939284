import React, {useEffect, useState} from "react";
import './howtoplaytitles.css'
import {BASE_URL} from '../../config';


// when click on article content tree than it will scroll to that section
// on the bottom right corner there should be a button scroll to top
function HowToPlayTitles(props) {

    const [howToPlayData, setHowToPlayData] = useState([]);
    const [tipData, setTipData] = useState([]);

    const redirectTo = (event, path, id, title, isTip, url) => {
        event.preventDefault(); // this will prevent it's default behaviour. which is redirecting to another page
        props.history.push(path, {title, isTip, requestUrl: url + id, topTitle: 'ナンプレの遊び方・コツ'});
    };

    useEffect(() => {

        fetch(BASE_URL + '/api/howtoplays')
            .then(res => res.json())
            .then(json => {
                setHowToPlayData(json.data);
            })
            .catch(err => {
                console.log(err);
            });

        fetch(BASE_URL + '/api/tips')
            .then(res => res.json())
            .then(json => setTipData(json.data))
            .catch(err => {
                console.log(err);
            });

    }, []);

    return (
        <div>

            <div className="how_to_play_titles_header">ナンプレの遊び方・コツ</div>

            <u>ナンプレの遊び方</u>
            <ul className='howToPlayTitles'>
                {
                    howToPlayData.map((row, key) => {
                        return (
                            <li key={key} onClick={(e) => {
                                redirectTo(e, 'how_to_play_details', row['id'], row['article_name'], false, '/api/howtoplaycontent/parent/')
                            }}>
                                {row['article_name']}
                            </li>
                        )
                    })
                }
            </ul>

            <u className='tipTitlesHeader'>ナンプレのコツ</u>
            <ul className='tipTitles'>
                {
                    tipData.map((row, key) => {
                        return (
                            <li key={key} onClick={(e) => {
                                redirectTo(e, 'how_to_play_details', row['id'], row['tip_name'], true,'/api/tipcontent/parent/')
                            }}>
                                {row['tip_name']}
                            </li>
                        )
                    })
                }
            </ul>

            <div onClick={(e) => {
                redirectTo(e, '/')
            }} className="howTo_tip_title_page_footer">ナンプレTOPへ戻る
            </div>
        </div>
    );
}

export default HowToPlayTitles;
