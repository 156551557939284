import React from "react";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { Backdrop, createStyles, makeStyles } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
    FacebookShareButton,
    FacebookIcon,
    LineShareButton,
    LineIcon,
    TwitterShareButton,
    TwitterIcon
} from "react-share";
import {BASE_URL} from "../../config";

const useStyles = makeStyles((theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: 'rgba(65, 98, 162, 0.6)',
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),

        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        }
    }),
);

export default function SudokuSuccessModal(props) {
    const classes = useStyles();

    const { successDialog, setSuccessDialog, endTime, addRating, successMessage, shareMessage, imageUrl } = props;

    const [rating, setRating] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const handleChange = event => {
        setRating(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={successDialog}
                onClose={() => setSuccessDialog(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={successDialog}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title" style={{ color: 'white', textAlign: 'center' }}>{successMessage}</h2>
                        <h5 style={{ color: 'white', textAlign: 'center' }}>タイム：{endTime ? endTime : '0'}</h5>
                        {/* CHANGE THE IMAGE HERE */}
                        <img src={BASE_URL + '/public/images/'+imageUrl} alt='' width="300px"/>
                        <div style={{ color: 'white', textAlign: 'center' }}>
                            <h5>問題を5段階で評価してください <br /> つまらない1↔5好き</h5>
                            {/* Put the dropdown menu here */}
                            <FormControl className={classes.formControl}>
                                <Select
                                    style={{ backgroundColor: 'white' }}
                                    labelId="demo-controlled-open-select-label"
                                    id="demo-controlled-open-select"
                                    open={open}
                                    onClose={handleClose}
                                    onOpen={handleOpen}
                                    value={rating}
                                    onChange={handleChange}>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                </Select>
                            </FormControl>
                            <br/>
                            {/* Put the social icon here twitter, facebook, line*/}
                            <TwitterShareButton url={'http://puz.puzkey.com/sudoku'} title={shareMessage}> <TwitterIcon size={32} /></TwitterShareButton>
                            <FacebookShareButton url={'http://puz.puzkey.com/sudoku'} quote={shareMessage}><FacebookIcon size={32} /></FacebookShareButton>
                            <LineShareButton url={'http://puz.puzkey.com/sudoku'} title={shareMessage}><LineIcon size={32} /></LineShareButton>
                            {/* Submit Rating Button 閉じる*/}
                            <br />
                            <button type='button' style={{ height: 30, width: 120, backgroundColor: 'white', color: '#000' }} onClick={() => addRating(rating)}>閉じる</button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
