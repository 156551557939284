import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  .App {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    margin: 0;
    padding: 0;
    font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    transition: all 0.25s linear;
  }
  .board_table > tbody > tr:nth-child(3n) {
      border-bottom: 2px solid ${({ theme }) => theme.table} !important;
  }
  .board_table > tbody > tr > td:nth-child(3n) {
      border-right: 2px solid ${({ theme }) => theme.table} !important;
  }
  .sum_table > tbody > tr:nth-child(3n) {
    border-bottom: 2px solid ${({ theme }) => theme.table} !important;
  }

  .sum_table > tbody > tr > td:nth-child(3n) {
    border-right: 2px solid ${({ theme }) => theme.table} !important;
  }
    .four_table > tbody > tr:nth-child(2n) {
    border-bottom: 2px solid ${({ theme }) => theme.table} !important;
  }

  .four_table > tbody > tr > td:nth-child(2n) {
    border-right: 2px solid ${({ theme }) => theme.table} !important;
  }
  .nav_box_setting{
    color: ${({ theme }) => theme.text} !important;
  }
  .custom_a {
    color: ${({ theme }) => theme.table} !important;
  }
  .blue-title{
    background-color: ${({ theme }) => theme.blueTitle} !important;
  }
  .default_color{
    color: ${({ theme }) => theme.table} !important;
  }
  .input_cell {
    color: ${({ theme }) => theme.problem_cell} !important;
  }
  .memo-table-row-col{
    color: ${({ theme }) => theme.text} !important;
  }
  .highlight_cell  .default_color{
    color: ${({ theme }) => theme.table} !important;
  }
  .selected_cell .default_color{
    color: ${({ theme }) => theme.selected} !important;
  }
  `;
